import React from 'react';
import { EuiHorizontalRule, EuiToolTip, Icon } from 'ui';
import { docMap } from './constants/docMap';
import { Content, DocumentsBadge, Label } from './DocumentsBadge.emotion';

export const DocumentBadge = ({ doc }) => {
  const toolTipContent = Array.isArray(doc) ? (
    <div>
      {doc.map((document, index) => (
        <div key={index}>
          <Content>{document}</Content>
          {docMap[document] && (
            <>
              <EuiHorizontalRule margin="xs" color="#000" />
              <span dangerouslySetInnerHTML={{ __html: docMap[document] }} />
            </>
          )}
          {index < doc.length - 1 && <EuiHorizontalRule margin="s" />}
        </div>
      ))}
    </div>
  ) : docMap[doc] ? (
    <div>
      <Content>{doc}</Content>
      <EuiHorizontalRule margin="xs" color="#000" />
      <span dangerouslySetInnerHTML={{ __html: docMap[doc] }} />
    </div>
  ) : (
    doc
  );

  return (
    <EuiToolTip content={toolTipContent}>
      <DocumentsBadge>
        <Label>{Array.isArray(doc) ? `${doc.length} Documents` : doc}</Label>
        <Icon color="#fff" name="help" size="s" />
      </DocumentsBadge>
    </EuiToolTip>
  );
};
