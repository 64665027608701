import React from 'react';
import { Dictionary } from 'lodash';
import { EuiDatePicker, EuiFormRow, EuiTextColor, Select, TextField } from 'ui';
import { FormValues, SubmissionAttributeMetadata } from './types';
import { isFieldInvalid, touch } from './utils';
import { SubmissionFormMeta } from './validator';
// field generators
export const textField = (
  field: string,
  label: string,
  meta: SubmissionFormMeta,
  attributeMeta: Dictionary<SubmissionAttributeMetadata>,
  setMeta: (m: SubmissionFormMeta) => void,
  values: FormValues,
  onValueChange: (field: string) => (e: any) => void,
  saving: boolean,
  required?: boolean,
) => {
  const isInvalid = isFieldInvalid(field, meta);
  const isRequired = required !== undefined ? required : isAttributeRequired(field, attributeMeta);
  return (
    <EuiFormRow
      label={getFormLabel(label, isRequired)}
      isInvalid={!!isInvalid}
      error={meta[field]?.error}
    >
      <TextField
        data-testid={`submission-input-${label.split(' ').join('-')}`}
        value={values[field]}
        onChange={onValueChange(field)}
        onBlur={touch(field, meta, setMeta)}
        disabled={saving}
        invalid={isInvalid}
      />
    </EuiFormRow>
  );
};

export const dateField = (
  field: string,
  label: string,
  values: FormValues,
  onValueChange: (field: string) => (e: any) => void,
  saving: boolean,
  attributeMeta: Dictionary<SubmissionAttributeMetadata>,
  required?: boolean,
) => {
  const isRequired = required !== undefined ? required : isAttributeRequired(field, attributeMeta);
  return (
    <EuiFormRow label={getFormLabel(label, isRequired)}>
      <EuiDatePicker
        selected={values[field]}
        onChange={onValueChange(field)}
        dateFormat="MM/DD/YYYY"
        disabled={saving}
      />
    </EuiFormRow>
  );
};

export const selectField = (
  field: string,
  label: string,
  options: { label: string; value: string }[],
  testID: string,
  meta: SubmissionFormMeta,
  attributeMeta: Dictionary<SubmissionAttributeMetadata>,
  values: FormValues,
  onValueChange: (field: string) => (e: any) => void,
  isFieldInvalid: (fieldName: string, meta: SubmissionFormMeta) => boolean,
  required?: boolean,
) => {
  const isInvalid = isFieldInvalid(field, meta);
  const isRequired = required !== undefined ? required : isAttributeRequired(field, attributeMeta);
  return (
    <EuiFormRow
      label={getFormLabel(label, isRequired)}
      isInvalid={isInvalid}
      error={meta[field]?.error}
    >
      <Select
        data-testid={testID}
        options={options}
        value={values[field]}
        onChange={(value) => onValueChange(field)({ target: { value } })}
        invalid={isInvalid}
      />
    </EuiFormRow>
  );
};

export const isAttributeRequired = (
  field: string,
  attributeMeta: Dictionary<SubmissionAttributeMetadata>,
) => attributeMeta?.[field]?.required || false;

export const getFormLabel = (label: string, required: boolean) => (
  <>
    {label}
    {required && <EuiTextColor color="danger"> *</EuiTextColor>}
  </>
);
