import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  ButtonEmpty,
  EuiFlexGroup,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiText,
  Tabs,
  useToast,
} from 'ui';
import { ModalContext } from '@app/contexts/ModalContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { useConfigureAuthorizationMutation } from '@app/graphql/mutations/rbac/__generated__/ConfigureAuthorization.generated';
import { useAuthorizationConfigQuery } from '@app/graphql/queries/rbac/__generated__/AuthorizationConfig.generated';
import { E2VALUE } from '@app/platform/Integrations/consts';
// FIX ME
// @ts-ignore
import ENABLE_INTEGRATION from '@app/queries/integrations/enableIntegration.gql';
// FIX ME
// @ts-ignore
import GET_USAGE from '@app/queries/integrations/getUsage.gql';
import {
  IEnableIntegrationData,
  IEnableIntegrationVariables,
  IUsageData,
  IUsageVariables,
} from '@app/queries/integrations/types';
// FIX ME
// @ts-ignore
import CREATE_ORG from '@app/queries/organizations/createOrg.gql';
// FIX ME
// @ts-ignore
import GET_ORGANIZATION from '@app/queries/organizations/getOrganization.gql';
// FIX ME
// @ts-ignore
import GET_ORGANIZATIONS from '@app/queries/organizations/getOrganizations.gql';
// FIX ME
// @ts-ignore
import INIT_ORG_CONFIG from '@app/queries/organizations/initOrgConfig.gql';
import {
  CarrierExportCode,
  InitOrgConfigVariables,
} from '@app/queries/organizations/orgConfig.types';
import {
  ICreateOrgData,
  ICreateOrgVariables,
  IGetOrganizationData,
  IGetOrganizationVariables,
  IUpdateOrgData,
  IUpdateOrgVariables,
} from '@app/queries/organizations/types';
// FIX ME
// @ts-ignore
import UPDATE_ORG from '@app/queries/organizations/updateOrg.gql';
// FIX ME
// @ts-ignore
import GET_ROLE_ASSIGNMENTS from '@app/queries/rbac/getRoleAssignments.gql';
import {
  GetRoleAssignmentsData,
  GetRoleAssignmentsVariables,
  Role,
  ScopeType,
} from '@app/queries/rbac/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { OrgModalProvider } from './context/OrgModal.context';
import GeneralTab from './Tabs/GeneralTab/GeneralTab';
import { integrationsApolloClient } from './Tabs/IntegrationsTab/IntegrationsApolloClient';
import IntegrationsTab from './Tabs/IntegrationsTab/IntegrationsTab';
import PageConfigurationTab from './Tabs/PageConfigurationTab/PageConfigurationTab';
import RbacTab from './Tabs/RbacTab/RbacTab';
import styles from './AddEditOrgModal.emotion';
import ConfirmUpdateModal from './ConfirmUpdateModal';
import { getOrgType } from './OrgTypeSelect';
import { IForm, ModalAction } from './types';

const actionLabel = { add: 'Add', edit: 'Edit' };

interface IOrganizationButtonAttribute {
  orgId?: string;
  orgName?: string;
  action: ModalAction;
}

const directValueFields = [
  'reportingType',
  'orgType',
  'autoDomains',
  'displayCurrency',
  'unitOfMeasurement',
  'lossStartYear',
  'industry',
  'businessType',
  'orgLevel',
];
const checkFields = [
  'enableUploadPortal',
  'enableStreamDashboard',
  'enableSubValues',
  'enableCurrencyConversion',
  'showNonEnrichmentDocs',
  'enableAccountDashboard',
  'enableRapidExport',
  'enableZorbaExport',
  'enableSecondaryModifiers',
  'excludeFromReporting',
  'enableRiskManagerAssessment',
  'enableCarrierAssessment',
  'enableUnderwriterEdit',
  'enableSendEmailOnSOVUpload',
  'enableRedirectOnSOVUpload',
  'enableLosses',
  'enableSOVManager',
];

const nameToValue = (field: string, e: any) => {
  if (directValueFields.includes(field)) {
    return e;
  }
  if (checkFields.includes(field)) {
    return e.target.checked;
  }
  return e.target.value;
};

const isValid = (action: ModalAction, formValues: IForm) => {
  if (action === 'add' && (!formValues.industry || !formValues.businessType)) {
    return false;
  }

  return !!(
    formValues.orgName &&
    (formValues.reportingType !== 'Internal' || !!formValues.orgType)
  );
};

const ContentWrapper: React.FC = ({ children }) => (
  <EuiForm className={styles.contentForm}>
    <EuiFlexGroup className={styles.contentFlexGroup} direction="column">
      {children}
    </EuiFlexGroup>
  </EuiForm>
);

const AddEditOrgModal: React.FC<IOrganizationButtonAttribute> = ({ orgId, orgName, action }) => {
  const { closeModal } = useContext(ModalContext);
  const toast = useToast();

  const modalHeaderText = `${actionLabel[action]} Account`;

  const [getOrg, { data, loading: loadingOrg }] = useLazyQuery<
    IGetOrganizationData,
    IGetOrganizationVariables
  >(GET_ORGANIZATION, {
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  useEffect(() => {
    if (orgId) {
      getOrg({
        variables: {
          id: orgId,
          name: orgName,
        },
      });
    }
  }, [orgId]);

  const org = data?.organization;
  const defaultValues: IForm = {
    autoDomains: org?.autoDomains || [],
    businessType: org?.businessType,
    displayCurrency: org?.displayCurrency || 'USD',
    enableAccountDashboard: !!data?.orgSubmissionConfig?.enableDashboard,
    enableCarrierAssessment: !!org?.enableCarrierAssessment,
    enableCurrencyConversion: !!org?.enableCurrencyConversion,
    enableLosses: false,
    enableRapidExport: false,
    enableRedirectOnSOVUpload: !!org?.enableRedirectOnSOVUpload,
    enableRiskManagerAssessment: !!org?.enableRiskManagerAssessment,
    enableSOVManager: org?.enableSOVManager,
    enableSecondaryModifiers: !!org?.enableSecondaryModifiers,
    enableSendEmailOnSOVUpload: !!org?.enableSendEmailOnSOVUpload,
    enableStreamDashboard: org?.enableStreamDashboard === false ? org?.enableStreamDashboard : true,
    enableSubValues: org?.enableSubValues === false ? org?.enableSubValues : true,
    enableUnderwriterEdit: !!org?.enableUnderwriterEdit,
    enableUploadPortal: !!org?.enableUploadPortal,
    enableZorbaExport: false,
    excludeFromReporting: !!org?.excludeFromReporting,
    industry: org?.industry,
    orgLevel: org?.orgLevel,
    orgName: org?.name || '',
    orgType: getOrgType(org?.orgType, org?.reportingType),
    reportingType: org?.reportingType || 'Customer',
    salesChannel: org?.salesChannel,
    salesforceAccountID: org?.salesforceAccountID,
    showNonEnrichmentDocs: !!org?.showNonEnrichmentDocs,
    unitOfMeasurement: org?.unitOfMeasurement || 'imperial',
  };
  const [values, setValues] = useState<IForm>(defaultValues);
  const [domainOptions, setDomainOptions] = useState<Array<string>>([]);
  const [confirmUpdateModalIsOpen, setConfirmUpdateModalIsOpen] = useState<boolean>(false);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(isValid(action, values));
  const getOrgInfo = action === 'edit' && !!org?.id;
  const [marketAccessDomainOptions, setMarketAccessDomainOptions] = useState<string[]>([]);
  const [ssoConfigName, setSSOConfigName] = useState<string>('');
  const [carrierExports, setCarrierExports] = useState<CarrierExportCode[]>([]);
  const [selectedTemplateOrg, setSelectedTemplateOrg] = useState<string | null>(null);
  const { selectedOrganization, setOrgByName } = useContext(UserSessionContext);

  const [initOrgConfig] = useMutation<any, InitOrgConfigVariables>(INIT_ORG_CONFIG, {
    onCompleted: closeModal,
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const { data: integrationsData } = useQuery<IUsageData, IUsageVariables>(GET_USAGE, {
    client: integrationsApolloClient,
    onCompleted: (data) => {
      if (data?.usage) {
        setIntegrationsSettings({
          enabled: data.usage.enabled,
          limit: data.usage.limit,
        });
      }
    },
    skip: !org?.id,
    variables: {
      input: {
        organizationID: org?.id,
        partnerName: E2VALUE,
      },
    },
  });

  const defaultIntegrationSettings = {
    enabled: integrationsData?.usage?.enabled || false,
    limit: integrationsData?.usage?.limit || 0,
  };

  const [integrationsSettings, setIntegrationsSettings] = useState<{
    enabled: boolean;
    limit: number;
  }>(defaultIntegrationSettings);

  const { orgExportConfig, orgPropertyConfig } = data || {};

  useEffect(() => {
    if (!data) {
      return;
    }

    setValues({
      ...defaultValues,
      enableLosses: orgPropertyConfig?.enableLosses,
      enableRapidExport: orgExportConfig?.carrierExports?.includes(CarrierExportCode.RAPID),
      enableZorbaExport: orgExportConfig?.carrierExports?.includes(CarrierExportCode.ZORBA),
      lossStartYear: orgPropertyConfig?.lossStartYear,
    });

    setDomainOptions(data?.organization?.autoDomains || []);
  }, [data]);

  useEffect(() => {
    if (values?.orgType !== 'Template') {
      setSelectedTemplateOrg(null);
    }
  }, [values?.orgType]);

  const [createOrg, createOrgResult] = useMutation<ICreateOrgData, ICreateOrgVariables>(
    CREATE_ORG,
    {
      onCompleted: (data) => {
        if (ssoConfigName !== '') {
          updateSSOConfigNameMutation({
            variables: {
              input: {
                marketAccessDomains: marketAccessDomainOptions,
                orgName: values.orgName,
                ssoConfigName,
              },
            },
          });
        }
        initOrgConfig({
          variables: {
            exportInput: {
              carrierExports,
              orgId: data.createOrg.id,
            },
            propertyInput: {
              enableLosses: values.enableLosses,
              lossStartYear: values.lossStartYear,
              orgId: data.createOrg.id,
            },
            submissionInput: {
              enableDashboard: values.enableAccountDashboard,
              orgName: data.createOrg.name,
              refreshFromTemplate: true,
              templateOrgName: selectedTemplateOrg,
            },
          },
        });

        onIntegrationsSave(data.createOrg.id);
      },
      onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    },
  );

  const updateOrg = () =>
    updateOrgMutation({
      refetchQueries: [{ query: GET_ORGANIZATIONS }],
      variables: {
        exportInput: {
          carrierExports,
          orgId: org.id,
        },
        input: {
          autoDomains: values.autoDomains,
          businessType: values.businessType,
          displayCurrency: values.displayCurrency,
          enableCarrierAssessment: values.enableCarrierAssessment,
          enableCurrencyConversion: values.enableCurrencyConversion,
          enableRedirectOnSOVUpload: values.enableRedirectOnSOVUpload,
          enableRiskManagerAssessment: values.enableRiskManagerAssessment,
          enableSOVManager: values.enableSOVManager,
          enableSecondaryModifiers: values.enableSecondaryModifiers,
          enableSendEmailOnSOVUpload: values.enableSendEmailOnSOVUpload,
          enableStreamDashboard: values.enableStreamDashboard,
          enableSubValues: values.enableSubValues,
          enableUnderwriterEdit: values.enableUnderwriterEdit,
          enableUploadPortal: values.enableUploadPortal,
          excludeFromReporting: values.excludeFromReporting,
          industry: values.industry,
          orgID: org?.id as string,
          orgName: values.orgName,
          orgType: values.orgType,
          reportingType: values.reportingType,
          salesChannel: values.salesChannel,
          salesforceAccountID: values.salesforceAccountID,
          showNonEnrichmentDocs: values.showNonEnrichmentDocs,
          unitOfMeasurement: values.unitOfMeasurement,
        },
        propertyInput: {
          enableLosses: values.enableLosses,
          lossStartYear: values.lossStartYear,
          orgId: org.id,
        },
        submissionInput: {
          enableDashboard: values.enableAccountDashboard,
          orgName: values.orgName,
          templateOrgName: data?.orgSubmissionConfig?.templateOrgName,
        },
      },
    });

  const [updateOrgMutation, updateOrgResult] = useMutation<IUpdateOrgData, IUpdateOrgVariables>(
    UPDATE_ORG,
    {
      onCompleted: (data) => {
        onIntegrationsSave(data.updateOrg.id);
        if (data?.updateOrg?.id === selectedOrganization?.id) {
          setOrgByName(data?.updateOrg.name);
        }
        closeModal();
      },
      onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    },
  );

  const [enableIntegration, { loading: isSavingIntegrations }] = useMutation<
    IEnableIntegrationData,
    IEnableIntegrationVariables
  >(ENABLE_INTEGRATION, {
    client: integrationsApolloClient,
    onCompleted: () => {
      closeModal();
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const { data: roleAssignmentsData } = useQuery<
    GetRoleAssignmentsData,
    GetRoleAssignmentsVariables
  >(GET_ROLE_ASSIGNMENTS, {
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    skip: !getOrgInfo,
    variables: {
      scope: {
        type: ScopeType.OrgId,
        value: org?.id as string,
      },
    },
  });

  const [updateSSOConfigNameMutation] = useConfigureAuthorizationMutation({
    onCompleted: () => {},
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const { data: ssoConfigNameData, loading: ssoConfigDataLoading } = useAuthorizationConfigQuery({
    onCompleted: ({ authorizationConfig }) => {
      setSSOConfigName(authorizationConfig.ssoConfigName);
      setMarketAccessDomainOptions(authorizationConfig.marketAccessDomains || []);
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    skip: !getOrgInfo,
    variables: {
      orgName: org?.name,
    },
  });

  useEffect(() => {
    const isZorbaEnabled = values.enableZorbaExport ? CarrierExportCode.ZORBA : null;
    const isRapidEnabled = values.enableRapidExport ? CarrierExportCode.RAPID : null;
    const exportArray = [isRapidEnabled, isZorbaEnabled].filter(Boolean);

    setCarrierExports(exportArray);
  }, [values.enableZorbaExport, values.enableRapidExport]);

  const isSaving = createOrgResult.loading || updateOrgResult.loading || isSavingIntegrations;
  const disabled = loadingOrg || isSaving;

  const currentPMRole = Role.PropertyEditor;
  const currentAssignments = (roleAssignmentsData?.roleAssignmentsV2?.assignments || []).filter(
    (ra) => ra.role === currentPMRole,
  );
  const [clearAssignmentsConfirmed, setClearAssignmentsConfirmed] = useState<boolean>(false);

  useEffect(() => {
    setShowSaveButton(isValid(action, values));
  }, [JSON.stringify(values)]);

  const updateValues = (newValues: IForm) => {
    setValues(newValues);
    setShowSaveButton(isValid(action, newValues));
  };

  const onValueChange = (field: string) => (e: any) => {
    const newValue = nameToValue(field, e);
    if (field === 'autoDomains') {
      setValues((prev) => {
        const current = prev.autoDomains || [];
        const newDomains = typeof e === 'string' ? current.concat(e) : e.map((o: any) => o.label);
        const newValues = { ...values, autoDomains: newDomains };
        setDomainOptions(
          [...domainOptions, ...newDomains].filter((v, i, arr) => arr.indexOf(v) === i),
        );

        setShowSaveButton(isValid(action, newValues));
        return newValues;
      });
    } else {
      updateValues({ ...values, [field]: newValue });
    }
  };
  const tabs = [
    {
      content: (
        <ContentWrapper>
          <GeneralTab />
        </ContentWrapper>
      ),
      'data-testid': 'general-tab',
      id: 'general',
      label: 'General',
      style: { padding: '0 10px' },
    },
    {
      content: (
        <ContentWrapper>
          <PageConfigurationTab />
        </ContentWrapper>
      ),

      'data-testid': 'page-configuration-tab',
      id: 'pageconfig',
      label: 'Page Configuration',
      style: { padding: '0 10px' },
    },
    {
      content: (
        <ContentWrapper>
          <IntegrationsTab organizationId={orgId} organizationName={orgName} />
        </ContentWrapper>
      ),
      'data-testid': 'integrations-tab',
      id: 'integrations',
      label: 'Integrations',
      style: { padding: '0 10px' },
    },

    {
      content: (
        <ContentWrapper>
          <RbacTab />
        </ContentWrapper>
      ),
      'data-testid': 'rbac-tab',
      // FIXME: Disabling RBAC tab temporarily during loading because the "Market Access Domains"
      // doesnt render correctly when RBAC tab is opened before the useAuthorizationConfigQuery
      // query is done loading
      disabled: loadingOrg || ssoConfigDataLoading,

      id: 'rbac',

      label: 'RBAC',

      style: { padding: '0 10px' },
    },
  ];

  const onIntegrationsSave = (orgID: string) => {
    if (
      integrationsSettings.enabled !== defaultIntegrationSettings.enabled ||
      integrationsSettings.limit !== defaultIntegrationSettings.limit
    ) {
      enableIntegration({
        variables: {
          input: {
            enableIntegration: integrationsSettings.enabled,
            limit: integrationsSettings.limit,
            organizationID: orgID,
            partnerName: E2VALUE,
          },
        },
      });
    }
  };

  // return true when the original marketAccessDomains and the current marketAccessDomainOptions are the same
  const marketAccessDomainsEqual = () => {
    const currentMarketAccessDomains = marketAccessDomainOptions || [].sort();
    const originalMarketAccessDomains =
      ssoConfigNameData?.authorizationConfig?.marketAccessDomains || [].sort();
    return (
      JSON.stringify(currentMarketAccessDomains) === JSON.stringify(originalMarketAccessDomains)
    );
  };

  const onSave = () => {
    if (action === 'edit' && org) {
      if (currentAssignments.length > 0 && !clearAssignmentsConfirmed) {
        setConfirmUpdateModalIsOpen(true);
      } else {
        updateOrg();

        // The SSO Config Name is stored in a different place then the organization, that's why we
        // need two mutations.
        if (
          ssoConfigName !== ssoConfigNameData?.authorizationConfig?.ssoConfigName ||
          !marketAccessDomainsEqual()
        ) {
          updateSSOConfigNameMutation({
            variables: {
              input: {
                marketAccessDomains: marketAccessDomainOptions,
                orgName: org.name,
                ssoConfigName,
              },
            },
          });
        }
      }
    } else {
      createOrg({
        refetchQueries: [{ query: GET_ORGANIZATIONS }],
        variables: {
          input: {
            autoDomains: values.autoDomains,
            businessType: values.businessType,
            displayCurrency: values.displayCurrency,
            enableCarrierAssessment: values.enableCarrierAssessment,
            enableCurrencyConversion: values.enableCurrencyConversion,
            enableRedirectOnSOVUpload: values.enableRedirectOnSOVUpload,
            enableRiskManagerAssessment: values.enableRiskManagerAssessment,
            enableSecondaryModifiers: values.enableSecondaryModifiers,
            enableSendEmailOnSOVUpload: values.enableSendEmailOnSOVUpload,
            enableStreamDashboard: values.enableStreamDashboard,
            enableSubValues: values.enableSubValues,
            enableUnderwriterEdit: values.enableUnderwriterEdit,
            enableUploadPortal: values.enableUploadPortal,
            excludeFromReporting: values.excludeFromReporting,
            industry: values.industry,
            orgLevel: values.orgLevel,
            orgName: values.orgName,
            orgType: values.orgType,
            reportingType: values.reportingType,
            salesChannel: values.salesChannel,
            salesforceAccountID: values.salesforceAccountID,
            showNonEnrichmentDocs: values.showNonEnrichmentDocs,
            unitOfMeasurement: values.unitOfMeasurement,
          },
        },
      });
    }
  };

  return (
    <>
      <EuiModal onClose={closeModal} initialFocus="[name=orgName]" className={styles.modal}>
        <OrgModalProvider
          org={org}
          orgId={orgId}
          orgName={orgName}
          action={action}
          values={values}
          integrationsSettings={integrationsSettings}
          selectedTemplateOrg={selectedTemplateOrg}
          domainOptions={domainOptions}
          ssoConfigName={ssoConfigName}
          disabled={disabled}
          onValueChange={onValueChange}
          setSelectedTemplateOrg={setSelectedTemplateOrg}
          setSSOConfigName={setSSOConfigName}
          updateValues={updateValues}
          setIntegrationsSettings={setIntegrationsSettings}
          marketAccessDomainOptions={marketAccessDomainOptions}
          setMarketAccessDomainOptions={setMarketAccessDomainOptions}
        >
          <EuiModalHeader>
            <EuiText>
              <h3>{modalHeaderText}</h3>
            </EuiText>
          </EuiModalHeader>
          <EuiModalBody>
            <Tabs data-testid="org-edit-modal-tabs" initialTabId="general" size="s" tabs={tabs} />
          </EuiModalBody>
          <EuiModalFooter>
            <ButtonEmpty
              data-testid="cancel-organization-button"
              onClick={closeModal}
              label="Cancel"
            />
            <Button
              data-testid="save-organization-button"
              onClick={() => onSave()}
              fill
              disabled={!showSaveButton}
              loading={disabled}
              label={isSaving ? 'Saving' : 'Save'}
            />
          </EuiModalFooter>
          {confirmUpdateModalIsOpen && (
            <ConfirmUpdateModal
              currentAssignments={currentAssignments}
              currentPMRole={currentPMRole}
              onCancel={() => setConfirmUpdateModalIsOpen(false)}
              onConfirm={() => {
                setConfirmUpdateModalIsOpen(false);
                setClearAssignmentsConfirmed(true);
                updateOrg();
              }}
            />
          )}
        </OrgModalProvider>
      </EuiModal>
    </>
  );
};

export default AddEditOrgModal;
