import styled from '@emotion/styled';
import { EuiModalHeader } from 'ui';

export const ContentContainer = styled.div`
  padding: 16px 0 0 0;
  overflow-y: auto;
`;

export const HeaderContainer = styled(EuiModalHeader)`
  padding: 24px 0 0 0;
`;
