import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { EuiFormControlLayout, EuiText } from '@elastic/eui';
import styled from '@emotion/styled';

const StyledCurrencyInput = styled(CurrencyInput)`
  width: 100%;
  height: 38px;
  padding: 12px;
  background-color: #f9fbfd;
  border: none;
  color: #343741;
  border-radius: 6px;
  font-size: 14px;
  background-size: 0% 100%;
  background-repeat: no-repeat;
  transition:
    box-shadow 150ms ease-in,
    background-image 150ms ease-in,
    background-size 150ms ease-in,
    background-color 150ms ease-in;

  &:focus {
    border-color: #4a90e2;
    background-color: #fff;
    background-image: linear-gradient(
      to top,
      #0f766e,
      #0f766e 2px,
      transparent 2px,
      transparent 100%
    );
    background-size: 100% 100%;
    outline: none;
  }
  &:disabled {
    background: #eef2f7;
  }

  &.drop-left-border {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  &.drop-right-border {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &.with-border {
    box-shadow:
      0 0 transparent,
      inset 0 0 0 1px rgba(22, 61, 76, 0.1);
  }
`;

const InputWithFormattedValue = (props) => {
  const {
    append = null,
    name,
    defaultValue = 0,
    value,
    onChange,
    intlConfig = undefined,
    disabled = false,
    prepend = null,
  } = props;

  const classNames = [];

  if (prepend !== null) {
    classNames.push('drop-left-border');
  }
  if (append !== null) {
    classNames.push('drop-right-border');
  }
  if (classNames.length === 0) {
    classNames.push('with-border');
  }

  return (
    <EuiFormControlLayout
      prepend={prepend && <EuiText>{prepend}</EuiText>}
      append={append && <EuiText>{append}</EuiText>}
    >
      <StyledCurrencyInput
        id={name}
        name={name}
        defaultValue={defaultValue}
        value={value}
        decimalsLimit={2}
        allowDecimals
        disabled={disabled}
        className={classNames.join(' ')}
        onValueChange={(value, name, values) => onChange(values.float)}
        intlConfig={intlConfig}
      />
    </EuiFormControlLayout>
  );
};

export default InputWithFormattedValue;
