import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip, Icon } from 'ui';
import { IColumn } from '@app/components/GroupedRow/types';
import {
  IGroupedProps,
  IPropertiesProps,
  IRowType,
} from '@app/components/PropertiesGroupedList/types';
import { getSnapshotLabel } from '@app/cx/Stream/Aside/Explorers/utils';
import { GetStreamPropertyGroupsQuery } from '@app/graphql/queries/streams/__generated__/StreamPropertyGroups.generated';
import { Snapshot } from '@app/graphql/types';
import { sortOrder } from '@app/queries/explorers/ColumnsQuery/utils';
import { ILossStat, IStats } from '@app/queries/explorers/types';
import {
  buildChangeData,
  buildSparklineData,
  formatPercentage,
} from '@app/queries/explorers/utils';
import { IAttributeProvenance } from '@app/queries/properties/types';
import { ISubGroup } from '@app/queries/streams/PropertyGroupsQuery/types';
import { formatNumber } from '@app/utils/format';
import { IGraphQLSnapshot } from '../types';
import { IGetPropertyGroupsData, IGroup, IPropertyGroups } from './types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';

export function formatHistoricalLossData(
  avgLossCost: ILossStat,
  changeAnalysis: boolean,
  trend?,
  order: number = 0,
): Array<IColumn> {
  const chartOptions = trend
    ? {
        data: buildSparklineData(trend || [], false),
        label: 'Last 5ys',
        name: 'loss_cost_over_5_yrs',
        order,
        type: 'spark' as 'spark',
        width: 100,
      }
    : null;

  const numYearLabel = avgLossCost?.numYears ? `${avgLossCost?.numYears}y avg.` : 'Prev. bound NA';

  const avgLossCostLabel = avgLossCost ? (
    `${avgLossCost?.value}‰`
  ) : (
    <EuiText color="subdued">--</EuiText>
  );
  return [
    {
      ...chartOptions,
    },
    {
      changeAnalysis: !!changeAnalysis,
      data: avgLossCostLabel,
      label: (
        <EuiFlexGroup gutterSize="xs">
          <EuiFlexItem grow>Historical Loss Cost</EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiToolTip
              title="Gross total loss / $1,000s of TIV"
              content={
                <p>
                  This based on <b>true historical losses</b>. Gross total loss represents the full
                  ground up cost for the policy period. It includes deductibles, paid and reserved
                  amounts, and claims for disposed properties. This excludes losses that occurred
                  before a property was added to this stream.
                </p>
              }
            >
              <Icon name="info" color="primary" />
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
      labelAlignment: 'center',
      name: 'avg_loss_cost',
      order,
      type: 'text' as 'text',
      width: 140,
      years: numYearLabel,
    },
  ];
}

export const translateStatToColumn = (
  stat: any,
  name: string,
  changeAnalysis?: boolean,
  previousSnapshotLabel?: string,
  isSubGroup?: boolean,
  propertyAttributeMetadata?: IGraphQLAttributeMetadata[],
): Array<IColumn> | undefined => {
  switch (name) {
    case 'tivChart':
      return changeAnalysis && isSubGroup
        ? [
            {
              change: buildChangeData(stat.tivYoyPctDiff, previousSnapshotLabel),
              changeAnalysis: !!changeAnalysis,
              data: stat.sum,
              dataFormat: 'currency',
              dataSubtitle: stat.percentageOfTotalTiv
                ? `(${formatPercentage(stat.percentageOfTotalTiv)}%)`
                : 'N/A',
              label: 'TIV',
              labelAlignment: 'center',
              name: 'total_tiv_by_group',
              order: 2,
              type: 'text' as 'text',
              width: 140,
            },
          ]
        : [
            {
              data: buildSparklineData(stat.trend || [], true),
              label: 'Last 5 yrs',
              name: 'total_tiv_over_5_yrs',
              order: 1,
              type: 'spark' as 'spark',
              width: 100,
            },
            {
              change: buildChangeData(stat.tivYoyPctDiff, previousSnapshotLabel),
              changeAnalysis: !!changeAnalysis,
              data: stat.sum,
              dataFormat: 'currency',
              dataSubtitle: stat.percentageOfTotalTiv
                ? `(${formatPercentage(stat.percentageOfTotalTiv)}%)`
                : 'N/A',
              label: 'TIV',
              labelAlignment: 'center',
              name: 'total_tiv_by_group',
              order: 2,
              type: 'text' as 'text',
              width: 140,
            },
          ];
    case 'lossCostTrend':
      const avgLossCost = stat?.avgLossCost;
      if (stat) {
        return formatHistoricalLossData(avgLossCost, changeAnalysis, stat?.trend, 9);
      }
      break;
    case 'avgLossCost':
      if (stat && changeAnalysis && isSubGroup) {
        return formatHistoricalLossData(stat, changeAnalysis, null, 10);
      }
      if (changeAnalysis) {
        return [
          {
            changeAnalysis: !!changeAnalysis,
            data: null,
            label: null,
            labelAlignment: 'center',
            name: 'avg_loss_cost',
            order: 10,
            type: 'text' as 'text',
            width: 140,
          },
        ];
      }
      break;
    case 'propertiesChart':
      return [
        {
          change: buildChangeData(stat.propertyYoYPctDiff, previousSnapshotLabel),
          changeAnalysis: !!changeAnalysis,
          data: formatNumber(stat.propertyCount),
          label: 'Properties',
          name: 'total_properties',
          order: 3,
          type: 'text' as 'text',
          width: 85,
        },
      ];
    case 'constructionType':
      const constructionAttributeMetadata = propertyAttributeMetadata?.find(
        (attr: any) => attr.name === 'constructionType',
      )?.enumMetadata;

      return [
        {
          data: stat?.data?.map((datum: { name: string; value: number }) => ({
            label: datum.name,
            value: datum.value,
            color: constructionAttributeMetadata?.find((data) => {
              return data.value === datum.name;
            })?.hexColorCode,
          })),
          label: 'Construction',
          name: 'percentage_by_construction',
          order: 5,
          type: 'donut' as 'donut',
          width: 75,
        },
      ];
    case 'occupancyType':
      const occupancyAttributeMetadata = propertyAttributeMetadata?.find(
        (attr: any) => attr.name === 'occupancyType',
      )?.enumMetadata;

      return [
        {
          data: stat?.data?.map((datum: { name: string; value: number }) => ({
            label: datum.name,
            value: datum.value,
            color: occupancyAttributeMetadata?.find((data) => {
              return data.value === datum.name;
            })?.hexColorCode,
          })),
          label: 'Occupancy',
          name: 'percentage_by_occupancy',
          order: 4,
          type: 'donut' as 'donut',
          width: 65,
        },
      ];
    case 'avgBuildYear':
      return [
        {
          changeAnalysis: !!changeAnalysis,
          data: stat,
          label: 'Avg. build year',
          name: 'avg_build_year',
          order: 8,
          type: 'text' as 'text',
          width: 95,
        },
      ];
    case 'avgSquareFeet':
      return [
        {
          changeAnalysis: !!changeAnalysis,
          data: formatNumber(stat, undefined, {
            compactDisplay: 'short',
            maximumSignificantDigits: 3,
            notation: 'compact',
          } as Intl.NumberFormatOptions),
          label: 'Avg. floor area',
          name: 'avg_sq_ft',
          order: 7,
          type: 'text' as 'text',
          width: 90,
        },
      ];
    case 'avgValue':
      return [
        {
          changeAnalysis: !!changeAnalysis,
          data: stat,
          dataFormat: 'currency',
          label: 'Avg. value',
          name: 'avg_value',
          order: 6,
          type: 'text' as 'text',
          width: 84,
        },
      ];
    case 'totalGrossLoss':
      return [
        {
          changeAnalysis: !!changeAnalysis,
          data: stat?.value || '-',
          dataFormat: 'currency',
          label: (
            <EuiFlexGroup gutterSize="xs">
              <EuiFlexItem grow>Gross total loss</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiToolTip
                  content={
                    <p>
                      This excludes losses that occurred before a property was added to this stream.
                    </p>
                  }
                >
                  <Icon name="info" color="primary" />
                </EuiToolTip>
              </EuiFlexItem>
            </EuiFlexGroup>
          ),
          name: 'gross_total_loss',
          order: 11,
          type: 'text' as 'text',
          width: 120,
          years: stat?.numYears ? `${stat?.numYears}y total` : '--',
        },
      ];
    case 'totalLossCount':
      return [
        {
          changeAnalysis: !!changeAnalysis,
          data: stat?.value || '-',
          label: (
            <EuiFlexGroup gutterSize="xs">
              <EuiFlexItem grow>Count of losses</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiToolTip
                  content={
                    <p>
                      This excludes losses that occurred before a property was added to this stream.
                    </p>
                  }
                >
                  <Icon name="info" color="primary" />
                </EuiToolTip>
              </EuiFlexItem>
            </EuiFlexGroup>
          ),
          name: 'count_of_losses',
          order: 12,
          type: 'text' as 'text',
          width: 120,
          years: stat?.numYears ? `${stat?.numYears}y total` : '--',
        },
      ];
    default:
      console.warn(`Undefined stat name: ${name}`);
      return undefined;
  }
};

export const getStatsAsColumns = (
  stats: IStats,
  explorersToDisplay: Array<string>,
  changeAnalysis?: boolean,
  previousSnapshotLabel?: string,
  isSubGroup?: boolean,
  propertyAttributeMetadata?: any,
): Array<IColumn> =>
  Object.entries(stats)
    .reduce((arr, [name, stat]) => {
      if (name === '__typename') {
        return arr;
      }

      const explorer = translateStatToColumn(
        stat,
        name,
        changeAnalysis,
        previousSnapshotLabel,
        isSubGroup,
        propertyAttributeMetadata,
      );

      const explorerDisplayMap = { loss_trend: true };
      for (let i = 0; i < explorersToDisplay.length; i++) {
        explorerDisplayMap[explorersToDisplay[i]] = true;
      }

      if (explorer) {
        return arr.concat(explorer).filter((v) => explorerDisplayMap[v.name]);
      }
      return arr;
    }, [] as Array<IColumn>)
    .sort((a, b) => sortOrder[b.name] - sortOrder[a.name]);

function translateGroupByToProvenance(group?: ISubGroup): IAttributeProvenance {
  const groupProvenanceSource = {
    changeAnalysis: (row: string = '') => {
      const rows = {
        Disposed: 'Properties that have been removed from the account since it was last bound',
        New: 'Properties that have been added to the account since it was last bound',
        Ongoing:
          'Properties that were in the account when it was last bound, and are still' +
          ' currently in the account.',
      };
      return rows[row] || '';
    },
    default: () => '',
    enriched: (row: string = '') => {
      const rows = {
        No: 'No documents available for the property.',
        Yes:
          'At least one source document provided by the client has been used to' +
          ' populate or validate property data.',
      };
      return rows[row] || '';
    },
    region: () =>
      'Regions are currently defined by geography. The USA has been' +
      ' split into regions defined by possible CAT zones. Rest of the world' +
      ' is defined either by country or continent.',
  };

  const groupByOrDefault = (g?: string) => (g && g in groupProvenanceSource ? g : 'default');
  const rowNameOrDefault = (r?: string) => r || 'default';

  const groupBy = groupByOrDefault(group?.groupingAttribute?.name);
  const rowName = rowNameOrDefault(group?.groupingAttribute?.value);

  return {
    attributeName: '',
    externalSourceURLs: [],
    sourceDocuments: null,
    sources: groupProvenanceSource[groupBy](rowName),
  };
}

const getPropertyGroupData = (
  data: IGetPropertyGroupsData,
  changeAnalysis?: boolean,
): IPropertyGroups => {
  const { streamPropertyGroups, compareChanges } = data;

  return (changeAnalysis ? compareChanges : streamPropertyGroups) as IPropertyGroups;
};

export const getTotalGroup = (
  data: IGetPropertyGroupsData,
  snapshots: Array<IGraphQLSnapshot>,
  changeAnalysis?: boolean,
): IGroupedProps | null => {
  const propertyGroup = getPropertyGroupData(data, changeAnalysis);

  if (!propertyGroup) {
    return null;
  }

  const {
    groups: { totalPropertyCount, filteredPropertyCount, stats, groupID },
    explorers,
    previousSnapshot,
  } = propertyGroup;

  const previousSnapshotLabel = getSnapshotLabel(snapshots, previousSnapshot);

  const explorersSorted = getStatsAsColumns(
    stats,
    explorers,
    changeAnalysis,
    previousSnapshotLabel,
  ).sort((a, b) => a.order - b.order);

  return {
    explorers: explorersSorted,
    filteredPropertyCount,
    id: groupID,
    label: 'test',
    totalPropertyCount,
    type: 'group',
  };
};
type GetPreparedDataAsGroups = (
  data: IGetPropertyGroupsData | GetStreamPropertyGroupsQuery,
  snapshots: Array<IGraphQLSnapshot> | Snapshot[],
  changeAnalysis?: boolean,
  propertyAttributeMetadata?: IGraphQLAttributeMetadata[],
) => Array<IGroup>;

export const getPreparedDataAsGroups: GetPreparedDataAsGroups = (
  data,
  snapshots,
  changeAnalysis, // changesSince
  propertyAttributeMetadata,
) => {
  if (!data) {
    return [];
  }
  // FIX ME
  // @ts-ignore
  const propertyGroup = getPropertyGroupData(data, changeAnalysis);
  if (!propertyGroup) {
    return [];
  }
  const {
    groups: { subGroups, groupID, totalPropertyCount, filteredPropertyCount },
    explorers,
    previousSnapshot,
  } = propertyGroup;

  if (subGroups === null) {
    const props: IPropertiesProps = {
      group: {
        explorers: [],
        filteredPropertyCount,
        id: groupID,
        label: 'unknown',
        totalPropertyCount,
        type: 'group',
      },
      properties: undefined,
      type: 'properties' as IRowType,
    };

    return [
      {
        props,
      },
    ];
  }

  return subGroups.map((group) => {
    const props: IGroupedProps = {
      explorers: getStatsAsColumns(
        group.stats,
        explorers,
        changeAnalysis,
        // FIX ME
        // @ts-ignore
        getSnapshotLabel(snapshots, previousSnapshot),
        true,
        propertyAttributeMetadata,
      ),
      filteredPropertyCount: group.filteredPropertyCount,
      id: group.groupID,
      label: group?.groupingAttribute?.value || '',
      provenance: translateGroupByToProvenance(group),
      totalPropertyCount: group.totalPropertyCount,
      type: 'group',
    };

    const propertiesProps: IPropertiesProps = {
      group: props,
      properties: undefined,
      type: 'properties' as IRowType,
    };

    const newGroup: IGroup = {
      props,
      rows: [
        {
          props: propertiesProps,
        },
      ],
    };

    return newGroup;
  });
};
