import { FormValues } from '@onarchipelago/cx/Dashboard/Submissions/types';
import { InterfaceAlias } from '@app/utils/alias';
import {
  validateArray,
  validateEmail,
  validateEmails,
  ValidateFunction,
  validateRequired,
} from '@app/utils/form';

export interface FieldMeta {
  error?: string;
  touched?: boolean;
}

export type SubmissionFormMeta = InterfaceAlias<FormValues, FieldMeta | undefined>;
export type Validator = InterfaceAlias<FormValues, Array<ValidateFunction>>;

// Fixed validators are only used in tests. With the Configurable Submissions Dashboard feature release, we now pull validator data from the API
export const editSubmissionValidator: Validator = {
  broker: [validateRequired],
  name: [validateRequired],
  underwriter: [validateArray],
};

export const renewSubmissionValidator: Validator = {
  ...editSubmissionValidator,
  businessType: [validateRequired],
  customerStreamSlug: [validateRequired],
  documents: [validateArray],
  industry: [validateRequired],
  name: undefined,
};

export const newSubmissionValidator: Validator = {
  ...renewSubmissionValidator,
  customerStreamSlug: undefined,
  name: [validateRequired],
};

export const validate = (validator: Validator, field: string, value: any): string => {
  if (!validator[field]) {
    return '';
  }

  const fieldValidators = validator[field];
  for (let i = 0; i < fieldValidators.length; i++) {
    const err = fieldValidators[i](value);
    if (err) {
      return err;
    }
  }

  return '';
};

export const validateAll = (validator: Validator, values: FormValues): boolean => {
  const fields = Object.keys(validator);
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    if (validate(validator, field, values[field])) {
      return false;
    }
  }

  return true;
};

const validateEmailArray = validateEmails('Emails must be valid');
export const getValidatorFromSubmissionData = (
  data?: any,
  requireDocs?: boolean,
  requireName?: boolean,
) => {
  const validator: Validator = {};
  const requiredFields = ['name', 'accountOrgType', 'industry', 'businessType'];
  if (data) {
    data.submissionAttributeMetadata.forEach((f) => {
      if (requiredFields.includes(f.name) && f.type !== 'Boolean') {
        validator[f.name] = [validateRequired];
      }

      if (f.name?.includes('teamMemberEmails') || f.name?.toLowerCase()?.includes('email')) {
        const validate = f.name?.includes('teamMemberEmails') ? validateEmailArray : validateEmail;
        if (validator[f.name]?.length) {
          validator[f.name].push(validate);
        } else {
          validator[f.name] = [validate];
        }
      }
    });
  }
  if (requireName) {
    validator.name = [validateRequired];
  }
  if (requireDocs) {
    validator.documents = [validateArray];
  }
  return validator;
};
