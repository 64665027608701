import React from 'react';

export const Confetti = () => (
  <svg viewBox="0 0 401 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8556_5783)">
      <path d="M-13 -106.965H408V254.437H-13V-106.965Z" fill="white" />
      <path
        d="M238.786 37.9137L239.119 34.5741L236.404 34.2231L236.071 37.5626L238.786 37.9137Z"
        fill="#FFDE7D"
      />
      <path
        d="M17.789 14.5093L19.3379 12.898L15.2464 8.03273L13.6976 9.64405L17.789 14.5093Z"
        fill="#FFDE7D"
      />
      <path
        d="M16.4324 24.8543L17.3916 23.211L14.8289 20.8036L13.8696 22.447L16.4324 24.8543Z"
        fill="#FFDE7D"
      />
      <path
        d="M360.918 16.2341L359.971 13.4328L357.842 14.0796L358.789 16.8809L360.918 16.2341Z"
        fill="#F8F3D4"
      />
      <path
        d="M43.6171 38.1653L43.6221 35.399L39.7352 34.015L39.7302 36.7814L43.6171 38.1653Z"
        fill="#00B8A9"
      />
      <path
        d="M254.953 33.0327L253.975 30.437L251.114 32.0129L252.093 34.6086L254.953 33.0327Z"
        fill="#F6416C"
      />
      <path
        d="M133.232 26.9085L135.729 26.8542L133.735 21.1542L131.237 21.2085L133.232 26.9085Z"
        fill="#F6416C"
      />
      <path
        d="M328.697 32.6681L326.229 31.2892L325.255 35.7656L327.722 37.1445L328.697 32.6681Z"
        fill="#F6416C"
      />
      <path
        d="M144.197 47.5251L144.113 45.7048L141.394 45.6458L141.478 47.4661L144.197 47.5251Z"
        fill="#F8F3D4"
      />
      <path
        d="M139.613 36.6614L141.196 35.849L138.112 29.4011L136.529 30.2135L139.613 36.6614Z"
        fill="#F8F3D4"
      />
      <path
        d="M297.039 14.6176L296.422 12.5091L294.222 13.7108L294.839 15.8193L297.039 14.6176Z"
        fill="#00B8A9"
      />
      <path
        d="M363.695 7.77532L363.295 5.44919L360.974 5.39955L361.374 7.72569L363.695 7.77532Z"
        fill="#00B8A9"
      />
      <path
        d="M396.032 51.9773L394.485 50.9346L393.09 54.7725L394.637 55.8152L396.032 51.9773Z"
        fill="#F6416C"
      />
      <path
        d="M369.664 49.1887L371.602 47.8037L369.299 41.5976L367.361 42.9826L369.664 49.1887Z"
        fill="#F6416C"
      />
      <path
        d="M106.056 8.33754L107.51 7.36804L106.849 4.92991L105.396 5.89941L106.056 8.33754Z"
        fill="#FFDE7D"
      />
      <path
        d="M220.292 18.5801L218.544 16.8308L215.779 20.9018L217.527 22.6511L220.292 18.5801Z"
        fill="#F8F3D4"
      />
      <path
        d="M226.27 12.4218L225.396 10.3794L222.522 11.1238L223.396 13.1662L226.27 12.4218Z"
        fill="#FFDE7D"
      />
      <path
        d="M62.1394 35.2761L62.709 32.1555L59.4187 31.8646L58.8491 34.9852L62.1394 35.2761Z"
        fill="#FFDE7D"
      />
      <path
        d="M41.5181 23.2217L38.8428 22.5084L38.2233 29.7364L40.8987 30.4497L41.5181 23.2217Z"
        fill="#00B8A9"
      />
      <path
        d="M286.315 0.99143L284.623 -1.80997L280.561 -0.0573307L282.253 2.74407L286.315 0.99143Z"
        fill="#00B8A9"
      />
      <path
        d="M226.454 16.6318L224.268 15.9164L224.321 20.4076L226.508 21.123L226.454 16.6318Z"
        fill="#00B8A9"
      />
      <path
        d="M226.384 -1.02599L224.886 -1.87445L224.154 1.77295L225.652 2.62141L226.384 -1.02599Z"
        fill="#F6416C"
      />
      <path
        d="M351.645 40.4104L350.354 37.5679L344.797 40.9921L346.088 43.8346L351.645 40.4104Z"
        fill="#F6416C"
      />
      <path
        d="M296.931 42.6075L299.103 41.9684L297.742 37.6898L295.57 38.329L296.931 42.6075Z"
        fill="#00B8A9"
      />
      <path
        d="M127.452 17.5944L129.485 16.5728L127.518 11.9764L125.484 12.9979L127.452 17.5944Z"
        fill="#FFDE7D"
      />
      <path
        d="M110.292 2.27093L110.756 0.442505L107.053 0.27834L106.589 2.10677L110.292 2.27093Z"
        fill="#F8F3D4"
      />
      <path
        d="M200.032 29.8292L201.168 27.9074L199.673 26.5617L198.537 28.4836L200.032 29.8292Z"
        fill="#00B8A9"
      />
      <path
        d="M182.687 18.8479L179.953 17.8238L180.579 24.0199L183.313 25.044L182.687 18.8479Z"
        fill="#F6416C"
      />
      <path
        d="M217.368 27.9335L217.635 25.9815L214.276 24.4829L214.01 26.4349L217.368 27.9335Z"
        fill="#F8F3D4"
      />
      <path
        d="M281.884 38.6209L282.967 36.9694L280.871 34.947L279.788 36.5985L281.884 38.6209Z"
        fill="#F6416C"
      />
      <path
        d="M288.408 37.365L287.925 35.3078L285.672 34.7141L286.156 36.7713L288.408 37.365Z"
        fill="#F6416C"
      />
      <path
        d="M35.9552 29.2934L37.6035 28.3357L35.3962 24.3437L33.7479 25.3014L35.9552 29.2934Z"
        fill="#F8F3D4"
      />
      <path
        d="M5.84462 2.00584L4.82617 -0.696228L1.15008 1.2887L2.16853 3.99077L5.84462 2.00584Z"
        fill="#F6416C"
      />
      <path
        d="M248.556 7.01215L245.93 6.31433L244.509 12.1228L247.136 12.8206L248.556 7.01215Z"
        fill="#FFDE7D"
      />
      <path
        d="M283.224 41.5504L280.875 40.077L279.668 45.4274L282.017 46.9008L283.224 41.5504Z"
        fill="#F8F3D4"
      />
      <path
        d="M46.2686 26.7496L48.2891 26.9862L48.0156 21.0375L45.9951 20.8009L46.2686 26.7496Z"
        fill="#FFDE7D"
      />
      <path
        d="M29.5154 49.5146L30.8389 48.1667L28.3318 44.1507L27.0084 45.4986L29.5154 49.5146Z"
        fill="#FFDE7D"
      />
      <path
        d="M304.936 38.0233L304.207 34.6592L296.542 34.1709L297.271 37.535L304.936 38.0233Z"
        fill="#F6416C"
      />
      <path
        d="M371.042 32.3451L371 29.4832L365.373 30.3457L365.415 33.2077L371.042 32.3451Z"
        fill="#00B8A9"
      />
      <path
        d="M153.534 50.3071L154.888 48.0828L150.199 43.2636L148.845 45.4879L153.534 50.3071Z"
        fill="#00B8A9"
      />
      <path
        d="M30.9667 54.2669L31.9141 52.8202L28.856 49.8613L27.9087 51.3079L30.9667 54.2669Z"
        fill="#F6416C"
      />
      <path
        d="M202.062 16.7399L199.67 13.3841L194.938 15.6048L197.33 18.9606L202.062 16.7399Z"
        fill="#F8F3D4"
      />
      <path
        d="M64.8163 49.6789L63.791 47.8052L61.1636 50.1585L62.1889 52.0322L64.8163 49.6789Z"
        fill="#FFDE7D"
      />
      <path
        d="M161.507 7.84719L161.694 4.69391L154.747 3.27889L154.56 6.43217L161.507 7.84719Z"
        fill="#00B8A9"
      />
      <path
        d="M188.1 6.6977L186.953 5.16089L185.262 7.06356L186.409 8.60036L188.1 6.6977Z"
        fill="#00B8A9"
      />
      <path
        d="M23.6529 27.1675L22.3154 26.2903L21.6931 27.8729L23.0305 28.7502L23.6529 27.1675Z"
        fill="#FFDE7D"
      />
      <path
        d="M263.741 51.3449L261.929 49.488L259.105 53.7917L260.917 55.6487L263.741 51.3449Z"
        fill="#F8F3D4"
      />
      <path
        d="M304.731 6.9573L304.188 5.17145L301.579 5.69674L302.122 7.4826L304.731 6.9573Z"
        fill="#00B8A9"
      />
      <path
        d="M344.107 28.6707L345.329 26.233L342.543 23.6978L341.321 26.1355L344.107 28.6707Z"
        fill="#FFDE7D"
      />
      <path
        d="M309.851 30.8337L312.229 30.2419L312.414 23.6607L310.036 24.2526L309.851 30.8337Z"
        fill="#FFDE7D"
      />
      <path
        d="M98.6182 41.6447L98.9092 40.226L94.7337 37.0547L94.4427 38.4734L98.6182 41.6447Z"
        fill="#FFDE7D"
      />
      <path
        d="M230.002 11.554L228.14 11.7577L228.849 16.6706L230.71 16.467L230.002 11.554Z"
        fill="#00B8A9"
      />
      <path
        d="M378.886 24.1689L377.287 22.1877L375.114 24.3885L376.713 26.3697L378.886 24.1689Z"
        fill="#FFDE7D"
      />
      <path
        d="M50.1558 6.05867L51.8516 5.07306L50.3776 1.31542L48.6818 2.30104L50.1558 6.05867Z"
        fill="#FFDE7D"
      />
      <path
        d="M79.4807 27.1161L79.5391 25.0462L75.9709 24.9694L75.9126 27.0393L79.4807 27.1161Z"
        fill="#00B8A9"
      />
      <path
        d="M18.4845 0.582735L16.5967 -0.510895L14.9401 3.71064L16.8279 4.80427L18.4845 0.582735Z"
        fill="#FFDE7D"
      />
      <path
        d="M394.312 50.1301L393.98 47.907L388.554 49.8705L388.886 52.0937L394.312 50.1301Z"
        fill="#F8F3D4"
      />
      <path
        d="M191.111 25.4649L188.798 24.0418L188.634 27.2301L190.947 28.6532L191.111 25.4649Z"
        fill="#F6416C"
      />
      <path
        d="M246.894 25.3463L245.769 24.1803L244.691 25.7683L245.816 26.9343L246.894 25.3463Z"
        fill="#FFDE7D"
      />
      <path
        d="M202.174 14.198L200.819 12.5272L198.076 15.5619L199.431 17.2327L202.174 14.198Z"
        fill="#F6416C"
      />
      <path d="M168.765 18.5432L167.916 15.5892H162.048L162.897 18.5432H168.765Z" fill="#F6416C" />
    </g>
    <defs>
      <clipPath id="clip0_8556_5783">
        <rect width="421" height="80" fill="white" transform="translate(-13 -29)" />
      </clipPath>
    </defs>
  </svg>
);
