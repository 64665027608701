import { useContext } from 'react';
import TrackerContext from '@app/components/Tracker/TrackerContext';
import { ITrackerContext, ITrackerMixpanel } from '@app/components/Tracker/types';
import { IS_DEV_PLATFORM, IS_JEST_ENV } from '@app/config';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useTrackEventMutation } from '@app/graphql/mutations/events/__generated__/setOrgPreCheckConfig.generated';

type TrackerType = keyof ITrackerContext;

const mockTracker = {
  track: () => {},
};

const useAPITracker = () => {
  if (IS_JEST_ENV) {
    return mockTracker;
  }
  const { selectedOrganization } = useUserSession();

  const [execute] = useTrackEventMutation({
    onError: (err) => console.error('.apiTracker: could not track event', err),
  });

  const track = (name: string, payload: any = {}) => {
    if (!payload?.organization_id && selectedOrganization?.id) {
      payload.organization_id = selectedOrganization.id;
    }
    execute({ variables: { input: { name, payload: JSON.stringify(payload) } } });
    if (IS_DEV_PLATFORM) {
      console.log('.apiTracker: ', name, payload);
    }
  };

  return { track };
};

export function useTracker(trackerType?: 'mixpanel' | 'api'): ITrackerMixpanel;
export function useTracker(trackerType: TrackerType | 'api' = 'api') {
  if (trackerType === 'api') {
    return useAPITracker();
  }

  return useContext(TrackerContext)[trackerType];
}

interface TrackGroup {
  prefix: string;
  event_surface: string;
}

export const TrackGroupSovManager: TrackGroup = {
  event_surface: 'SovManager',
  prefix: 'SOV Manager',
};

export const TrackStreamPage: TrackGroup = {
  event_surface: 'StreamPage',
  prefix: 'Stream Page',
};

export const TrackStreamPageDocumentModal: TrackGroup = {
  event_surface: 'StreamPageDocumentModal',
  prefix: 'Stream Page Document Modal',
};

export const TrackGroupUserMgmt: TrackGroup = {
  event_surface: 'UserMgmt',
  prefix: 'User Mgmt',
};

export const TrackGroupMarketAccess: TrackGroup = {
  event_surface: 'MarketAccess',
  prefix: 'Market Access',
};

export const TrackGroupInbox: TrackGroup = {
  event_surface: 'Inbox',
  prefix: 'Inbox',
};

export const TrackGroupCasualty: TrackGroup = {
  event_surface: 'Casualty',
  prefix: 'Casualty',
};

export const TrackInsights: TrackGroup = {
  event_surface: 'Insights',
  prefix: 'Insights',
};

export const TrackSpotlight: TrackGroup = {
  event_surface: 'Spotlight',
  prefix: 'Spotlight',
};
