import React from 'react';
import { EuiFlexGroup, EuiForm, EuiToolTip, Icon } from 'ui';
import { getAttributeRow } from '@app/cx/Stream/AddPropertyFlyout/AdditionalAttributesTab/AdditionalAttributes';
import { AttributeRowLayoutEnum } from '@app/cx/Stream/AddPropertyFlyout/AttributeRow';
import { RecommendationType } from '@app/graphql/precheck/precheck.types';
import { getDescription } from '@app/platform/Inbox/utils/utils';
import { DocumentBadge } from '../V2/DocumentBadge';
import { UploadDocuments } from '../V2/UploadDocuments';

export const MissingAttributes = ({ task, refetch }) => (
  <EuiFlexGroup direction="column">
    <UploadDocuments
      recommendedDocs={task.recommendedDocuments}
      propertyID={task.propertyId}
      refetch={refetch}
    />
    <EuiForm>
      <EuiFlexGroup direction="column">
        {task.attributes
          .filter((attr) => attr.recommendationType === RecommendationType.MissingAttribute)
          .map((attribute) => {
            const orgAttributeMetadata = attribute.attributeMetadata;
            if (!orgAttributeMetadata) {
              return null;
            }

            const labelInfo =
              attribute.documents.length > 1 ? (
                <DocumentBadge doc={attribute.documents} />
              ) : (
                <DocumentBadge doc={attribute.documents[0]} />
              );

            return getAttributeRow(
              //for some reason pre check api has two different fields then what this wants
              // but those fields are not used in the function
              orgAttributeMetadata as any,
              undefined,
              undefined,
              // FIX ME
              // @ts-ignore
              {
                labelAction: (
                  <EuiToolTip
                    content={getDescription(attribute.name)}
                    display="block"
                    position="top"
                  >
                    <Icon color="text" name="help" />
                  </EuiToolTip>
                ),

                labelInfo,
                layout: AttributeRowLayoutEnum.vertical,
              },
            );
          })}
      </EuiFlexGroup>
    </EuiForm>
  </EuiFlexGroup>
);
