import React from 'react';
import { generatePath } from 'react-router';
import { Icon } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useNavContext } from '../SideNavContext';
import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';

export const UserLink = () => {
  const { selectedOrganization } = useUserSession();

  const link = generatePath('/organizations/:organizationName/users', {
    organizationName: selectedOrganization?.name,
  });

  return (
    <NavItem testId="header-users-tab" icon={<Icon name="user" />} link={link} label="Users" />
  );
};

export const Settings = () => {
  const { selectedOrganization } = useUserSession();
  const { permissions } = useNavContext();

  const alertLink = generatePath('/organizations/:organizationName/alerts-config', {
    organizationName: selectedOrganization?.name,
  });

  return (
    <NavGroup title="Settings">
      {permissions.canViewUsers && <UserLink />}
      {permissions.canViewAlerts && (
        <NavItem icon={<Icon name="mail" />} link={alertLink} label="Alerts" />
      )}
      <NavItem icon={<Icon name="help" />} link="/support" label="Support" />
    </NavGroup>
  );
};
