import React from 'react';

interface SOVHealthCircleProps {
  healthScore: string;
}

const SOVHealthCircle: React.FC<SOVHealthCircleProps> = ({ healthScore }) => {
  const score = parseInt(healthScore, 10);
  const isHealthy = score > 75;

  const circleStyle = {
    backgroundColor: isHealthy ? '#3B743B' : '#9F2620',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
  };

  return <div style={circleStyle}></div>;
};

export default SOVHealthCircle;
