export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
  ArchipelagoID: { input: any; output: any };
  Date: { input: any; output: any };
  DocumentID: { input: any; output: any };
  Time: { input: any; output: any };
  /** The `Upload` scalar type represents a multipart file upload. This is a built-in type in `gqlgen` */
  Upload: { input: any; output: any };
};

/** Actions listed below are in order of scope. */
export enum Action {
  Create = 'Create',
  Delete = 'Delete',
  Edit = 'Edit',
  List = 'List',
  View = 'View',
}

export type AddOrgToDomainInput = {
  domain: Scalars['String']['input'];
  orgID: Scalars['ID']['input'];
};

export type AddOrgToDomainInputV2 = {
  domain: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type ApplyAddPropertyJobInput = {
  /** Indicates whether lat/lng values were provided by the customer */
  customerProvidedGeocode: Scalars['Boolean']['input'];
  /**  Document IDs to be linked to the property.  */
  linkedDocumentIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  propertyData: PropertyDataInput;
  streamSlug: Scalars['String']['input'];
};

export type ApplyDeletePropertiesJobInput = {
  orgName: Scalars['String']['input'];
  propertyArchipelagoIDs: Array<Scalars['ArchipelagoID']['input']>;
};

export type ApplyDisposePropertiesJobInput = {
  orgName: Scalars['String']['input'];
  properties: Array<DisposePropertyInput>;
};

export type ApplyEditPropertyJobInput = {
  /**  Attributes to lock for editing. If none are specified, all attributes are locked.  */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Indicates whether lat/lng values were provided by the customer */
  customerProvidedGeocode: Scalars['Boolean']['input'];
  /**  Document IDs to be linked to the property.  */
  linkedDocumentIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  locationID?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  propertyArchipelagoID: Scalars['ArchipelagoID']['input'];
  /**  New values for partial property update.  */
  propertyData: PropertyDataInput;
  /**
   * Identifies the requester when the request is submitted by a system user on behalf of an actual user.
   * Only allowed to be set by admins.
   */
  requester?: InputMaybe<Requester>;
  streamSlug: Scalars['String']['input'];
};

export type ApplyJobInput = {
  jobID: Scalars['ID']['input'];
};

export type ApplyMappingRulesToPropertiesInput = {
  /**  Boolean to set whether to update changes to the properties or just return the changes that would be made  */
  applyChanges?: InputMaybe<Scalars['Boolean']['input']>;
  /**  The properties to apply the mapping rules to  */
  archipelagoIDs: Array<Scalars['String']['input']>;
  overrideValues?: InputMaybe<Array<ValidateFieldInput>>;
};

export type ApplyMappingRulesToPropertiesResult = {
  __typename?: 'ApplyMappingRulesToPropertiesResult';
  /**  The properties that the mapping rules were applied to  */
  changes?: Maybe<Array<PropertyChanges>>;
};

export type ApplyRestoreDeletedPropertiesJobInput = {
  orgName: Scalars['String']['input'];
  propertyArchipelagoIDs: Array<Scalars['ArchipelagoID']['input']>;
};

export type ApplyRestoreDisposedPropertiesJobInput = {
  orgName: Scalars['String']['input'];
  propertyArchipelagoIDs: Array<Scalars['ArchipelagoID']['input']>;
};

export type ApplySovJobInput = {
  jobID: Scalars['ID']['input'];
};

export type ApproveJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['ID']['input'];
};

export type ArchiveProjectInput = {
  name: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type AssignRoleAllScopeValuesInput = {
  principalId: Scalars['String']['input'];
  role: Role;
  scopeType: ScopeType;
};

export type AssignRoleInputV2 = {
  filter?: InputMaybe<RoleFilterInput>;
  principalId: Scalars['String']['input'];
  principalType?: InputMaybe<PrincipalType>;
  role: Role;
  scope: ScopeInput;
};

export type AssignRoleOutput = {
  __typename?: 'AssignRoleOutput';
  /** True if an existing assignment was updated */
  found: Scalars['Boolean']['output'];
  roleAssignment: RoleAssignmentV2;
};

export type Attribute = {
  __typename?: 'Attribute';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AttributeComments = {
  __typename?: 'AttributeComments';
  attributeName: Scalars['String']['output'];
  comments: Array<Comment>;
  status: CommentStatus;
};

export type AttributeCommentsInput = {
  attributeName: Scalars['String']['input'];
  /**  new comments for specified attribute by current user.  */
  comments?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  when resolve is true, comments field must be empty  */
  resolve?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AttributeEditInfo = {
  __typename?: 'AttributeEditInfo';
  attributeName: Scalars['String']['output'];
  author: Scalars['String']['output'];
  date: Scalars['Time']['output'];
};

export type AttributeFilter = {
  name: Scalars['String']['input'];
  operator?: InputMaybe<BinaryOperator>;
  /** A candidate object with any of these values will match this filter */
  values: Array<Scalars['String']['input']>;
};

export type AttributeFilterOutput = {
  __typename?: 'AttributeFilterOutput';
  name: Scalars['String']['output'];
  operator?: Maybe<BinaryOperator>;
  /** A candidate object with any of these values will match this filter */
  values: Array<Scalars['String']['output']>;
};

export type AttributeLockConflict = {
  __typename?: 'AttributeLockConflict';
  /**  True if the lock is on all property attributes.  */
  allAttributesLocked: Scalars['Boolean']['output'];
  /**  Names of attributes which conflict with the requested attributes. Empty if allAttributesLocked is true.  */
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  groupID: Scalars['String']['output'];
  propertyID: Scalars['ArchipelagoID']['output'];
};

export type AttributeLocks = {
  __typename?: 'AttributeLocks';
  /**  True if the property is locked for all attributes.  */
  allAttributesLocked: Scalars['Boolean']['output'];
  /**  Names of attributes which are locked, if all attributes are not locked.  */
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  /**  Archipelago ID of the property for which these locks apply.  */
  propertyArchipelagoID: Scalars['String']['output'];
};

export type AttributeMetadata = {
  __typename?: 'AttributeMetadata';
  columnPosition: Scalars['Int']['output'];
  dataType: Scalars['String']['output'];
  /** Minimal number of trailing decimals to display in the UI (optional) */
  decimals?: Maybe<Scalars['Int']['output']>;
  derived: Scalars['Boolean']['output'];
  dimension?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  /** Column width multiplied by 16 in the grid UI */
  displayWidth: Scalars['Int']['output'];
  /** List of values for this attribute. */
  enumMetadata?: Maybe<Array<EnumValueMetadata>>;
  filterable: Scalars['Boolean']['output'];
  groupable: Scalars['Boolean']['output'];
  hiddenInGrid: Scalars['Boolean']['output'];
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  /** Max threshold to display in the UI if the value crosses it */
  maxThreshold?: Maybe<Scalars['Int']['output']>;
  /** Unique name of this attribute used to reference in other APIs  e.g. 'locationId' */
  name: Scalars['ID']['output'];
  /** Name of parent attribute. Value is null for top level attributes. */
  parent?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  secondaryModifier?: Maybe<SecondaryModifier>;
  source?: Maybe<Scalars['String']['output']>;
  subdimension?: Maybe<Scalars['String']['output']>;
};

export type AttributeProvenanceInput = {
  attributeName: Scalars['String']['input'];
  /**  document used as provenance for attribute value  */
  documentID: Scalars['ID']['input'];
};

export type AttributeRecommendation = {
  __typename?: 'AttributeRecommendation';
  frequency?: Maybe<Scalars['Int']['output']>;
  pageSections?: Maybe<Array<PageSection>>;
  value?: Maybe<Scalars['String']['output']>;
};

export type AttributeRecommendationsInput = {
  archipelagoID?: InputMaybe<Scalars['String']['input']>;
  attributeName: Scalars['String']['input'];
  jobID: Scalars['ID']['input'];
};

export type AttributeRecommendationsOutput = {
  __typename?: 'AttributeRecommendationsOutput';
  attributeName: Scalars['String']['output'];
  documents?: Maybe<Array<DocumentRecommendations>>;
};

export type Authorization = {
  __typename?: 'Authorization';
  authorized: Scalars['Boolean']['output'];
  /** If set, the authorization is subject to this filter. */
  filter?: Maybe<RoleFilter>;
};

export type AuthorizationConfig = {
  __typename?: 'AuthorizationConfig';
  orgId: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  /**
   * ConfigureAuthorizationInput.ssoConfigName is the name of the SAML connection in Auth0 that is
   * used to configure SSO for customers. Configuring SSO in Auth0 allows customers to use their own
   * identity provider (IdP) to log in to the platform. By configuring
   * ConfigureAuthorizationInput.ssoConfigName in the authorization service the platform automatically
   * grants the user roles to the customer's organization which gives access to organization's stream
   * data. Granting roles, or automatically role provisioning, is based on the metadata that the IdP
   * provides. If no role metadata is provided by the IdP then the user won't get access. Currently
   * only RiskManager is supported.
   *
   * Setting this value won't configure SSO in Auth0.
   */
  ssoConfigName?: Maybe<Scalars['String']['output']>;
};

export enum BinaryOperator {
  BeginsWith = 'BEGINS_WITH',
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  Equal = 'EQUAL',
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  In = 'IN',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  Less = 'LESS',
  LessOrEqual = 'LESS_OR_EQUAL',
  NotBeginsWith = 'NOT_BEGINS_WITH',
  NotBetween = 'NOT_BETWEEN',
  NotContains = 'NOT_CONTAINS',
  NotEndsWith = 'NOT_ENDS_WITH',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN',
}

export type CheckSovJobWorksheetInput = {
  /**
   * If set to true, the job will process the updates automatically in this step. A separate apply mutation is not needed.
   *    Status is available from Job object. Any data with errors will not be applied.
   */
  autoApply?: InputMaybe<Scalars['Boolean']['input']>;
  jobID: Scalars['String']['input'];
  /**  Upload updated SOV obtained from  getSOVJobWorksheet  */
  worksheet: Scalars['Upload']['input'];
};

export type CheckoutJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['ID']['input'];
};

export type ClientIDsExistInput = {
  /**  ClientIDs to check for existence within the organization.  */
  clientIDs: Array<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
};

export type ClientIDsExistResponse = {
  __typename?: 'ClientIDsExistResponse';
  /**  Subset of requested client IDs which exist within an organization.  */
  existingClientIDs: Array<Scalars['String']['output']>;
};

export type Comment = {
  __typename?: 'Comment';
  createdAt: Scalars['Time']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  userID: Scalars['String']['output'];
};

export enum CommentStatus {
  Open = 'Open',
  Resolved = 'Resolved',
}

export type CompleteJobTransitionInput = {
  comment: Scalars['String']['input'];
  jobID: Scalars['ID']['input'];
  transitionID: Scalars['ID']['input'];
};

export type ConfigureAuthorizationInput = {
  orgName: Scalars['String']['input'];
  /**
   * ConfigureAuthorizationInput.ssoConfigName is the name of the SAML connection in Auth0 that is
   * used to configure SSO for customers. Configuring SSO in Auth0 allows customers to use their own
   * identity provider (IdP) to log in to the platform. By configuring
   * ConfigureAuthorizationInput.ssoConfigName in the authorization service the platform automatically
   * grants the user roles to the customer's organization which gives access to organization's stream
   * data. Granting roles, or automatically role provisioning, is based on the metadata that the IdP
   * provides. If no role metadata is provided by the IdP then the user won't get access. Currently
   * only RiskManager is supported.
   *
   * Setting this value won't configure SSO in Auth0.
   */
  ssoConfigName?: InputMaybe<Scalars['String']['input']>;
};

export type ConsumerCustomAttributes = {
  __typename?: 'ConsumerCustomAttributes';
  consumerDecimal1?: Maybe<Scalars['Float']['output']>;
  consumerDecimal2?: Maybe<Scalars['Float']['output']>;
  consumerDecimal3?: Maybe<Scalars['Float']['output']>;
  consumerDecimal4?: Maybe<Scalars['Float']['output']>;
  consumerDecimal5?: Maybe<Scalars['Float']['output']>;
  consumerDecimal6?: Maybe<Scalars['Float']['output']>;
  consumerDecimal7?: Maybe<Scalars['Float']['output']>;
  consumerDecimal8?: Maybe<Scalars['Float']['output']>;
  consumerDecimal9?: Maybe<Scalars['Float']['output']>;
  consumerDecimal10?: Maybe<Scalars['Float']['output']>;
  consumerDecimal11?: Maybe<Scalars['Float']['output']>;
  consumerDecimal12?: Maybe<Scalars['Float']['output']>;
  consumerDecimal13?: Maybe<Scalars['Float']['output']>;
  consumerDecimal14?: Maybe<Scalars['Float']['output']>;
  consumerDecimal15?: Maybe<Scalars['Float']['output']>;
  consumerText1?: Maybe<Scalars['String']['output']>;
  consumerText2?: Maybe<Scalars['String']['output']>;
  consumerText3?: Maybe<Scalars['String']['output']>;
  consumerText4?: Maybe<Scalars['String']['output']>;
  consumerText5?: Maybe<Scalars['String']['output']>;
  consumerText6?: Maybe<Scalars['String']['output']>;
  consumerText7?: Maybe<Scalars['String']['output']>;
  consumerText8?: Maybe<Scalars['String']['output']>;
  consumerText9?: Maybe<Scalars['String']['output']>;
  consumerText10?: Maybe<Scalars['String']['output']>;
  consumerText11?: Maybe<Scalars['String']['output']>;
  consumerText12?: Maybe<Scalars['String']['output']>;
  consumerText13?: Maybe<Scalars['String']['output']>;
  consumerText14?: Maybe<Scalars['String']['output']>;
  consumerText15?: Maybe<Scalars['String']['output']>;
};

export type CopyProvenanceInput = {
  jobID: Scalars['ID']['input'];
  /**  attributes to be copied  */
  selectedAttributes: Array<Scalars['String']['input']>;
  sourceAID: Scalars['ArchipelagoID']['input'];
  /**  properties to be copied to  */
  targetPropertyAIDs: Array<Scalars['ArchipelagoID']['input']>;
};

export type CreateJobInput = {
  /**  Attributes to be enriched as part of this job. A non-empty list is required for 'Enrichment' Jobs. */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  doerUserID?: InputMaybe<Scalars['String']['input']>;
  dueOn?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Optional expire in hours. If specified, this job expires in this number of hours after create.
   * All locks are released and status is updated to Expired.
   */
  expireInHours?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  /**  If true this job does auto submits and approves to run to completion.  If there are errors, the job stops at that step.  */
  nonInteractive?: InputMaybe<Scalars['Boolean']['input']>;
  orgName: Scalars['String']['input'];
  priority?: InputMaybe<JobPriority>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Optional list of properties subject of this job. If not provided all properties in the
   *   org are implied.
   */
  propertyIDs?: InputMaybe<Array<Scalars['ArchipelagoID']['input']>>;
  /**
   * List of reference document ids.
   * Optional except for provenance enrichment jobs, which must contain exactly one reference.
   */
  references?: InputMaybe<Array<Scalars['String']['input']>>;
  requesterUserID?: InputMaybe<Scalars['String']['input']>;
  reviewerUserID?: InputMaybe<Scalars['String']['input']>;
  /**  Applicable to col mapping and pre cleanse jobs. system doc id for a previously uploaded SOV  */
  sovDocId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Optional stream slug for ingest jobs. If specified, workseet will use the stream's attribute metadata configuration
   * If not it will use the organization's configuration by default
   */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<JobType>;
  /**
   * Only applies to Enrichment jobs. If set to SingleColumn, api will generate
   * the worksheet without .New and .Suggested columns
   */
  worksheetFormat?: InputMaybe<WorksheetFormat>;
};

export type CreateOnboardingProjectsInput = {
  orgID: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type CreateProjectInput = {
  /**  UTC date on which this project is due. Value format as YYYY-MM-DD  */
  dueOn?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Effective date, in UTC, for all property changes in this project. Value format as YYYY-MM-DD
   *       If not specified, the change timeframe is assumed to be current.
   */
  effectiveFrom?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type CreatePropertyInput = {
  locationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  property: PropertyDataInput;
};

export type CreateSnapshotInput = {
  /**
   * Optional snapshot date. Format must be YYYY-MM-DD.
   * If no date or project is specified, snapshot date will be the current timestamp.
   * If both optional fields are specified, the date field will be used for snapshot date.
   */
  date?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   * Snapshot name. If not specified, will default to snapshot date
   * formatted like YYYY-MM-DD
   */
  name?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  /**
   * Optional project name. If specified, snapshot will be created at the project's
   * effective date. Current timestamp will be used otherwise
   */
  projectName?: InputMaybe<Scalars['String']['input']>;
  /** Type of snapshot */
  type?: InputMaybe<SnapshotType>;
  updateReportingViews?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomAttributeInput = {
  ownerDecimal1?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal2?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal3?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal4?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal5?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal6?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal7?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal8?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal9?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal10?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal11?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal12?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal13?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal14?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal15?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal101?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal102?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal103?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal104?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal105?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal106?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal107?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal108?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal109?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal110?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal111?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal112?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal113?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal114?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal115?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal116?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal117?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal118?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal119?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal120?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal121?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal122?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal123?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal124?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal125?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal126?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal127?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal128?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal129?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal130?: InputMaybe<Scalars['Float']['input']>;
  ownerText1?: InputMaybe<Scalars['String']['input']>;
  ownerText2?: InputMaybe<Scalars['String']['input']>;
  ownerText3?: InputMaybe<Scalars['String']['input']>;
  ownerText4?: InputMaybe<Scalars['String']['input']>;
  ownerText5?: InputMaybe<Scalars['String']['input']>;
  ownerText6?: InputMaybe<Scalars['String']['input']>;
  ownerText7?: InputMaybe<Scalars['String']['input']>;
  ownerText8?: InputMaybe<Scalars['String']['input']>;
  ownerText9?: InputMaybe<Scalars['String']['input']>;
  ownerText10?: InputMaybe<Scalars['String']['input']>;
  ownerText11?: InputMaybe<Scalars['String']['input']>;
  ownerText12?: InputMaybe<Scalars['String']['input']>;
  ownerText13?: InputMaybe<Scalars['String']['input']>;
  ownerText14?: InputMaybe<Scalars['String']['input']>;
  ownerText15?: InputMaybe<Scalars['String']['input']>;
  ownerText101?: InputMaybe<Scalars['String']['input']>;
  ownerText102?: InputMaybe<Scalars['String']['input']>;
  ownerText103?: InputMaybe<Scalars['String']['input']>;
  ownerText104?: InputMaybe<Scalars['String']['input']>;
  ownerText105?: InputMaybe<Scalars['String']['input']>;
  ownerText106?: InputMaybe<Scalars['String']['input']>;
  ownerText107?: InputMaybe<Scalars['String']['input']>;
  ownerText108?: InputMaybe<Scalars['String']['input']>;
  ownerText109?: InputMaybe<Scalars['String']['input']>;
  ownerText110?: InputMaybe<Scalars['String']['input']>;
  ownerText111?: InputMaybe<Scalars['String']['input']>;
  ownerText112?: InputMaybe<Scalars['String']['input']>;
  ownerText113?: InputMaybe<Scalars['String']['input']>;
  ownerText114?: InputMaybe<Scalars['String']['input']>;
  ownerText115?: InputMaybe<Scalars['String']['input']>;
  ownerText116?: InputMaybe<Scalars['String']['input']>;
  ownerText117?: InputMaybe<Scalars['String']['input']>;
  ownerText118?: InputMaybe<Scalars['String']['input']>;
  ownerText119?: InputMaybe<Scalars['String']['input']>;
  ownerText120?: InputMaybe<Scalars['String']['input']>;
  ownerText121?: InputMaybe<Scalars['String']['input']>;
  ownerText122?: InputMaybe<Scalars['String']['input']>;
  ownerText123?: InputMaybe<Scalars['String']['input']>;
  ownerText124?: InputMaybe<Scalars['String']['input']>;
  ownerText125?: InputMaybe<Scalars['String']['input']>;
  ownerText126?: InputMaybe<Scalars['String']['input']>;
  ownerText127?: InputMaybe<Scalars['String']['input']>;
  ownerText128?: InputMaybe<Scalars['String']['input']>;
  ownerText129?: InputMaybe<Scalars['String']['input']>;
  ownerText130?: InputMaybe<Scalars['String']['input']>;
};

export type DataError = {
  __typename?: 'DataError';
  attributeName?: Maybe<Scalars['String']['output']>;
  /**  For worksheet based input  */
  columnHeader?: Maybe<Scalars['String']['output']>;
  /**  For worksheet based input  */
  location?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  /**  For worksheet based input  */
  sheetName?: Maybe<Scalars['String']['output']>;
  /**  Type of data error  */
  type?: Maybe<DataErrorType>;
  value: Scalars['String']['output'];
};

export enum DataErrorType {
  /**  Worksheet xlsx file is corrupt  */
  CorruptFile = 'CorruptFile',
  /**  Worksheet contains client IDs which already exist in the portfolio  */
  DuplicateClientId = 'DuplicateClientID',
  /**  Worksheet contains the same header more than once  */
  DuplicateColumnHeader = 'DuplicateColumnHeader',
  /**  Internal server error  */
  InternalServerError = 'InternalServerError',
  /**  Worksheet contains invalid data cells, for example a date or number that's not properly formatted */
  InvalidDataFormat = 'InvalidDataFormat',
  /**  Worksheet contains one or several invalid archipelago property ids that are not part of the current org and stream  */
  InvalidPropertyIDs = 'InvalidPropertyIDs',
  /**  Worksheet contains invalid column headers that are not part of the current org and stream  */
  UnknownColumnHeader = 'UnknownColumnHeader',
}

export type DeleteOrgProjectsInput = {
  orgName: Scalars['String']['input'];
};

export type DeletePropertyRevInput = {
  /** Job ID to be reverted. Delete the property revisions created by this job. */
  jobID: Scalars['ID']['input'];
};

export type DeletedPropertiesInput = {
  /** Only one of sortBy or cursor can be specified in this query */
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgID: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

/** This is used by manage stream properties dispose job */
export type DisposePropertiesInput = {
  /**  list of property IDs selected in dispose properties job  */
  archipelagoIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  manage stream properties dispose job id  */
  jobID: Scalars['String']['input'];
  organizationID: Scalars['String']['input'];
  /**  user id of dispose properties job  */
  userID: Scalars['String']['input'];
};

export type DisposePropertiesResponse = {
  __typename?: 'DisposePropertiesResponse';
  /**  number of properties disposed  */
  count: Scalars['Int']['output'];
};

export type DisposePropertyInput = {
  /**  Date the property was disposed, formatted 'YYYY-MM-DD' ', example: '2006-01-18'  */
  disposalDate?: InputMaybe<Scalars['String']['input']>;
  propertyID: Scalars['ArchipelagoID']['input'];
};

export type Document = {
  __typename?: 'Document';
  /**  Type of the file. Like Roof Report or PCA Report  */
  classification?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  /**  UserProfile of the user that created/uploaded the file  */
  createdBy: UserProfile;
  extension?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  httpURL: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationID: Scalars['ID']['output'];
  origin?: Maybe<Scalars['String']['output']>;
  /**  Preview URL of a document which is converted to pdf to preview. Only Set in getDocument  */
  previewURL?: Maybe<Scalars['String']['output']>;
  /**  List of associated Properties  */
  properties?: Maybe<Array<Property>>;
  /** All document links referenced as provenance from property attributes. */
  referencedSections?: Maybe<Array<SectionReference>>;
  sizeInBytes: Scalars['Int']['output'];
  /**  List of source documents. A source document are the original documents to which this file is related.  */
  sourceDocuments?: Maybe<Array<Document>>;
  /** List of any attributes which are linked to this document for provenance sourcing. */
  sourcedAttributes?: Maybe<Array<AttributeMetadata>>;
  /**  List of streams to which this files belongs. Also contains a String to indicate the file's group  */
  streamGroups?: Maybe<Array<StreamGroup>>;
  /**  Keywords associated with a particular document  */
  tags?: Maybe<Array<Scalars['String']['output']>>;
  thumbnailURLs?: Maybe<ImageUrLs>;
};

export type DocumentLink = {
  __typename?: 'DocumentLink';
  /**  The document that contains the evidence  */
  document: Document;
  /**
   * This stores a string used to describe the specific section of the document which was used
   *   to source the provenance.
   *   For example, this is used by iPad app to indicate that the provenance contains an in-line reference into a document
   *   iPad format: {"x": float, "y": float, "width": float, "height": float, "length": int, "content": string
   *   "pageNumber": int, "startIndex": int, "extractorVersion": int}
   */
  documentSection?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type DocumentPropertyInput = {
  /**  The properties to be added or removed from the document by using archipelagoIds  */
  archipelagoIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The document ID for the document to be updated */
  documentID: Scalars['ID']['input'];
  /**
   * TO be deprecated: The property to be added or removed from the document
   *     for now if using the new way add anything to propertyID until this is removed. This is due backwards compatibility.
   */
  propertyID: Scalars['ID']['input'];
};

export type DocumentRecommendations = {
  __typename?: 'DocumentRecommendations';
  document: Document;
  recommendations?: Maybe<Array<AttributeRecommendation>>;
};

export type DocumentsByClassificationInput = {
  /** Maximum number of items to return. Default value is 10. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset within the dataset from which to return results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgName: Scalars['String']['input'];
  originClassifications: Array<OriginClassification>;
};

export type DocumentsByClassificationResponse = {
  __typename?: 'DocumentsByClassificationResponse';
  documents: Array<Document>;
  /**  Total number of rows in the dataset.  */
  totalCount: Scalars['Int']['output'];
};

export type DocumentsPage = {
  __typename?: 'DocumentsPage';
  documents: Array<Document>;
  /**  information about this documents page  */
  pageInfo: PageInfo;
};

export type Domain = {
  __typename?: 'Domain';
  authorized: Scalars['Boolean']['output'];
  domain: Scalars['String']['output'];
};

export type DownloadJobPropertyValidationsWorksheetInput = {
  jobID: Scalars['ID']['input'];
};

export type DownloadJobPropertyValidationsWorksheetOutput = {
  __typename?: 'DownloadJobPropertyValidationsWorksheetOutput';
  job: Job;
  worksheet: Worksheet;
};

export type DownloadJobWorksheetInput = {
  jobID: Scalars['ID']['input'];
};

export type DownloadJobWorksheetOutput = {
  __typename?: 'DownloadJobWorksheetOutput';
  job: Job;
  worksheet: Worksheet;
};

export type EnumValueInput = {
  attributeName: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type EnumValueMetadata = {
  __typename?: 'EnumValueMetadata';
  /**  Cluster value of attributes constructionType and occupancyType like [Steel,...]  */
  clusterValue?: Maybe<Scalars['String']['output']>;
  externalMappings?: Maybe<Array<ExternalMapping>>;
  /**  Hex color code of the value used for explorers microchart and donut colors  */
  hexColorCode?: Maybe<Scalars['String']['output']>;
  /**  Order of the attribute value like [1,2,...]  */
  position: Scalars['Int']['output'];
  softMappings?: Maybe<Array<Maybe<SoftMapping>>>;
  /**  System/platform defined attribute value like [Wood frame,Retail trade,...]  */
  value: Scalars['String']['output'];
  /**  The value used for display instead of 'value'  */
  valueDisplay?: Maybe<Scalars['String']['output']>;
  /**  System/platform defined id of attribute value like [0,1,FEMA-A,W1,O1,...]  */
  valueID: Scalars['String']['output'];
};

/**
 *  THIS FILE IS COPIED FROM API REPO
 * Archipelago extensions to GraphQL error. This type defines the names of keys returned in GraphQL response
 * map field `errors.extensions` as defined in http://spec.graphql.org/October2021/#sec-Errors
 */
export enum ErrorExtension {
  /**  Denotes type of error. Value of this map key is a string -- see #ErrorType for possible values  */
  Code = 'Code',
  /**  Value of this key describes the error type  */
  Details = 'Details',
  /**
   * Value of this key contains an opaque id of the error instance for reference. Include this value when
   *   reporting any errors.
   */
  Id = 'ID',
}

/**  Possible values for ErrorExtension 'Code'  */
export enum ErrorType {
  /**
   * There was a unexpected error on the server while processing this request. If the request fails, repeatedly
   *   this should be reported including the `ID` field in the error.extensions field.
   */
  InternalServerError = 'InternalServerError',
  /**  Input provided for request is invalid. Caller should review Details field and modify the request.  */
  InvalidUserInput = 'InvalidUserInput',
  /**  Caller does not have permissions for requested resources. */
  UnAuthorized = 'UnAuthorized',
}

export enum ExportCode {
  Air = 'AIR',
  Archipelago = 'ARCHIPELAGO',
  ChangeAnalysis = 'CHANGE_ANALYSIS',
  Diagnostic = 'DIAGNOSTIC',
  DocumentsArchive = 'DOCUMENTS_ARCHIVE',
  Dqd = 'DQD',
  Enrichment = 'ENRICHMENT',
  Losses = 'LOSSES',
  Rapid = 'RAPID',
  Rms = 'RMS',
  Zorba = 'ZORBA',
}

export enum ExternalCoding {
  Air = 'AIR',
  Atc = 'ATC',
  Default = 'DEFAULT',
  Iso = 'ISO',
  Naics = 'NAICS',
  Rms = 'RMS',
  RmsEq = 'RMS_EQ',
  Sic = 'SIC',
  Unknown = 'UNKNOWN',
}

export type ExternalMapping = {
  __typename?: 'ExternalMapping';
  /**  External coding value like [RMS,ATC,AIR,...]  */
  externalCoding: ExternalCoding;
  value: Scalars['String']['output'];
  /**  External value id of attribute like [RMS-3A1,ATC-6,ISO-2,...]  */
  valueID: Scalars['String']['output'];
};

export type Field = {
  __typename?: 'Field';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum FileType {
  Document = 'document',
  Image = 'image',
}

export type GenerateSovWorkbookInput = {
  columnMappingS3Path: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  sovS3Path: Scalars['String']['input'];
};

export type GeoJobData = {
  __typename?: 'GeoJobData';
  properties?: Maybe<Array<GeoJobProperty>>;
};

export type GeoJobDataInput = {
  jobID: Scalars['ID']['input'];
  properties?: InputMaybe<Array<GeoJobPropertyInput>>;
};

export type GeoJobProperty = {
  __typename?: 'GeoJobProperty';
  /**
   * Indicates that address from Geocoding service did not match provided address.
   * This property should be manually reviewed and assigned address fields including latitude, longitude and geo code level.
   * If not assigned, these will not be updated by the system and hazards for this property will not be calculated.
   */
  needsAddressReview: Scalars['Boolean']['output'];
  new?: Maybe<GeoJobPropertyData>;
  original?: Maybe<GeoJobPropertyData>;
  priority?: Maybe<Scalars['Int']['output']>;
  suggested?: Maybe<GeoJobPropertyData>;
};

export type GeoJobPropertyData = {
  __typename?: 'GeoJobPropertyData';
  archipelagoFloodZone?: Maybe<Scalars['String']['output']>;
  archipelagoId: Scalars['String']['output'];
  archipelagoSeismicZone?: Maybe<Scalars['String']['output']>;
  archipelagoWindZone?: Maybe<Scalars['String']['output']>;
  baseFloodElevation?: Maybe<Scalars['Float']['output']>;
  buildingFootprintClass?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  distanceToNearestFault?: Maybe<Scalars['Float']['output']>;
  floodZone?: Maybe<Scalars['String']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  geoCodeType?: Maybe<Scalars['String']['output']>;
  hailHazardClass?: Maybe<Scalars['String']['output']>;
  landslideSusceptibility?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  lightningHazardClass?: Maybe<Scalars['String']['output']>;
  liquefactionSusceptibility?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  milesToCoast?: Maybe<Scalars['Float']['output']>;
  originalStreet?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  seismicHazardClass?: Maybe<Scalars['String']['output']>;
  seismicZone?: Maybe<Scalars['String']['output']>;
  siteSoilClassification?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  tornadoHazardClass?: Maybe<Scalars['String']['output']>;
  tsunamiHazardClass?: Maybe<Scalars['String']['output']>;
  wildfireHazardClass?: Maybe<Scalars['String']['output']>;
  windHazardClass?: Maybe<Scalars['String']['output']>;
  windZone?: Maybe<Scalars['String']['output']>;
};

export type GeoJobPropertyInput = {
  archipelagoId: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  geoCodeType?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};

export type GetSovJobWorksheetInput = {
  jobID: Scalars['ID']['input'];
};

export enum HazardsStatus {
  Completed = 'Completed',
  Created = 'Created',
  Failed = 'Failed',
  Started = 'Started',
}

export type ImageUrLs = {
  __typename?: 'ImageURLs';
  /**  300x300  */
  medium: Scalars['String']['output'];
  /**  original document dimensions  */
  original: Scalars['String']['output'];
  /**  40x40  */
  small: Scalars['String']['output'];
};

export type InitAddPropertiesJobInput = {
  orgName: Scalars['String']['input'];
  streamSlug: Scalars['String']['input'];
};

export type InitEditPropertiesJobInput = {
  /**
   * Names of attributes which are included in the first columns of the worksheet to identify properties.
   * ArchipelagoID is included regardless of whether it is specified.
   */
  identifierAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of attributes to lock for editing. All attributes are locked if not provided. */
  lockedAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
  /** Locks attributes for properties which match the filter for editing by this job. */
  propertyFilter?: InputMaybe<Array<AttributeFilter>>;
  streamSlug: Scalars['String']['input'];
  /**
   * List of attributes to include in the worksheet.
   * All enabled, non-derived attributes are included if this list is not provided.
   * ArchipelagoID is included regardless of whether it is specified.
   */
  worksheetAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InitSovJobInput = {
  /** If specified, this job will update values for only these attributes from the provided SOV */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * If set to true, the job will process the updates automatically based on values extracted by the system.
   *   No further calls are needed. Status is available from Job object.
   */
  autoApply?: InputMaybe<Scalars['Boolean']['input']>;
  /** complexity of the SOV */
  complexity?: InputMaybe<Scalars['String']['input']>;
  /**  documentID  */
  documentID?: InputMaybe<Scalars['String']['input']>;
  doerUserID?: InputMaybe<Scalars['String']['input']>;
  /** name of the job */
  name: Scalars['String']['input'];
  orgID: Scalars['String']['input'];
  reviewerUserID?: InputMaybe<Scalars['String']['input']>;
  /**  skeleton S3 path of the document  */
  skeletonS3Path?: InputMaybe<Scalars['String']['input']>;
  /**  Upload pre-cleansed SOV with property edits.  */
  worksheet?: InputMaybe<Scalars['Upload']['input']>;
};

export type Job = {
  __typename?: 'Job';
  /**
   * Attributes to be enriched as part of this job. Updates to any other attributes are
   *   not be accepted by the system on submission.
   */
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**  Deprecated. Used by type VirtualInspection to return any associated email templates.  */
  details?: Maybe<Scalars['String']['output']>;
  doerProfile?: Maybe<UserProfile>;
  dueOn?: Maybe<Scalars['Date']['output']>;
  /**
   * Time at which this job expires and releases any locks on underlying properties and/or attributes.
   *   Only get and find operation is permitted after this time. Get and find operation will include expired jobs.
   */
  expiryTime?: Maybe<Scalars['Time']['output']>;
  /**  System generated identifier for this job. */
  id: Scalars['ID']['output'];
  /**  List of state transitions, ordered by most recent first. */
  lifeCycle?: Maybe<Array<StateTransition>>;
  name: Scalars['String']['output'];
  /**  Identifier for non-interactive mode for this job. */
  nonInteractive?: Maybe<Scalars['Boolean']['output']>;
  orgName: Scalars['String']['output'];
  priority?: Maybe<JobPriority>;
  projectName: Scalars['String']['output'];
  /**
   * Optional list of properties subject of this job. If not provided all properties
   *   in the org are implied, and an error will be returned if the org contains more than
   *   10k properties.
   */
  propertyIDs?: Maybe<Array<Scalars['ArchipelagoID']['output']>>;
  /**  Identifier for the group of property attribute locks acquired for this job  */
  propertyLockGroupID?: Maybe<Scalars['String']['output']>;
  /**  Optional list of system documents for reference from this job  */
  references?: Maybe<Array<Document>>;
  requesterProfile?: Maybe<UserProfile>;
  reviewerProfile?: Maybe<UserProfile>;
  /**
   * Only applicable to col mapping and ingest jobs. system doc id for the customer SOV
   *   which is the data source for this job.
   */
  sovDocId?: Maybe<Scalars['String']['output']>;
  status: JobStatus;
  /**  Stream associated with job.  */
  streamSlug?: Maybe<Scalars['String']['output']>;
  type: JobType;
  /**
   * version of job type. It is used for Provenance enrichment job now(3/15/2022).
   *       Default value is 1. Only `jobsV2` and `job` query return it.
   *
   *       value  |  meaning
   *       2         PE2
   */
  version?: Maybe<Scalars['Int']['output']>;
  /**
   * Only applies to Enrichment jobs. If set to SingleColumn, api will generate
   *   the worksheet without .New and .Suggested columns
   */
  worksheetFormat: WorksheetFormat;
};

export type JobAddressSuggestionsPageInput = {
  jobID: Scalars['ID']['input'];
  /** Maximum number of items to return. Default limit value is 200. A maximum limit value of 2000 is supported. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /**  Offset within the dataset from which to return results.  */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type JobAddressSuggestionsPageOutput = {
  __typename?: 'JobAddressSuggestionsPageOutput';
  suggestions?: Maybe<Array<PropertyAddressSuggestion>>;
};

export enum JobPriority {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
}

export type JobPropertyDataInput = {
  /**  Unique identifier for the property within job.  */
  jobPropertyID: Scalars['String']['input'];
  /**  Updated data to submit for the property. Sets all attribute values to those provided, including null values.  */
  propertyData: PropertyDataInput;
};

export type JobPropertyDataValidationsPageInput = {
  jobID: Scalars['ID']['input'];
  /** Maximum number of items to return. Default limit value is 200. A maximum limit value of 2000 is supported. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /**  Offset within the dataset from which to return results.  */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /**
   * If true, will only return properties with validation errors/warnings. Otherwise all properties included in the job
   * will be returned.
   */
  withValidationErrorsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobPropertyDataValidationsPageOutput = {
  __typename?: 'JobPropertyDataValidationsPageOutput';
  properties: Array<PropertyDataValidations>;
};

export enum JobStatus {
  Archived = 'Archived',
  ChangesRequested = 'ChangesRequested',
  Complete = 'Complete',
  /**  All locks are released and only get, find operations are allowed.  */
  Expired = 'Expired',
  /**  Indicates unexpected failures which require manual review.  */
  Failed = 'Failed',
  /**  Hazards have been updated for all properties */
  HazardsUpdated = 'HazardsUpdated',
  InProgress = 'InProgress',
  /**  Mapping rules have been applied to the job's properties */
  MappingRulesApplied = 'MappingRulesApplied',
  /**  Mapping rules failed to apply to the job's properties */
  MappingRulesApplyFailed = 'MappingRulesApplyFailed',
  /**  Initial state. A Job cannot be set to this state.  */
  None = 'None',
  /**  The job failed failed property data validation.  */
  PropertyDataValidateFailed = 'PropertyDataValidateFailed',
  /**  The job's added or edited property data has been validated.  */
  PropertyDataValidated = 'PropertyDataValidated',
  PropertyUpdatesCompleted = 'PropertyUpdatesCompleted',
  Ready = 'Ready',
  ReadyForReview = 'ReadyForReview',
  /**  Indicates the revisions created for the job are removed. The most recent job can be reverted.  */
  Reverted = 'Reverted',
  /**  Stream memberships have been updated for the job's organization  */
  StreamsUpdated = 'StreamsUpdated',
  ValidateFailed = 'ValidateFailed',
  /**  The job failed worksheet validation.  */
  WorksheetValidateFailed = 'WorksheetValidateFailed',
  /**  The job's worksheet has been validated.  */
  WorksheetValidated = 'WorksheetValidated',
}

export enum JobType {
  AddLosses = 'AddLosses',
  AddProperties = 'AddProperties',
  AddProperty = 'AddProperty',
  ColumnMapping = 'ColumnMapping',
  DeleteProperties = 'DeleteProperties',
  DisposeProperties = 'DisposeProperties',
  EditLosses = 'EditLosses',
  EditProperties = 'EditProperties',
  EditProperty = 'EditProperty',
  Enrichment = 'Enrichment',
  EnrichmentWithProvenance = 'EnrichmentWithProvenance',
  Geo = 'Geo',
  Ingest = 'Ingest',
  LocationIngest = 'LocationIngest',
  NonLocationIngest = 'NonLocationIngest',
  PalIngest = 'PALIngest',
  PreCleanse = 'PreCleanse',
  RestoreDeletedProperties = 'RestoreDeletedProperties',
  RestoreDisposedProperties = 'RestoreDisposedProperties',
  Sov = 'SOV',
  Stp = 'STP',
}

export type JobWorksheetProgressInput = {
  orgName: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
};

export type JobWorksheetProgressResponse = {
  __typename?: 'JobWorksheetProgressResponse';
  docID?: Maybe<Scalars['ID']['output']>;
  status: JobWorksheetStatus;
};

export type JobWorksheetStartInput = {
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  effectiveFrom has time.RFC3339 format  */
  effectiveFrom?: InputMaybe<Scalars['String']['input']>;
  filename: Scalars['String']['input'];
  format: WorksheetFormat;
  jobID: Scalars['String']['input'];
  jobName: Scalars['String']['input'];
  jobType: JobType;
  /**  List of archipelago ids of selectable properties. Only applies to loss worksheets  */
  lossPropertyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['String']['input'];
  principalID?: InputMaybe<Scalars['String']['input']>;
  propertyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  provenanceDocID?: InputMaybe<Scalars['String']['input']>;
  s3Key: Scalars['String']['input'];
  streamID?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['String']['input']>;
};

export type JobWorksheetStartResponse = {
  __typename?: 'JobWorksheetStartResponse';
  docID?: Maybe<Scalars['ID']['output']>;
  /**
   * docID is only set when status is Completed. If status is Started, jobWorksheetProgress should be
   * called to get docID.
   * For JobTypeEnrichmentWithProvenance and JobTypeAddProperties, worksheet creation is synchronous
   * and docID is returned in the response.
   */
  status: JobWorksheetStatus;
};

export enum JobWorksheetStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Started = 'Started',
}

export type JobsFilter = {
  /**  Filter any job status that's not Archived, Complete or Expired  */
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  /**  returns jobs with specified jobIDs  */
  jobIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgID?: InputMaybe<Scalars['String']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  propertyID?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<JobStatus>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  type?: InputMaybe<JobType>;
  userID?: InputMaybe<Scalars['String']['input']>;
};

export type JobsPage = {
  __typename?: 'JobsPage';
  jobs: Array<Job>;
  /**  information about this jobs page  */
  pageInfo: PageInfo;
};

export type Lock = {
  __typename?: 'Lock';
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  /**  If true, current user can continue using this job instead of creating a new one */
  canReuse: Scalars['Boolean']['output'];
  canStart: Scalars['Boolean']['output'];
  expiryTime?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  propertyIds?: Maybe<Array<Scalars['String']['output']>>;
  type: LockType;
  worksheetURL?: Maybe<Scalars['String']['output']>;
};

export type LockPropertyAttributesInput = {
  /**
   * Only attributes matching the provided list of attributeNames are locked for edits.
   * If no attributeNames are provided, locks will be checked for all attributes.
   */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  Number of hours until the lock expires. A maximum of 336 hours may be specified.  */
  expireInHours?: InputMaybe<Scalars['Int']['input']>;
  orgID: Scalars['ID']['input'];
  /**
   * Only properties matching the propertyFilter are locked for edits. If propertyFilter
   * is not specified, all properties are locked.
   */
  propertyFilter?: InputMaybe<Array<AttributeFilter>>;
  /** If streamSlug is provided, only properties that are members of the stream are locked. */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type LockPropertyAttributesResponse = {
  __typename?: 'LockPropertyAttributesResponse';
  /** Names of attributes which have been locked. If all attributes are locked, no attributeNames are returned. */
  attributeNames?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Details about existing locks which prevented the requested property attributes from being locked.
   * Conflicts are only returned if locking the property attributes was not successful.
   * Only the first 100 conflicts are returned.
   */
  conflicts?: Maybe<Array<AttributeLockConflict>>;
  /**
   * ID for the group of property attributes locked. This can be used to update the locks for all the property attributes
   * using updatePropertyLocks.
   */
  groupID: Scalars['ID']['output'];
  /** IDs of properties which matched the filter specified in the request and have been locked for editing. */
  propertyIDs: Array<Scalars['ArchipelagoID']['output']>;
  /**
   * True if property attributes were successfully locked.
   * False if the property attributes are already locked.
   */
  success: Scalars['Boolean']['output'];
};

export enum LockType {
  AddLosses = 'AddLosses',
  AddProperties = 'AddProperties',
  EditLosses = 'EditLosses',
  EditProperties = 'EditProperties',
  EditProperty = 'EditProperty',
  Enrichment = 'Enrichment',
}

export type Loss = {
  __typename?: 'Loss';
  archipelagoLossId: Scalars['String']['output'];
  archipelagoPropertyId: Scalars['String']['output'];
  catastrophe?: Maybe<Scalars['Boolean']['output']>;
  catastropheEventName?: Maybe<Scalars['String']['output']>;
  clientClaimId?: Maybe<Scalars['String']['output']>;
  deductible?: Maybe<Scalars['Float']['output']>;
  generalCauseOfLoss?: Maybe<Scalars['String']['output']>;
  grossLossIncurred?: Maybe<Scalars['Float']['output']>;
  grossTotalLoss?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  lossCurrency?: Maybe<Scalars['String']['output']>;
  lossDate?: Maybe<Scalars['Date']['output']>;
  lossDescription?: Maybe<Scalars['String']['output']>;
  lossStatus?: Maybe<Scalars['String']['output']>;
  occurrenceNumber?: Maybe<Scalars['String']['output']>;
  orgName: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  paid?: Maybe<Scalars['Float']['output']>;
  property: Property;
  reserve?: Maybe<Scalars['Float']['output']>;
  specificCauseOfLoss?: Maybe<Scalars['String']['output']>;
};

export type LossAttributes = {
  __typename?: 'LossAttributes';
  largestLoss?: Maybe<Scalars['Float']['output']>;
  lossCountSinceLastBound?: Maybe<Scalars['Int']['output']>;
  lossCountTotal?: Maybe<Scalars['Int']['output']>;
  totalGrossLoss?: Maybe<Scalars['Float']['output']>;
};

export type LossFilter = {
  /**  A list of Archipelago Loss IDs.  */
  archipelagoLossIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  A list of Archipelago Property IDs.  */
  archipelagoPropertyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LossesForEnrichmentInput = {
  jobId: Scalars['String']['input'];
  selectedLossIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LossesInput = {
  lossFilter?: InputMaybe<LossFilter>;
  organizationId: Scalars['String']['input'];
};

export type MpAddWorksheetInput = {
  worksheet: MpWorksheetInput;
};

export type MpAddWorksheetResponse = {
  __typename?: 'MPAddWorksheetResponse';
  docID: Scalars['String']['output'];
  httpURL: Scalars['String']['output'];
};

export type MpEditWorksheetStartInput = {
  /**
   * Names of editable attributes for edit styling.
   * All enabled, non-derived attributes are included if this list is not provided.
   */
  editableAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Properties matching the included filter will be included in the worksheet. Archipelago IDs matching this filter are
   * returned in the response.
   * If specified filter does not match any property, the response returns an error.
   *
   * If not specified this matches all properties in the stream.
   */
  filter?: InputMaybe<Array<AttributeFilter>>;
  /**
   * Names of attributes which are included in the first columns of the worksheet to identify properties.
   * ArchipelagoID is included regardless of whether it is specified.
   */
  identifierAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  worksheet: MpWorksheetInput;
  /**
   * List of attributes to include in the worksheet.
   * All enabled attributes are included if this list is not provided.
   * ArchipelagoID is included regardless of whether it is specified.
   */
  worksheetAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MpEditWorksheetStartResponse = {
  __typename?: 'MPEditWorksheetStartResponse';
  /**  IDs of property which matched the filter specified in the request  */
  propertyIDs: Array<Scalars['ArchipelagoID']['output']>;
  status: JobWorksheetStatus;
};

export type MpWorksheetInput = {
  filename: Scalars['String']['input'];
  jobID: Scalars['String']['input'];
  jobName: Scalars['String']['input'];
  jobType: JobType;
  orgId: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
  streamID: Scalars['String']['input'];
};

export type MappingRuleAppliedChange = {
  __typename?: 'MappingRuleAppliedChange';
  /**  The value that the field was changed to  */
  after?: Maybe<Scalars['Any']['output']>;
  /**  The value that the field was changed from  */
  before?: Maybe<Scalars['Any']['output']>;
  /**  The name of the field that was changed  */
  name: Scalars['String']['output'];
  /**  The rule that caused the change  */
  rule: ValidateRuleV2;
};

export type MergePropertyAttributesInput = {
  archipelagoId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Deprecated - Use recordEdits instead
   * Insert an editing provenance for updated attributes.
   * Editing provenance is not created for provenanceAttributes values if specified
   */
  createEditingProvenance?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  payload: PropertyDataInput;
  /** Names of attributes for which provenance update should be updated */
  provenanceAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
  provenanceDocumentId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Record each attribute that was edited to a non null value in this operation
   *   Only applies if author is non admin
   */
  recordEdits?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MergePropertyAttributesWithMultiProvenancesInput = {
  archipelagoId?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  payload: PropertyDataInput;
  provenance?: InputMaybe<Array<UpdateProvenanceInput>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Deprecated. Use addOrgToDomainV2 instead */
  addOrgToDomain?: Maybe<Domain>;
  /** add org to the domain */
  addOrgToDomainV2?: Maybe<Domain>;
  /** Adds a single property with the provided data. */
  applyAddPropertyJob: Job;
  /** Deletes the properties with the specified IDs. */
  applyDeletePropertiesJob: Job;
  /** Disposes the properties with the specified IDs. */
  applyDisposePropertiesJob: Job;
  /** Applies edits to a property's attributes which were locked using initEditPropertyJob. */
  applyEditPropertyJob: Job;
  /** Applies an add or edit properties worksheet which has been validated using validateJobWorksheet. */
  applyJob: Job;
  /** Restores deleted properties. */
  applyRestoreDeletedPropertiesJob: Job;
  /** Restores disposed properties so that they can be visible in streams again. */
  applyRestoreDisposedPropertiesJob: Job;
  /** Applies property edits in the updated job worksheet which has been validated. */
  applySOVJob: Job;
  /**  Approved a job by reviewer. Comments are optional. */
  approveJob: Job;
  /**  Soft-Deletes a project. Can only be used by Archipelago Users.  */
  archiveProject: Scalars['Boolean']['output'];
  /**
   * Submit a completed job worksheet for validation. This mutation returns
   * a Job object, including the latest StateTransition to Submit, which will complete in the future.
   * For status updates query the Job object and inspect the 'progress' field of the transition
   * with the returned ID.
   */
  checkSOVJobWorksheet: Job;
  /**  Returns document ID to the property change template file. */
  checkoutJob: Worksheet;
  /**
   * For ingest jobs only (Ingest, Enrichment, EnrichmentWithProvenance and AddProperties)
   *   Should only be called by pebbles after ingest operation is finished.
   *   Completes an ongoing job transition and perform depending operations.
   *   If current transition is for Completed, it can send notification emails and update metrics
   */
  completeJobTransition?: Maybe<Job>;
  /**  copy attributes and related provenances from one property to other properties in the same group */
  copyProvenance: ProvenanceJobData;
  createJob?: Maybe<Job>;
  /**
   * creates "Bound" and 6 historic projects for provided org name. Historic project's name has format
   * [year]-[year+1]. For example, 2021-2022.
   */
  createOnboardingProjects?: Maybe<Scalars['String']['output']>;
  createProject?: Maybe<Project>;
  /**
   * Delete projects of an org. This is only supported for test orgs and
   *   can only be used by admin.
   */
  deleteOrgProjects: Scalars['Boolean']['output'];
  /** Mutation to be called after SOV is transformed and Column Mappings have been run. This endpoint will convert the SOV file to a Google Sheet, apply column mapping, normalize Construction & Occupancy values, when there's a failure sends an email to the DS team with a link to the Google Sheet for them to review and correct the values. */
  generateSOVWorkbook: Scalars['Boolean']['output'];
  /**
   * Download SOV job worksheet which adds Archipelago ID and Property Status columns.  Property status value is set to
   * one of ['Ongoing', 'New', 'Disposed'].
   *
   * Archipelago ID is populated for all 'Ongoing' property which are existing in the system. This worksheet also adds
   * columns to indicate current attribute values of 'Ongoing' properties which are different from the specified values in SOV.
   */
  getSOVJobWorksheet: Worksheet;
  /** Starts a job to add multiple properties and starts asynchronous generation of a worksheet. Job will expire in 48 hours. */
  initAddPropertiesJob: Job;
  /** Starts an edit properties job and starts asynchronous generation of a worksheet. Job will expire in 48 hours. */
  initEditPropertiesJob: Job;
  /** Starts a job to process property updates based on a SOV. */
  initSOVJob: Job;
  /**  Rejected a job by reviewer. Comments are optional. */
  rejectJob?: Maybe<Job>;
  /** Deprecated. Use removeOrgFromDomainV2 instead. */
  removeOrgFromDomain?: Maybe<Domain>;
  /** remove org from the domain */
  removeOrgFromDomainV2?: Maybe<Domain>;
  /**  retry a job which fails after approval, and can only be used by admin   */
  retryJob?: Maybe<Job>;
  /**  Set archipelago wind/flood/seismic zone hazards only. */
  setHazards?: Maybe<Scalars['ID']['output']>;
  /** Starts an add losses job. Job will expire in 48 hours. The createJob mutation is not required to use this. */
  startAddLosses?: Maybe<Job>;
  /** Starts an edit losses job. Job will expire in 48 hours. The createJob mutation is not required to use this. */
  startEditLosses?: Maybe<Job>;
  /**
   * Deprecated. Use initEditPropertyJob instead.
   * Starts an edit properties job. Job will expire in 48 hours. The createJob mutation is not required to use this.
   */
  startEditPropertiesJob: StartEditPropertiesJobOutput;
  startProvenanceEnrichment?: Maybe<Job>;
  /**  Revert a job. */
  startRevertJob?: Maybe<Job>;
  /** Submits an add losses job for validation and update. */
  submitAddLosses: Scalars['String']['output'];
  /** Submits an edit losses job for validation and update. */
  submitEditLosses: Scalars['String']['output'];
  /**
   * Deprecated. Use validateEditPropertiesJob instead.
   * Submits an edit properties job for validation.
   */
  submitEditPropertiesJob: SubmitEditPropertiesJobOutput;
  /**
   * Submit a completed job worksheet for validation. This mutation returns
   *   the stateTransitionId of the specified job, which will complete at some point in the future.
   *   For status updates query the Job object and inspect the 'progress' field of the transition
   *   with the returned ID.
   */
  submitJob: Scalars['String']['output'];
  submitOCJob: OcJobResponse;
  /** Deprecated. Use submitProvenanceEnrichmentV2 instead */
  submitProvenanceEnrichment: Scalars['String']['output'];
  /**  Submit a completed job. It returns the stateTransitionId of the specified job and validation errors.  */
  submitProvenanceEnrichmentV2: SubmitProvenanceEnrichmentOutput;
  /** Updates the geo job data stored for the provided job ID. Expects the provided job is of type Geo. */
  updateGeoJobData: Scalars['ID']['output'];
  updateJob?: Maybe<Job>;
  /** Updates property address data for an add or edit properties job. */
  updateJobPropertyAddresses: Job;
  /**  Updates submitted property data which is staged to applied with the job.  */
  updateJobPropertyData: Job;
  updateOCData: OcJobResponse;
  updateProject?: Maybe<Project>;
  updateProvenanceJobData: Scalars['ID']['output'];
  /**
   * Deprecated. Use updateSOVMappingsV2 instead.
   * Updates the mappings for the raw SOV specified by the document ID
   */
  updateSOVMappings: Scalars['ID']['output'];
  /** Updates the mappings for the raw SOV associated with specified job. */
  updateSOVMappingsV2: Scalars['ID']['output'];
  updateStreamMembershipReportData?: Maybe<Scalars['String']['output']>;
  /**
   * Runs validation on the new property data. Once the transition is complete, use updateJobPropertyData to update
   * based on validation errors or applyPropertiesJob to apply the new property data.
   */
  validateJobPropertyData: Job;
  /**
   * Runs initial validation on an add or edit properties worksheet and generates address suggestions.
   * Once the transition is complete, use updateJobPropertyAddresses to update address data or applyJob to
   * apply the new property data.
   */
  validateJobWorksheet: Job;
};

export type MutationAddOrgToDomainArgs = {
  input: AddOrgToDomainInput;
};

export type MutationAddOrgToDomainV2Args = {
  input: AddOrgToDomainInputV2;
};

export type MutationApplyAddPropertyJobArgs = {
  input: ApplyAddPropertyJobInput;
};

export type MutationApplyDeletePropertiesJobArgs = {
  input: ApplyDeletePropertiesJobInput;
};

export type MutationApplyDisposePropertiesJobArgs = {
  input: ApplyDisposePropertiesJobInput;
};

export type MutationApplyEditPropertyJobArgs = {
  input: ApplyEditPropertyJobInput;
};

export type MutationApplyJobArgs = {
  input: ApplyJobInput;
};

export type MutationApplyRestoreDeletedPropertiesJobArgs = {
  input: ApplyRestoreDeletedPropertiesJobInput;
};

export type MutationApplyRestoreDisposedPropertiesJobArgs = {
  input: ApplyRestoreDisposedPropertiesJobInput;
};

export type MutationApplySovJobArgs = {
  input: ApplySovJobInput;
};

export type MutationApproveJobArgs = {
  input: ApproveJobInput;
};

export type MutationArchiveProjectArgs = {
  input: ArchiveProjectInput;
};

export type MutationCheckSovJobWorksheetArgs = {
  input: CheckSovJobWorksheetInput;
};

export type MutationCheckoutJobArgs = {
  input: CheckoutJobInput;
};

export type MutationCompleteJobTransitionArgs = {
  input: CompleteJobTransitionInput;
};

export type MutationCopyProvenanceArgs = {
  input: CopyProvenanceInput;
};

export type MutationCreateJobArgs = {
  input: CreateJobInput;
};

export type MutationCreateOnboardingProjectsArgs = {
  input: CreateOnboardingProjectsInput;
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationDeleteOrgProjectsArgs = {
  input: DeleteOrgProjectsInput;
};

export type MutationGenerateSovWorkbookArgs = {
  input: GenerateSovWorkbookInput;
};

export type MutationGetSovJobWorksheetArgs = {
  input: GetSovJobWorksheetInput;
};

export type MutationInitAddPropertiesJobArgs = {
  input: InitAddPropertiesJobInput;
};

export type MutationInitEditPropertiesJobArgs = {
  input: InitEditPropertiesJobInput;
};

export type MutationInitSovJobArgs = {
  input: InitSovJobInput;
};

export type MutationRejectJobArgs = {
  input: RejectJobInput;
};

export type MutationRemoveOrgFromDomainArgs = {
  input: RemoveOrgFromDomainInput;
};

export type MutationRemoveOrgFromDomainV2Args = {
  input: RemoveOrgFromDomainInputV2;
};

export type MutationRetryJobArgs = {
  input: RetryJobInput;
};

export type MutationSetHazardsArgs = {
  input: SetHazardsInput;
};

export type MutationStartAddLossesArgs = {
  input: StartAddLossesJobInput;
};

export type MutationStartEditLossesArgs = {
  input: StartEditLossesJobInput;
};

export type MutationStartEditPropertiesJobArgs = {
  input: StartEditPropertiesJobInput;
};

export type MutationStartProvenanceEnrichmentArgs = {
  input: StartProvenanceEnrichmentInput;
};

export type MutationStartRevertJobArgs = {
  input: RevertJobInput;
};

export type MutationSubmitAddLossesArgs = {
  input: SubmitAddLossesJobInput;
};

export type MutationSubmitEditLossesArgs = {
  input: SubmitEditLossesJobInput;
};

export type MutationSubmitEditPropertiesJobArgs = {
  input: SubmitEditPropertiesJobInput;
};

export type MutationSubmitJobArgs = {
  input: SubmitJobInput;
};

export type MutationSubmitOcJobArgs = {
  input: SubmitOcJobInput;
};

export type MutationSubmitProvenanceEnrichmentArgs = {
  input: SubmitProvenanceEnrichmentInput;
};

export type MutationSubmitProvenanceEnrichmentV2Args = {
  input: SubmitProvenanceEnrichmentInput;
};

export type MutationUpdateGeoJobDataArgs = {
  input: GeoJobDataInput;
};

export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};

export type MutationUpdateJobPropertyAddressesArgs = {
  input: UpdateJobPropertyAddressesInput;
};

export type MutationUpdateJobPropertyDataArgs = {
  input: UpdateJobPropertyDataInput;
};

export type MutationUpdateOcDataArgs = {
  input: Array<UpdateOcDataInput>;
};

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

export type MutationUpdateProvenanceJobDataArgs = {
  input: ProvenanceJobDataInput;
};

export type MutationUpdateSovMappingsArgs = {
  input: UpdateSovMappingsInput;
};

export type MutationUpdateSovMappingsV2Args = {
  input: UpdateSovMappingsV2Input;
};

export type MutationUpdateStreamMembershipReportDataArgs = {
  input: UpdateStreamMembershipReportDataInput;
};

export type MutationValidateJobPropertyDataArgs = {
  input: ValidateJobPropertyDataInput;
};

export type MutationValidateJobWorksheetArgs = {
  input: ValidateJobWorksheetInput;
};

export type MyAuthorizedScopesInput = {
  action: Action;
  resourceType: ResourceType;
  scopeType: ScopeType;
};

export type OcAttribute = {
  __typename?: 'OCAttribute';
  currentValue?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  originalValue?: Maybe<Scalars['String']['output']>;
};

export enum OcAttributeType {
  Construction = 'Construction',
  Occupancy = 'Occupancy',
}

export type OcAttributesInput = {
  ocJobId: Scalars['String']['input'];
  /**  propertyIds for which attribute information is needed. These Ids are returned by ocPropertyGroups query.  */
  ocPropertyIds: Array<Scalars['String']['input']>;
};

export type OcAttributesResponse = {
  __typename?: 'OCAttributesResponse';
  ocPropertyAttributes?: Maybe<Array<OcPropertyAttributes>>;
};

export type OcJobResponse = {
  __typename?: 'OCJobResponse';
  ocJobId: Scalars['String']['output'];
  stateTransitionId: Scalars['String']['output'];
  status: Status;
};

export type OcMetrics = {
  __typename?: 'OCMetrics';
  clientId?: Maybe<Scalars['String']['output']>;
  rawSpecificConstruction?: Maybe<Scalars['String']['output']>;
  rawSpecificOccupancy?: Maybe<Scalars['String']['output']>;
  specificConstructionReason?: Maybe<Scalars['String']['output']>;
  specificConstructionSuggested?: Maybe<Scalars['String']['output']>;
  specificConstructionUpdated?: Maybe<Scalars['String']['output']>;
  specificOccupancyReason?: Maybe<Scalars['String']['output']>;
  specificOccupancySuggested?: Maybe<Scalars['String']['output']>;
  specificOccupancyUpdated?: Maybe<Scalars['String']['output']>;
};

export type OcMetricsInput = {
  ingestJobId: Scalars['String']['input'];
};

export type OcMetricsResponse = {
  __typename?: 'OCMetricsResponse';
  businessType?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  ocMetrics?: Maybe<Array<OcMetrics>>;
  orgName: Scalars['String']['output'];
};

export type OcPropertiesInput = {
  attributeType: OcAttributeType;
  jobID: Scalars['ID']['input'];
  ocPropertyIds: Array<Scalars['String']['input']>;
};

export type OcPropertyAttributes = {
  __typename?: 'OCPropertyAttributes';
  attributes?: Maybe<Array<OcAttribute>>;
  ocPropertyId: Scalars['String']['output'];
};

export type OcPropertyGroup = {
  __typename?: 'OCPropertyGroup';
  attributeType: OcAttributeType;
  confidencePercentage?: Maybe<Scalars['Int']['output']>;
  currentValue?: Maybe<Scalars['String']['output']>;
  /**  True if all member properties of this group have the same updatedValue.  */
  hasSingleUpdatedValue: Scalars['Boolean']['output'];
  /**
   * Identifier for each property in this group. The number of elements in this array are equal to the
   * value of 'propertyCount'. These are needed for ocAttributes query.
   */
  ocPropertyIds: Array<Scalars['String']['output']>;
  originalValue?: Maybe<Scalars['String']['output']>;
  /**  Number of properties with in this group.  */
  propertyCount: Scalars['Int']['output'];
  /**  Number of stories in each property of this property group. */
  storyCount?: Maybe<Scalars['Int']['output']>;
  suggestedValue?: Maybe<Scalars['String']['output']>;
  /**  Returns null if value was never changed or hasSingleUpdatedValue is false  */
  updatedValue?: Maybe<Scalars['String']['output']>;
};

export type OcPropertyGroupsInput = {
  /**  Attribute type for grouping properties.  */
  attributeType: OcAttributeType;
  jobID: Scalars['ID']['input'];
};

export type OcPropertyGroupsResponse = {
  __typename?: 'OCPropertyGroupsResponse';
  groups?: Maybe<Array<OcPropertyGroup>>;
  ingestFileURL: Scalars['String']['output'];
  jobID: Scalars['String']['output'];
  sovFilename: Scalars['String']['output'];
};

export type OptionalFeature = {
  __typename?: 'OptionalFeature';
  enable: Scalars['Boolean']['output'];
  forAllOrgs: Scalars['Boolean']['output'];
  forOrgNames?: Maybe<Array<OrgInfo>>;
  name: Scalars['String']['output'];
};

export type OrgInfo = {
  __typename?: 'OrgInfo';
  businessType?: Maybe<Scalars['String']['output']>;
  enablePropertyManagerEdit: Scalars['Boolean']['output'];
  /** Default false with the exception of prospect orgs registered through hubspot. */
  enableRedirectOnSOVUpload: Scalars['Boolean']['output'];
  /** Flag to enable/disable the submission dashboard. */
  enableSubmissionDashboard: Scalars['Boolean']['output'];
  /** If set to false, createSubmission mutation will assign creator as StreamViewer instead of RiskManager */
  enableUnderwriterEdit: Scalars['Boolean']['output'];
  enableUploadPortal: Scalars['Boolean']['output'];
  /** List of features enabled for this org */
  enabledFeatures?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type OrgPropertiesPageInput = {
  /**
   * Can be used to fetch active properties only. If not specified or value is false, returns all properties.
   * If specified value is true, excludes properties with status disposed or superceded.
   * Default value is false.
   */
  activePropertiesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Can be used to fetch current or historical properties. If not specified, returns current properties.
   * If specified, returns historical properties for the specified date.
   */
  effectiveFrom?: InputMaybe<Scalars['String']['input']>;
  /** Properties are filtered to those matching the set of provided filters. */
  filter?: InputMaybe<Array<AttributeFilter>>;
  /** Maximum number of properties to return. Default value is 10. A maximum of 200 may be requested. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset within the dataset from which to return results. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgName: Scalars['String']['input'];
  /** If not specified, latest visible snapshot is used. */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  /** Sort order of results. */
  sortBy?: InputMaybe<Array<Sort>>;
};

export enum OrgType {
  /**  Org with data for demos. Can not be deleted. Excluded from reporting/benchmarking.  */
  Demo = 'Demo',
  /**
   * Org containing data bound in a prior year. Used by underwriters to see what they are bound to in the prior year.
   * Can not be deleted. Excluded from reporting/benchmarking.
   */
  PriorYear = 'PriorYear',
  /**  Org with customer data. Included in reporting/benchmarking and can not be deleted.  */
  Production = 'Production',
  /**
   * Org created for a prospect customer. These can be changed to Production when a customer signs up.
   * Requires no manual geo-coding. Uses a light-weight Geo-job which returns only 4 hazard attributes (Seismic Zone, Wind Zone, Flood Zone, Geo-type).
   * Included in reporting/benchmarking and can not be deleted.
   */
  Prospect = 'Prospect',
  /**  Org which contains default metadata. Can not be created via the API. Excluded from reporting/benchmarking.  */
  SystemDefaults = 'SystemDefaults',
  /**  Org used as a template for new submissions. Can not be deleted. Excluded from reporting/benchmarking.  */
  Template = 'Template',
  /**  Org with test data. Test orgs can be deleted. Excluded from reporting/benchmarking.  */
  Test = 'Test',
}

export type OriginClassification = {
  classifications: Array<Scalars['String']['input']>;
  origin: Scalars['String']['input'];
};

export type OwnerCustomAttributes = {
  __typename?: 'OwnerCustomAttributes';
  ownerDecimal1?: Maybe<Scalars['Float']['output']>;
  ownerDecimal2?: Maybe<Scalars['Float']['output']>;
  ownerDecimal3?: Maybe<Scalars['Float']['output']>;
  ownerDecimal4?: Maybe<Scalars['Float']['output']>;
  ownerDecimal5?: Maybe<Scalars['Float']['output']>;
  ownerDecimal6?: Maybe<Scalars['Float']['output']>;
  ownerDecimal7?: Maybe<Scalars['Float']['output']>;
  ownerDecimal8?: Maybe<Scalars['Float']['output']>;
  ownerDecimal9?: Maybe<Scalars['Float']['output']>;
  ownerDecimal10?: Maybe<Scalars['Float']['output']>;
  ownerDecimal11?: Maybe<Scalars['Float']['output']>;
  ownerDecimal12?: Maybe<Scalars['Float']['output']>;
  ownerDecimal13?: Maybe<Scalars['Float']['output']>;
  ownerDecimal14?: Maybe<Scalars['Float']['output']>;
  ownerDecimal15?: Maybe<Scalars['Float']['output']>;
  ownerDecimal101?: Maybe<Scalars['Float']['output']>;
  ownerDecimal102?: Maybe<Scalars['Float']['output']>;
  ownerDecimal103?: Maybe<Scalars['Float']['output']>;
  ownerDecimal104?: Maybe<Scalars['Float']['output']>;
  ownerDecimal105?: Maybe<Scalars['Float']['output']>;
  ownerDecimal106?: Maybe<Scalars['Float']['output']>;
  ownerDecimal107?: Maybe<Scalars['Float']['output']>;
  ownerDecimal108?: Maybe<Scalars['Float']['output']>;
  ownerDecimal109?: Maybe<Scalars['Float']['output']>;
  ownerDecimal110?: Maybe<Scalars['Float']['output']>;
  ownerDecimal111?: Maybe<Scalars['Float']['output']>;
  ownerDecimal112?: Maybe<Scalars['Float']['output']>;
  ownerDecimal113?: Maybe<Scalars['Float']['output']>;
  ownerDecimal114?: Maybe<Scalars['Float']['output']>;
  ownerDecimal115?: Maybe<Scalars['Float']['output']>;
  ownerDecimal116?: Maybe<Scalars['Float']['output']>;
  ownerDecimal117?: Maybe<Scalars['Float']['output']>;
  ownerDecimal118?: Maybe<Scalars['Float']['output']>;
  ownerDecimal119?: Maybe<Scalars['Float']['output']>;
  ownerDecimal120?: Maybe<Scalars['Float']['output']>;
  ownerDecimal121?: Maybe<Scalars['Float']['output']>;
  ownerDecimal122?: Maybe<Scalars['Float']['output']>;
  ownerDecimal123?: Maybe<Scalars['Float']['output']>;
  ownerDecimal124?: Maybe<Scalars['Float']['output']>;
  ownerDecimal125?: Maybe<Scalars['Float']['output']>;
  ownerDecimal126?: Maybe<Scalars['Float']['output']>;
  ownerDecimal127?: Maybe<Scalars['Float']['output']>;
  ownerDecimal128?: Maybe<Scalars['Float']['output']>;
  ownerDecimal129?: Maybe<Scalars['Float']['output']>;
  ownerDecimal130?: Maybe<Scalars['Float']['output']>;
  ownerText1?: Maybe<Scalars['String']['output']>;
  ownerText2?: Maybe<Scalars['String']['output']>;
  ownerText3?: Maybe<Scalars['String']['output']>;
  ownerText4?: Maybe<Scalars['String']['output']>;
  ownerText5?: Maybe<Scalars['String']['output']>;
  ownerText6?: Maybe<Scalars['String']['output']>;
  ownerText7?: Maybe<Scalars['String']['output']>;
  ownerText8?: Maybe<Scalars['String']['output']>;
  ownerText9?: Maybe<Scalars['String']['output']>;
  ownerText10?: Maybe<Scalars['String']['output']>;
  ownerText11?: Maybe<Scalars['String']['output']>;
  ownerText12?: Maybe<Scalars['String']['output']>;
  ownerText13?: Maybe<Scalars['String']['output']>;
  ownerText14?: Maybe<Scalars['String']['output']>;
  ownerText15?: Maybe<Scalars['String']['output']>;
  ownerText101?: Maybe<Scalars['String']['output']>;
  ownerText102?: Maybe<Scalars['String']['output']>;
  ownerText103?: Maybe<Scalars['String']['output']>;
  ownerText104?: Maybe<Scalars['String']['output']>;
  ownerText105?: Maybe<Scalars['String']['output']>;
  ownerText106?: Maybe<Scalars['String']['output']>;
  ownerText107?: Maybe<Scalars['String']['output']>;
  ownerText108?: Maybe<Scalars['String']['output']>;
  ownerText109?: Maybe<Scalars['String']['output']>;
  ownerText110?: Maybe<Scalars['String']['output']>;
  ownerText111?: Maybe<Scalars['String']['output']>;
  ownerText112?: Maybe<Scalars['String']['output']>;
  ownerText113?: Maybe<Scalars['String']['output']>;
  ownerText114?: Maybe<Scalars['String']['output']>;
  ownerText115?: Maybe<Scalars['String']['output']>;
  ownerText116?: Maybe<Scalars['String']['output']>;
  ownerText117?: Maybe<Scalars['String']['output']>;
  ownerText118?: Maybe<Scalars['String']['output']>;
  ownerText119?: Maybe<Scalars['String']['output']>;
  ownerText120?: Maybe<Scalars['String']['output']>;
  ownerText121?: Maybe<Scalars['String']['output']>;
  ownerText122?: Maybe<Scalars['String']['output']>;
  ownerText123?: Maybe<Scalars['String']['output']>;
  ownerText124?: Maybe<Scalars['String']['output']>;
  ownerText125?: Maybe<Scalars['String']['output']>;
  ownerText126?: Maybe<Scalars['String']['output']>;
  ownerText127?: Maybe<Scalars['String']['output']>;
  ownerText128?: Maybe<Scalars['String']['output']>;
  ownerText129?: Maybe<Scalars['String']['output']>;
  ownerText130?: Maybe<Scalars['String']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /**  cursor to the next page  */
  cursor: Scalars['String']['output'];
  /**  whether the page has a next page with information available   */
  hasNextPage: Scalars['Boolean']['output'];
  /**  total number of rows in the dataset  */
  totalCount: Scalars['Int']['output'];
};

export type PageSection = {
  __typename?: 'PageSection';
  pageNumber: Scalars['Int']['output'];
  sentences?: Maybe<Array<Scalars['String']['output']>>;
};

export type Permission = {
  __typename?: 'Permission';
  action: Action;
  resource: ResourceType;
};

export type Pictures = {
  __typename?: 'Pictures';
  contentType?: Maybe<Scalars['String']['output']>;
  extension: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  httpURLs: ImageUrLs;
  size?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

/** A principal is a user, service or group requesting an action on a protected resource. The PrincipalType tells which possible values are supported by the system. */
export enum PrincipalType {
  /**
   * Principal of type Organization and is identified by it's UUID. This allows the authorization
   * service to make authorization decisions on a resource for all users that have this org as their
   * primary org. All roles granted to this org will also implicitly grant the user the same roles.
   */
  Org = 'Org',
  /**
   * Principal of type User and is identified by the user's email address. This allows the
   * authorization service to make authorization decisions on a resource for a single user.
   */
  User = 'User',
}

export type Project = {
  __typename?: 'Project';
  dueOn?: Maybe<Scalars['Date']['output']>;
  effectiveFrom?: Maybe<Scalars['Date']['output']>;
  /**  User specified unique project name, within the specified org.  */
  id: Scalars['String']['output'];
  jobs?: Maybe<Array<Job>>;
  /**  most recent ingest job id of the project  */
  lastIngestJobID?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  /**
   * Snapshot effective time is used for snapshot creation on this project.
   * This is calculated by going 1 quarter prior to the project effective date + historical rev counter.
   */
  snapshotEffectiveTime?: Maybe<Scalars['Time']['output']>;
};

export type PropertiesByAiDsInput = {
  archipelagoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * This is used in conjunction with includeProvenance which must be set to true.
   * If specifed value is true, includes property documents.
   * Default value is false.
   */
  includeDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If specifed value is true, includes property provenance.
   * Default value is false.
   */
  includeProvenance?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertiesByAiDsResponse = {
  __typename?: 'PropertiesByAiDsResponse';
  properties?: Maybe<Array<Property>>;
};

export type PropertiesByJobInput = {
  jobID: Scalars['String']['input'];
};

export type PropertiesForLossesInput = {
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgName: Scalars['String']['input'];
  projectName?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};

export type PropertiesPage = {
  __typename?: 'PropertiesPage';
  pageInfo: PageInfo;
  properties?: Maybe<Array<Property>>;
};

export type PropertiesPageResponse = {
  __typename?: 'PropertiesPageResponse';
  properties: Array<Property>;
};

export type Property = {
  __typename?: 'Property';
  accessoryStructures?: Maybe<Scalars['String']['output']>;
  acquiredOrBuilt?: Maybe<Scalars['String']['output']>;
  acquisitionOrOccupancyDate?: Maybe<Scalars['Date']['output']>;
  airEqBrickVeneer?: Maybe<Scalars['String']['output']>;
  airEqBuildingCondition?: Maybe<Scalars['String']['output']>;
  airEqBuildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  airEqBuildingShape?: Maybe<Scalars['String']['output']>;
  airEqChimney?: Maybe<Scalars['String']['output']>;
  airEqColdFormedTub?: Maybe<Scalars['String']['output']>;
  airEqColumnBasementType?: Maybe<Scalars['String']['output']>;
  airEqEquipment?: Maybe<Scalars['String']['output']>;
  airEqFoundationConnection?: Maybe<Scalars['String']['output']>;
  airEqFoundationType?: Maybe<Scalars['String']['output']>;
  airEqInternalPartitions?: Maybe<Scalars['String']['output']>;
  airEqIsFireSprinklerAvailable?: Maybe<Scalars['String']['output']>;
  airEqIsValueType?: Maybe<Scalars['String']['output']>;
  airEqLatticeType?: Maybe<Scalars['String']['output']>;
  airEqMultiStoryHallType?: Maybe<Scalars['String']['output']>;
  airEqOrnamentation?: Maybe<Scalars['String']['output']>;
  airEqPounding?: Maybe<Scalars['String']['output']>;
  airEqRedundancy?: Maybe<Scalars['String']['output']>;
  airEqRetrofitMeasures?: Maybe<Scalars['String']['output']>;
  airEqShortColumn?: Maybe<Scalars['String']['output']>;
  airEqSoftStory?: Maybe<Scalars['String']['output']>;
  airEqSpecialEQResistiveSystems?: Maybe<Scalars['String']['output']>;
  airEqStructuralIrregularity?: Maybe<Scalars['String']['output']>;
  airEqTallOneStory?: Maybe<Scalars['String']['output']>;
  airEqTank?: Maybe<Scalars['String']['output']>;
  airEqTorsion?: Maybe<Scalars['String']['output']>;
  airEqTransitionInSRCType?: Maybe<Scalars['String']['output']>;
  airEqWallSiding?: Maybe<Scalars['String']['output']>;
  airEqWallType?: Maybe<Scalars['String']['output']>;
  airEqWaterHeater?: Maybe<Scalars['String']['output']>;
  airEqWeldingDetail?: Maybe<Scalars['String']['output']>;
  airModifiers?: Maybe<Scalars['String']['output']>;
  airWindAdjacentBuildingHeight?: Maybe<Scalars['String']['output']>;
  airWindAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevation?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindBasementFinishType?: Maybe<Scalars['String']['output']>;
  airWindBasementLevelCount?: Maybe<Scalars['String']['output']>;
  airWindCertifiedStructuresIbhs?: Maybe<Scalars['String']['output']>;
  airWindContentVulnerability?: Maybe<Scalars['String']['output']>;
  airWindCustomElevation?: Maybe<Scalars['String']['output']>;
  airWindCustomElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindCustomFloodStandard?: Maybe<Scalars['Float']['output']>;
  airWindCustomFloodZone?: Maybe<Scalars['String']['output']>;
  airWindExteriorDoors?: Maybe<Scalars['String']['output']>;
  airWindFirmCompliance?: Maybe<Scalars['String']['output']>;
  airWindFirstFloorHeight?: Maybe<Scalars['Float']['output']>;
  airWindFirstFloorHeightUnit?: Maybe<Scalars['String']['output']>;
  airWindFloorOfInterest?: Maybe<Scalars['String']['output']>;
  airWindGlassPercentage?: Maybe<Scalars['String']['output']>;
  airWindGlassType?: Maybe<Scalars['String']['output']>;
  airWindLargeMissiles?: Maybe<Scalars['String']['output']>;
  airWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  airWindRoofAttachedStructures?: Maybe<Scalars['String']['output']>;
  airWindRoofCovering?: Maybe<Scalars['String']['output']>;
  airWindRoofCoveringAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofDeck?: Maybe<Scalars['String']['output']>;
  airWindRoofDeckAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  airWindRoofPitch?: Maybe<Scalars['String']['output']>;
  airWindRoofYearBuilt?: Maybe<Scalars['Int']['output']>;
  airWindSealOfApproval?: Maybe<Scalars['String']['output']>;
  airWindServiceEquipmentProtection?: Maybe<Scalars['String']['output']>;
  airWindSmallDebris?: Maybe<Scalars['String']['output']>;
  airWindTerrainRoughness?: Maybe<Scalars['String']['output']>;
  airWindTreeExposure?: Maybe<Scalars['String']['output']>;
  airWindWallAttachedStructure?: Maybe<Scalars['String']['output']>;
  airWindWindowProtection?: Maybe<Scalars['String']['output']>;
  annualBaseRent?: Maybe<Scalars['Float']['output']>;
  annualBaseRentDisplay?: Maybe<Scalars['Float']['output']>;
  annualEstimatedRent?: Maybe<Scalars['Float']['output']>;
  annualEstimatedRentDisplay?: Maybe<Scalars['Float']['output']>;
  annualGrossProfit?: Maybe<Scalars['Float']['output']>;
  annualGrossProfitDisplay?: Maybe<Scalars['Float']['output']>;
  annualLossOfRevenue?: Maybe<Scalars['Float']['output']>;
  annualLossOfRevenueDisplay?: Maybe<Scalars['Float']['output']>;
  annualServiceCharge?: Maybe<Scalars['Float']['output']>;
  annualServiceChargeDisplay?: Maybe<Scalars['Float']['output']>;
  archipelagoFloodZone?: Maybe<Scalars['String']['output']>;
  archipelagoId: Scalars['String']['output'];
  archipelagoSeismicZone?: Maybe<Scalars['String']['output']>;
  archipelagoWindZone?: Maybe<Scalars['String']['output']>;
  attributeEdits?: Maybe<Array<AttributeEditInfo>>;
  attributeProvenance?: Maybe<Array<Provenance>>;
  backupGenerator?: Maybe<Scalars['String']['output']>;
  backupGeneratorDieselStorageCapacity?: Maybe<Scalars['String']['output']>;
  backupGeneratorLocation?: Maybe<Scalars['String']['output']>;
  baseFloodElevation?: Maybe<Scalars['Float']['output']>;
  basementFinishType?: Maybe<Scalars['String']['output']>;
  buildingCondition?: Maybe<Scalars['String']['output']>;
  buildingDescription?: Maybe<Scalars['String']['output']>;
  buildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  buildingFootprintClass?: Maybe<Scalars['String']['output']>;
  buildingFoundationType?: Maybe<Scalars['String']['output']>;
  buildingRedundancy?: Maybe<Scalars['String']['output']>;
  buildingReplacementCost?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPartner?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifference?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifferenceRange?: Maybe<Scalars['String']['output']>;
  buildingReplacementCostValueDifference?: Maybe<Scalars['Float']['output']>;
  buildingSprinklerType?: Maybe<Scalars['String']['output']>;
  buildingSprinklered?: Maybe<Scalars['String']['output']>;
  buildingValue?: Maybe<Scalars['Float']['output']>;
  buildingValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessContinuityPlans?: Maybe<Scalars['String']['output']>;
  businessIncomeValue?: Maybe<Scalars['Float']['output']>;
  businessIncomeValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCost?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCostDisplay?: Maybe<Scalars['Float']['output']>;
  businessInterruptionPreparedness?: Maybe<Scalars['String']['output']>;
  businessInterruptionRedundancy?: Maybe<Scalars['String']['output']>;
  chimneys?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commodityHazardClass?: Maybe<Scalars['String']['output']>;
  constructionDescription?: Maybe<Scalars['String']['output']>;
  constructionQuality?: Maybe<Scalars['String']['output']>;
  constructionType?: Maybe<Scalars['String']['output']>;
  constructionTypeAIR?: Maybe<Scalars['String']['output']>;
  constructionTypeATC?: Maybe<Scalars['String']['output']>;
  constructionTypeArchipelago?: Maybe<Scalars['String']['output']>;
  constructionTypeCluster?: Maybe<Scalars['String']['output']>;
  constructionTypeISO?: Maybe<Scalars['String']['output']>;
  constructionTypeRMS?: Maybe<Scalars['String']['output']>;
  consumerAttributes?: Maybe<ConsumerCustomAttributes>;
  contentsReplacementCost?: Maybe<Scalars['Float']['output']>;
  contentsReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  contentsVulnerabilityFlood?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityVerticalDistributionOfContents?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityWind?: Maybe<Scalars['String']['output']>;
  contingency?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionComments?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionFlag?: Maybe<Scalars['Boolean']['output']>;
  controlOfCombustiblesProgram?: Maybe<Scalars['String']['output']>;
  convectiveStormZone?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Time']['output']>;
  crippleWalls?: Maybe<Scalars['String']['output']>;
  currencyPair?: Maybe<Scalars['String']['output']>;
  customerProvidedGeocode?: Maybe<Scalars['Boolean']['output']>;
  dependencyComments?: Maybe<Scalars['String']['output']>;
  dependencyCoveragePercentage?: Maybe<Scalars['Float']['output']>;
  dependencyFlag?: Maybe<Scalars['Boolean']['output']>;
  dependencyValue?: Maybe<Scalars['Float']['output']>;
  dependencyValueDisplay?: Maybe<Scalars['Float']['output']>;
  dispositionDate?: Maybe<Scalars['Date']['output']>;
  distanceToCoast?: Maybe<Scalars['Float']['output']>;
  distanceToNearestBuilding?: Maybe<Scalars['Float']['output']>;
  distanceToNearestFault?: Maybe<Scalars['Float']['output']>;
  documents?: Maybe<Array<Document>>;
  doorAndFrameType?: Maybe<Scalars['String']['output']>;
  effectiveFrom?: Maybe<Scalars['Time']['output']>;
  electronicsValue?: Maybe<Scalars['Float']['output']>;
  electronicsValueDisplay?: Maybe<Scalars['Float']['output']>;
  elevationOfGroundOrBasementLevelMEPEquipment?: Maybe<Scalars['String']['output']>;
  emergencyFloodProtectionMeasures?: Maybe<Scalars['String']['output']>;
  enriched?: Maybe<Scalars['Boolean']['output']>;
  eqSpecialSystems?: Maybe<Scalars['String']['output']>;
  equipmentEarthquakeBracing?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['Float']['output']>;
  exchangeRateDate?: Maybe<Scalars['Date']['output']>;
  exclusions?: Maybe<Scalars['String']['output']>;
  extraExpenseValue?: Maybe<Scalars['Float']['output']>;
  extraExpenseValueDisplay?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValue?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValueDisplay?: Maybe<Scalars['Float']['output']>;
  fireDetectionSystems?: Maybe<Scalars['String']['output']>;
  fireEstimatedMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProbableMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProtectionDescription?: Maybe<Scalars['String']['output']>;
  fireProtectionInspectionProgram?: Maybe<Scalars['String']['output']>;
  firePumpBackupPeriod?: Maybe<Scalars['Float']['output']>;
  firePumpChurnRate?: Maybe<Scalars['Float']['output']>;
  firePumpExcessCapacity?: Maybe<Scalars['String']['output']>;
  firePumpFlowRate?: Maybe<Scalars['String']['output']>;
  firePumpPowerSupply?: Maybe<Scalars['String']['output']>;
  firePumpTestResults?: Maybe<Scalars['String']['output']>;
  firstFloorElevation?: Maybe<Scalars['Float']['output']>;
  firstFloorHeight?: Maybe<Scalars['Float']['output']>;
  flammableLiquidCapacity?: Maybe<Scalars['Float']['output']>;
  flammableLiquidDescription?: Maybe<Scalars['String']['output']>;
  flammableLiquidGasLineManagement?: Maybe<Scalars['String']['output']>;
  flammableLiquidProgram?: Maybe<Scalars['String']['output']>;
  flammableLiquidStorageLocation?: Maybe<Scalars['String']['output']>;
  flammableLiquidsOnsite?: Maybe<Scalars['String']['output']>;
  floodMissiles?: Maybe<Scalars['String']['output']>;
  floodZone?: Maybe<Scalars['String']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  floorOfInterest?: Maybe<Scalars['Int']['output']>;
  floorSystem?: Maybe<Scalars['String']['output']>;
  foundationToFrameConnection?: Maybe<Scalars['String']['output']>;
  generatorTestingProgram?: Maybe<Scalars['String']['output']>;
  geoCodeType?: Maybe<Scalars['String']['output']>;
  glassPercentage?: Maybe<Scalars['String']['output']>;
  glassType?: Maybe<Scalars['String']['output']>;
  hailHazardClass?: Maybe<Scalars['String']['output']>;
  hasSeismicInspectionReport?: Maybe<Scalars['Boolean']['output']>;
  hazardSummary?: Maybe<Scalars['Boolean']['output']>;
  heightOfRackStorage?: Maybe<Scalars['Float']['output']>;
  highHazardAreaSprinklerType?: Maybe<Scalars['String']['output']>;
  hotWorkProgram?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsResidual?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsStatic?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  improvementsValue?: Maybe<Scalars['Float']['output']>;
  improvementsValueDisplay?: Maybe<Scalars['Float']['output']>;
  inRackSprinklerType?: Maybe<Scalars['String']['output']>;
  interiorPartitions?: Maybe<Scalars['String']['output']>;
  inventoryValue?: Maybe<Scalars['Float']['output']>;
  inventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  landslideSusceptibility?: Maybe<Scalars['String']['output']>;
  lastEngineeringVisitDate?: Maybe<Scalars['Date']['output']>;
  lastValuationDate?: Maybe<Scalars['String']['output']>;
  latestRevisionId?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  leasedOrOwned?: Maybe<Scalars['String']['output']>;
  lightningHazardClass?: Maybe<Scalars['String']['output']>;
  liquefactionSusceptibility?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['String']['output'];
  locationName?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  lossAttributes?: Maybe<LossAttributes>;
  lossEngineeringReportPresent?: Maybe<Scalars['String']['output']>;
  machineryValue?: Maybe<Scalars['Float']['output']>;
  machineryValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValue?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValue?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscContentsValue?: Maybe<Scalars['Float']['output']>;
  miscContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  modelingAvailable?: Maybe<Scalars['Boolean']['output']>;
  multiTenant?: Maybe<Scalars['Boolean']['output']>;
  naturalGasAutomaticShutoff?: Maybe<Scalars['String']['output']>;
  naturalGasPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  numberOfBuildings?: Maybe<Scalars['Int']['output']>;
  numberOfFireHazardAreas?: Maybe<Scalars['Int']['output']>;
  numberOfHighPriorityRecommendations?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesAboveGround?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesBelowGround?: Maybe<Scalars['Int']['output']>;
  numberOfUnits?: Maybe<Scalars['Int']['output']>;
  occupancyDescription?: Maybe<Scalars['String']['output']>;
  occupancyType?: Maybe<Scalars['String']['output']>;
  occupancyTypeATC?: Maybe<Scalars['String']['output']>;
  occupancyTypeNAICS?: Maybe<Scalars['String']['output']>;
  occupancyTypeSIC?: Maybe<Scalars['String']['output']>;
  orgName: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  organizationLevelFour?: Maybe<Scalars['String']['output']>;
  organizationLevelOne?: Maybe<Scalars['String']['output']>;
  organizationLevelThree?: Maybe<Scalars['String']['output']>;
  organizationLevelTwo?: Maybe<Scalars['String']['output']>;
  originalAddress?: Maybe<Scalars['String']['output']>;
  ornamentation?: Maybe<Scalars['String']['output']>;
  otherContentsValue?: Maybe<Scalars['Float']['output']>;
  otherContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  ownerAttributes?: Maybe<OwnerCustomAttributes>;
  payrollValue?: Maybe<Scalars['Float']['output']>;
  payrollValueDisplay?: Maybe<Scalars['Float']['output']>;
  percentageSprinklered?: Maybe<Scalars['Float']['output']>;
  permanentFloodMeasuresPresent?: Maybe<Scalars['String']['output']>;
  pictures?: Maybe<Array<Pictures>>;
  portableFireExtinguisherProgram?: Maybe<Scalars['String']['output']>;
  portfolio?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryTenantNAICS?: Maybe<Scalars['String']['output']>;
  propertyManager?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  propertyStatus?: Maybe<Scalars['String']['output']>;
  quakeScenarioEstimatedLoss?: Maybe<Scalars['Float']['output']>;
  quakeScenarioUpperLoss?: Maybe<Scalars['Float']['output']>;
  rackStoragePresent?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  remoteMonitoringOfSprinklerSystem?: Maybe<Scalars['Boolean']['output']>;
  rentAndServiceIndemnityPeriod?: Maybe<Scalars['Int']['output']>;
  replacementCostMethodology?: Maybe<Scalars['String']['output']>;
  replacementCostPerFloorAreaPartner?: Maybe<Scalars['Float']['output']>;
  replacementCostPerFloorAreaPartnerDifference?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootage?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootageDisplay?: Maybe<Scalars['Float']['output']>;
  residentialGarage?: Maybe<Scalars['String']['output']>;
  revisionId?: Maybe<Scalars['String']['output']>;
  rmsEqAppendagesAndOrnamentation?: Maybe<Scalars['String']['output']>;
  rmsEqBaseIsolation?: Maybe<Scalars['String']['output']>;
  rmsEqConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsEqContentsRateGradeEQ?: Maybe<Scalars['String']['output']>;
  rmsEqCrippleWalls?: Maybe<Scalars['String']['output']>;
  rmsEqEarthquakeSprinklerCoverageFlag?: Maybe<Scalars['String']['output']>;
  rmsEqEngineeredFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqEnvelopeOpening?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentEQBracing?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentSupportMaintenance?: Maybe<Scalars['String']['output']>;
  rmsEqExteriorWallsCladding?: Maybe<Scalars['String']['output']>;
  rmsEqFrameBoltedtoFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqPounding?: Maybe<Scalars['String']['output']>;
  rmsEqPurlinAnchoringTiltUpRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqRedundancy?: Maybe<Scalars['String']['output']>;
  rmsEqShapeConfiguration?: Maybe<Scalars['String']['output']>;
  rmsEqShortColumnCondition?: Maybe<Scalars['String']['output']>;
  rmsEqSoftStory?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerLeakageSusceptibility?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerType?: Maybe<Scalars['String']['output']>;
  rmsEqStructuralUpgradeNonURM?: Maybe<Scalars['String']['output']>;
  rmsEqTank?: Maybe<Scalars['String']['output']>;
  rmsEqTorsion?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryPartition?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqVerticalIrregularitySetbackandOverhangs?: Maybe<Scalars['String']['output']>;
  rmsModifiers?: Maybe<Scalars['String']['output']>;
  rmsOtherBIPreparedness?: Maybe<Scalars['String']['output']>;
  rmsOtherBIRedundancy?: Maybe<Scalars['String']['output']>;
  rmsOtherPercentageContentsBelowGrade?: Maybe<Scalars['Float']['output']>;
  rmsRoofSheathingAttachment?: Maybe<Scalars['String']['output']>;
  rmsWindBIDependency?: Maybe<Scalars['String']['output']>;
  rmsWindBasementProtection?: Maybe<Scalars['String']['output']>;
  rmsWindCauseOfLossFlag?: Maybe<Scalars['String']['output']>;
  rmsWindCladding?: Maybe<Scalars['String']['output']>;
  rmsWindCommercialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsWindContentsRateGradeWind?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToFlood?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToWind?: Maybe<Scalars['String']['output']>;
  rmsWindCopingAndFlashingQuality?: Maybe<Scalars['String']['output']>;
  rmsWindFinishedFloorHeightAboveGround?: Maybe<Scalars['Float']['output']>;
  rmsWindFinishedFloorHeightMatchLevel?: Maybe<Scalars['Float']['output']>;
  rmsWindFirstFloorElevation?: Maybe<Scalars['Float']['output']>;
  rmsWindFloodMissiles?: Maybe<Scalars['String']['output']>;
  rmsWindFloodProtection?: Maybe<Scalars['String']['output']>;
  rmsWindFloorType?: Maybe<Scalars['String']['output']>;
  rmsWindFrameFoundationConnection?: Maybe<Scalars['String']['output']>;
  rmsWindGroundLevelEquipment?: Maybe<Scalars['String']['output']>;
  rmsWindOpeningProtection?: Maybe<Scalars['String']['output']>;
  rmsWindResidentialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindResistanceDoors?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAge?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  rmsWindRoofEquipmentWindBracing?: Maybe<Scalars['String']['output']>;
  rmsWindRoofFramingType?: Maybe<Scalars['String']['output']>;
  rmsWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  rmsWindRoofMaintenance?: Maybe<Scalars['String']['output']>;
  rmsWindRoofParapetsChimneys?: Maybe<Scalars['String']['output']>;
  rmsWindRoofSystemCovering?: Maybe<Scalars['String']['output']>;
  rmsWindVerticalExposureDistribution?: Maybe<Scalars['String']['output']>;
  rmsWindWindMissiles?: Maybe<Scalars['String']['output']>;
  roofChimneysAnchorage?: Maybe<Scalars['String']['output']>;
  roofCopingAndFlashing?: Maybe<Scalars['String']['output']>;
  roofDeckAnchorage?: Maybe<Scalars['String']['output']>;
  roofDeckType?: Maybe<Scalars['String']['output']>;
  roofDescription?: Maybe<Scalars['String']['output']>;
  roofDrainageType?: Maybe<Scalars['String']['output']>;
  roofEquipmentAnchorage?: Maybe<Scalars['String']['output']>;
  roofEstimatedReplacementYear?: Maybe<Scalars['Int']['output']>;
  roofGeometry?: Maybe<Scalars['String']['output']>;
  roofHailMitigation?: Maybe<Scalars['String']['output']>;
  roofImage?: Maybe<Scalars['String']['output']>;
  roofInspectionProgram?: Maybe<Scalars['String']['output']>;
  roofInstallYear?: Maybe<Scalars['Int']['output']>;
  roofParapets?: Maybe<Scalars['String']['output']>;
  roofScreensSignsAnchorageandBracing?: Maybe<Scalars['String']['output']>;
  roofSolarPanelCoverage?: Maybe<Scalars['String']['output']>;
  roofSolarPanelDescription?: Maybe<Scalars['String']['output']>;
  roofSolarPanelOwnership?: Maybe<Scalars['Float']['output']>;
  roofStructures?: Maybe<Scalars['String']['output']>;
  roofSystem?: Maybe<Scalars['String']['output']>;
  roofSystemAnchorage?: Maybe<Scalars['String']['output']>;
  roofingMaterialAnchorage?: Maybe<Scalars['String']['output']>;
  rooftopSolarPanels?: Maybe<Scalars['String']['output']>;
  rooftopWaterTanks?: Maybe<Scalars['String']['output']>;
  securityDescription?: Maybe<Scalars['String']['output']>;
  seismicDesignQuality?: Maybe<Scalars['String']['output']>;
  seismicDesignRValue?: Maybe<Scalars['Float']['output']>;
  seismicHazardClass?: Maybe<Scalars['String']['output']>;
  seismicInspectionDate?: Maybe<Scalars['Date']['output']>;
  seismicStatus?: Maybe<Scalars['String']['output']>;
  seismicZone?: Maybe<Scalars['String']['output']>;
  shortColumnConcrete?: Maybe<Scalars['String']['output']>;
  siteSoilClassification?: Maybe<Scalars['String']['output']>;
  smokingControlsProgram?: Maybe<Scalars['String']['output']>;
  softStory?: Maybe<Scalars['String']['output']>;
  sovSortOrder?: Maybe<Scalars['Int']['output']>;
  specificOccupancy?: Maybe<Scalars['String']['output']>;
  sprinklerHeadSize?: Maybe<Scalars['Float']['output']>;
  sprinklerHeadTemperatureRating?: Maybe<Scalars['String']['output']>;
  sprinklerLeakDetectionSystem?: Maybe<Scalars['Boolean']['output']>;
  sprinklerPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stockThroughputExcessInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputExcessInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  stockThroughputFactor?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  storageArrangementDescription?: Maybe<Scalars['String']['output']>;
  streamMembership?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  structuralDescription?: Maybe<Scalars['String']['output']>;
  structuralSystemUpgraded?: Maybe<Scalars['String']['output']>;
  structuralUpgradeType?: Maybe<Scalars['String']['output']>;
  suitabilityOfFireProtectionMeasures?: Maybe<Scalars['String']['output']>;
  surfaceRoughnessWind?: Maybe<Scalars['String']['output']>;
  tornadoHazardClass?: Maybe<Scalars['String']['output']>;
  torsion?: Maybe<Scalars['String']['output']>;
  totalInsuredValue?: Maybe<Scalars['Float']['output']>;
  totalInsuredValueDisplay?: Maybe<Scalars['Float']['output']>;
  treeExposure?: Maybe<Scalars['String']['output']>;
  tsunamiHazardClass?: Maybe<Scalars['String']['output']>;
  /**  Profile of the user that last made changes to the property.  */
  updatedBy?: Maybe<UserProfile>;
  valuationCurrency?: Maybe<Scalars['String']['output']>;
  valueInspectionProgram?: Maybe<Scalars['String']['output']>;
  verticalIrregularity?: Maybe<Scalars['String']['output']>;
  wallCladdingSystem?: Maybe<Scalars['String']['output']>;
  waterHeaterBracing?: Maybe<Scalars['String']['output']>;
  waterSupplyFlowRate?: Maybe<Scalars['String']['output']>;
  waterSupplyTime?: Maybe<Scalars['Float']['output']>;
  wildfireHazardClass?: Maybe<Scalars['String']['output']>;
  wildfireZone?: Maybe<Scalars['String']['output']>;
  windHazardClass?: Maybe<Scalars['String']['output']>;
  windMissiles?: Maybe<Scalars['String']['output']>;
  windZone?: Maybe<Scalars['String']['output']>;
  windowProtection?: Maybe<Scalars['String']['output']>;
  windowType?: Maybe<Scalars['String']['output']>;
  yearBuilt?: Maybe<Scalars['Int']['output']>;
  yearLastUpgraded?: Maybe<Scalars['Int']['output']>;
  yearsInPortfolio?: Maybe<Scalars['Int']['output']>;
};

export type PropertyAddress = {
  __typename?: 'PropertyAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
};

export type PropertyAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  /**  Unique identifier for the property within job.  */
  jobPropertyID: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};

export type PropertyAddressSuggestion = {
  __typename?: 'PropertyAddressSuggestion';
  /**  Unique identifier for the property within job.  */
  jobPropertyID: Scalars['String']['output'];
  submittedAddress: PropertyAddress;
  suggestedAddress: PropertyAddress;
};

export type PropertyChanges = {
  __typename?: 'PropertyChanges';
  changes?: Maybe<Array<MappingRuleAppliedChange>>;
  propertyArchipelagoID: Scalars['String']['output'];
};

export type PropertyCount = {
  __typename?: 'PropertyCount';
  failed: Scalars['Int']['output'];
  invalid: Scalars['Int']['output'];
  processed: Scalars['Int']['output'];
  success: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PropertyData = {
  __typename?: 'PropertyData';
  accessoryStructures?: Maybe<Scalars['String']['output']>;
  acquiredOrBuilt?: Maybe<Scalars['String']['output']>;
  acquisitionOrOccupancyDate?: Maybe<Scalars['Date']['output']>;
  airEqBrickVeneer?: Maybe<Scalars['String']['output']>;
  airEqBuildingCondition?: Maybe<Scalars['String']['output']>;
  airEqBuildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  airEqBuildingShape?: Maybe<Scalars['String']['output']>;
  airEqChimney?: Maybe<Scalars['String']['output']>;
  airEqColdFormedTub?: Maybe<Scalars['String']['output']>;
  airEqColumnBasementType?: Maybe<Scalars['String']['output']>;
  airEqEquipment?: Maybe<Scalars['String']['output']>;
  airEqFoundationConnection?: Maybe<Scalars['String']['output']>;
  airEqFoundationType?: Maybe<Scalars['String']['output']>;
  airEqInternalPartitions?: Maybe<Scalars['String']['output']>;
  airEqIsFireSprinklerAvailable?: Maybe<Scalars['String']['output']>;
  airEqIsValueType?: Maybe<Scalars['String']['output']>;
  airEqLatticeType?: Maybe<Scalars['String']['output']>;
  airEqMultiStoryHallType?: Maybe<Scalars['String']['output']>;
  airEqOrnamentation?: Maybe<Scalars['String']['output']>;
  airEqPounding?: Maybe<Scalars['String']['output']>;
  airEqRedundancy?: Maybe<Scalars['String']['output']>;
  airEqRetrofitMeasures?: Maybe<Scalars['String']['output']>;
  airEqShortColumn?: Maybe<Scalars['String']['output']>;
  airEqSoftStory?: Maybe<Scalars['String']['output']>;
  airEqSpecialEQResistiveSystems?: Maybe<Scalars['String']['output']>;
  airEqStructuralIrregularity?: Maybe<Scalars['String']['output']>;
  airEqTallOneStory?: Maybe<Scalars['String']['output']>;
  airEqTank?: Maybe<Scalars['String']['output']>;
  airEqTorsion?: Maybe<Scalars['String']['output']>;
  airEqTransitionInSRCType?: Maybe<Scalars['String']['output']>;
  airEqWallSiding?: Maybe<Scalars['String']['output']>;
  airEqWallType?: Maybe<Scalars['String']['output']>;
  airEqWaterHeater?: Maybe<Scalars['String']['output']>;
  airEqWeldingDetail?: Maybe<Scalars['String']['output']>;
  airModifiers?: Maybe<Scalars['String']['output']>;
  airWindAdjacentBuildingHeight?: Maybe<Scalars['String']['output']>;
  airWindAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevation?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindBasementFinishType?: Maybe<Scalars['String']['output']>;
  airWindBasementLevelCount?: Maybe<Scalars['String']['output']>;
  airWindCertifiedStructuresIbhs?: Maybe<Scalars['String']['output']>;
  airWindContentVulnerability?: Maybe<Scalars['String']['output']>;
  airWindCustomElevation?: Maybe<Scalars['String']['output']>;
  airWindCustomElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindCustomFloodStandard?: Maybe<Scalars['Float']['output']>;
  airWindCustomFloodZone?: Maybe<Scalars['String']['output']>;
  airWindExteriorDoors?: Maybe<Scalars['String']['output']>;
  airWindFirmCompliance?: Maybe<Scalars['String']['output']>;
  airWindFirstFloorHeight?: Maybe<Scalars['Float']['output']>;
  airWindFirstFloorHeightUnit?: Maybe<Scalars['String']['output']>;
  airWindFloorOfInterest?: Maybe<Scalars['String']['output']>;
  airWindGlassPercentage?: Maybe<Scalars['String']['output']>;
  airWindGlassType?: Maybe<Scalars['String']['output']>;
  airWindLargeMissiles?: Maybe<Scalars['String']['output']>;
  airWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  airWindRoofAttachedStructures?: Maybe<Scalars['String']['output']>;
  airWindRoofCovering?: Maybe<Scalars['String']['output']>;
  airWindRoofCoveringAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofDeck?: Maybe<Scalars['String']['output']>;
  airWindRoofDeckAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  airWindRoofPitch?: Maybe<Scalars['String']['output']>;
  airWindRoofYearBuilt?: Maybe<Scalars['Int']['output']>;
  airWindSealOfApproval?: Maybe<Scalars['String']['output']>;
  airWindServiceEquipmentProtection?: Maybe<Scalars['String']['output']>;
  airWindSmallDebris?: Maybe<Scalars['String']['output']>;
  airWindTerrainRoughness?: Maybe<Scalars['String']['output']>;
  airWindTreeExposure?: Maybe<Scalars['String']['output']>;
  airWindWallAttachedStructure?: Maybe<Scalars['String']['output']>;
  airWindWindowProtection?: Maybe<Scalars['String']['output']>;
  annualBaseRent?: Maybe<Scalars['Float']['output']>;
  annualBaseRentDisplay?: Maybe<Scalars['Float']['output']>;
  annualEstimatedRent?: Maybe<Scalars['Float']['output']>;
  annualEstimatedRentDisplay?: Maybe<Scalars['Float']['output']>;
  annualGrossProfit?: Maybe<Scalars['Float']['output']>;
  annualGrossProfitDisplay?: Maybe<Scalars['Float']['output']>;
  annualLossOfRevenue?: Maybe<Scalars['Float']['output']>;
  annualLossOfRevenueDisplay?: Maybe<Scalars['Float']['output']>;
  annualServiceCharge?: Maybe<Scalars['Float']['output']>;
  annualServiceChargeDisplay?: Maybe<Scalars['Float']['output']>;
  archipelagoFloodZone?: Maybe<Scalars['String']['output']>;
  archipelagoId: Scalars['String']['output'];
  archipelagoSeismicZone?: Maybe<Scalars['String']['output']>;
  archipelagoWindZone?: Maybe<Scalars['String']['output']>;
  backupGenerator?: Maybe<Scalars['String']['output']>;
  backupGeneratorDieselStorageCapacity?: Maybe<Scalars['String']['output']>;
  backupGeneratorLocation?: Maybe<Scalars['String']['output']>;
  baseFloodElevation?: Maybe<Scalars['Float']['output']>;
  basementFinishType?: Maybe<Scalars['String']['output']>;
  buildingCondition?: Maybe<Scalars['String']['output']>;
  buildingDescription?: Maybe<Scalars['String']['output']>;
  buildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  buildingFootprintClass?: Maybe<Scalars['String']['output']>;
  buildingFoundationType?: Maybe<Scalars['String']['output']>;
  buildingRedundancy?: Maybe<Scalars['String']['output']>;
  buildingReplacementCost?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPartner?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifference?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifferenceRange?: Maybe<Scalars['String']['output']>;
  buildingReplacementCostValueDifference?: Maybe<Scalars['Float']['output']>;
  buildingSprinklerType?: Maybe<Scalars['String']['output']>;
  buildingSprinklered?: Maybe<Scalars['String']['output']>;
  buildingValue?: Maybe<Scalars['Float']['output']>;
  buildingValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessContinuityPlans?: Maybe<Scalars['String']['output']>;
  businessIncomeValue?: Maybe<Scalars['Float']['output']>;
  businessIncomeValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCost?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCostDisplay?: Maybe<Scalars['Float']['output']>;
  businessInterruptionPreparedness?: Maybe<Scalars['String']['output']>;
  businessInterruptionRedundancy?: Maybe<Scalars['String']['output']>;
  chimneys?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commodityHazardClass?: Maybe<Scalars['String']['output']>;
  constructionDescription?: Maybe<Scalars['String']['output']>;
  constructionQuality?: Maybe<Scalars['String']['output']>;
  constructionType?: Maybe<Scalars['String']['output']>;
  constructionTypeAIR?: Maybe<Scalars['String']['output']>;
  constructionTypeATC?: Maybe<Scalars['String']['output']>;
  constructionTypeArchipelago?: Maybe<Scalars['String']['output']>;
  constructionTypeCluster?: Maybe<Scalars['String']['output']>;
  constructionTypeISO?: Maybe<Scalars['String']['output']>;
  constructionTypeRMS?: Maybe<Scalars['String']['output']>;
  consumerAttributes?: Maybe<ConsumerCustomAttributes>;
  contentsReplacementCost?: Maybe<Scalars['Float']['output']>;
  contentsReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  contentsVulnerabilityFlood?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityVerticalDistributionOfContents?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityWind?: Maybe<Scalars['String']['output']>;
  contingency?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionComments?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionFlag?: Maybe<Scalars['Boolean']['output']>;
  controlOfCombustiblesProgram?: Maybe<Scalars['String']['output']>;
  convectiveStormZone?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  crippleWalls?: Maybe<Scalars['String']['output']>;
  currencyPair?: Maybe<Scalars['String']['output']>;
  customerProvidedGeocode?: Maybe<Scalars['Boolean']['output']>;
  dependencyComments?: Maybe<Scalars['String']['output']>;
  dependencyCoveragePercentage?: Maybe<Scalars['Float']['output']>;
  dependencyFlag?: Maybe<Scalars['Boolean']['output']>;
  dependencyValue?: Maybe<Scalars['Float']['output']>;
  dependencyValueDisplay?: Maybe<Scalars['Float']['output']>;
  dispositionDate?: Maybe<Scalars['Date']['output']>;
  distanceToCoast?: Maybe<Scalars['Float']['output']>;
  distanceToNearestBuilding?: Maybe<Scalars['Float']['output']>;
  distanceToNearestFault?: Maybe<Scalars['Float']['output']>;
  doorAndFrameType?: Maybe<Scalars['String']['output']>;
  electronicsValue?: Maybe<Scalars['Float']['output']>;
  electronicsValueDisplay?: Maybe<Scalars['Float']['output']>;
  elevationOfGroundOrBasementLevelMEPEquipment?: Maybe<Scalars['String']['output']>;
  emergencyFloodProtectionMeasures?: Maybe<Scalars['String']['output']>;
  /**  This attribute is automatically computed whenever provenance is updated. Should be true as long as current property has attribute provenance with a sourced document.  */
  enriched?: Maybe<Scalars['Boolean']['output']>;
  eqSpecialSystems?: Maybe<Scalars['String']['output']>;
  equipmentEarthquakeBracing?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['Float']['output']>;
  exchangeRateDate?: Maybe<Scalars['Date']['output']>;
  exclusions?: Maybe<Scalars['String']['output']>;
  extraExpenseValue?: Maybe<Scalars['Float']['output']>;
  extraExpenseValueDisplay?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValue?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValueDisplay?: Maybe<Scalars['Float']['output']>;
  fireDetectionSystems?: Maybe<Scalars['String']['output']>;
  fireEstimatedMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProbableMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProtectionDescription?: Maybe<Scalars['String']['output']>;
  fireProtectionInspectionProgram?: Maybe<Scalars['String']['output']>;
  firePumpBackupPeriod?: Maybe<Scalars['Float']['output']>;
  firePumpChurnRate?: Maybe<Scalars['Float']['output']>;
  firePumpExcessCapacity?: Maybe<Scalars['String']['output']>;
  firePumpFlowRate?: Maybe<Scalars['String']['output']>;
  firePumpPowerSupply?: Maybe<Scalars['String']['output']>;
  firePumpTestResults?: Maybe<Scalars['String']['output']>;
  firstFloorElevation?: Maybe<Scalars['Float']['output']>;
  firstFloorHeight?: Maybe<Scalars['Float']['output']>;
  flammableLiquidCapacity?: Maybe<Scalars['Float']['output']>;
  flammableLiquidDescription?: Maybe<Scalars['String']['output']>;
  flammableLiquidGasLineManagement?: Maybe<Scalars['String']['output']>;
  flammableLiquidProgram?: Maybe<Scalars['String']['output']>;
  flammableLiquidStorageLocation?: Maybe<Scalars['String']['output']>;
  flammableLiquidsOnsite?: Maybe<Scalars['String']['output']>;
  floodMissiles?: Maybe<Scalars['String']['output']>;
  floodZone?: Maybe<Scalars['String']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  floorOfInterest?: Maybe<Scalars['Int']['output']>;
  floorSystem?: Maybe<Scalars['String']['output']>;
  foundationToFrameConnection?: Maybe<Scalars['String']['output']>;
  generatorTestingProgram?: Maybe<Scalars['String']['output']>;
  geoCodeType?: Maybe<Scalars['String']['output']>;
  glassPercentage?: Maybe<Scalars['String']['output']>;
  glassType?: Maybe<Scalars['String']['output']>;
  hailHazardClass?: Maybe<Scalars['String']['output']>;
  hasSeismicInspectionReport?: Maybe<Scalars['Boolean']['output']>;
  hazardSummary?: Maybe<Scalars['Boolean']['output']>;
  heightOfRackStorage?: Maybe<Scalars['Float']['output']>;
  highHazardAreaSprinklerType?: Maybe<Scalars['String']['output']>;
  hotWorkProgram?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsResidual?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsStatic?: Maybe<Scalars['String']['output']>;
  improvementsValue?: Maybe<Scalars['Float']['output']>;
  improvementsValueDisplay?: Maybe<Scalars['Float']['output']>;
  inRackSprinklerType?: Maybe<Scalars['String']['output']>;
  interiorPartitions?: Maybe<Scalars['String']['output']>;
  inventoryValue?: Maybe<Scalars['Float']['output']>;
  inventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  landslideSusceptibility?: Maybe<Scalars['String']['output']>;
  lastEngineeringVisitDate?: Maybe<Scalars['Date']['output']>;
  lastValuationDate?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  leasedOrOwned?: Maybe<Scalars['String']['output']>;
  lightningHazardClass?: Maybe<Scalars['String']['output']>;
  liquefactionSusceptibility?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  lossAttributes?: Maybe<LossAttributes>;
  lossEngineeringReportPresent?: Maybe<Scalars['String']['output']>;
  machineryValue?: Maybe<Scalars['Float']['output']>;
  machineryValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValue?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValue?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscContentsValue?: Maybe<Scalars['Float']['output']>;
  miscContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  modelingAvailable?: Maybe<Scalars['Boolean']['output']>;
  multiTenant?: Maybe<Scalars['Boolean']['output']>;
  naturalGasAutomaticShutoff?: Maybe<Scalars['String']['output']>;
  naturalGasPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  numberOfBuildings?: Maybe<Scalars['Int']['output']>;
  numberOfFireHazardAreas?: Maybe<Scalars['Int']['output']>;
  numberOfHighPriorityRecommendations?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesAboveGround?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesBelowGround?: Maybe<Scalars['Int']['output']>;
  numberOfUnits?: Maybe<Scalars['Int']['output']>;
  occupancyDescription?: Maybe<Scalars['String']['output']>;
  occupancyType?: Maybe<Scalars['String']['output']>;
  occupancyTypeATC?: Maybe<Scalars['String']['output']>;
  occupancyTypeNAICS?: Maybe<Scalars['String']['output']>;
  occupancyTypeSIC?: Maybe<Scalars['String']['output']>;
  organizationLevelFour?: Maybe<Scalars['String']['output']>;
  organizationLevelOne?: Maybe<Scalars['String']['output']>;
  organizationLevelThree?: Maybe<Scalars['String']['output']>;
  organizationLevelTwo?: Maybe<Scalars['String']['output']>;
  originalAddress?: Maybe<Scalars['String']['output']>;
  ornamentation?: Maybe<Scalars['String']['output']>;
  otherContentsValue?: Maybe<Scalars['Float']['output']>;
  otherContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  ownerAttributes?: Maybe<OwnerCustomAttributes>;
  payrollValue?: Maybe<Scalars['Float']['output']>;
  payrollValueDisplay?: Maybe<Scalars['Float']['output']>;
  percentageSprinklered?: Maybe<Scalars['Float']['output']>;
  permanentFloodMeasuresPresent?: Maybe<Scalars['String']['output']>;
  portableFireExtinguisherProgram?: Maybe<Scalars['String']['output']>;
  portfolio?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryTenantNAICS?: Maybe<Scalars['String']['output']>;
  propertyManager?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  propertyStatus?: Maybe<Scalars['String']['output']>;
  quakeScenarioEstimatedLoss?: Maybe<Scalars['Float']['output']>;
  quakeScenarioUpperLoss?: Maybe<Scalars['Float']['output']>;
  rackStoragePresent?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  remoteMonitoringOfSprinklerSystem?: Maybe<Scalars['Boolean']['output']>;
  rentAndServiceIndemnityPeriod?: Maybe<Scalars['Int']['output']>;
  replacementCostMethodology?: Maybe<Scalars['String']['output']>;
  replacementCostPerFloorAreaPartner?: Maybe<Scalars['Float']['output']>;
  replacementCostPerFloorAreaPartnerDifference?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootage?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootageDisplay?: Maybe<Scalars['Float']['output']>;
  residentialGarage?: Maybe<Scalars['String']['output']>;
  rmsEqAppendagesAndOrnamentation?: Maybe<Scalars['String']['output']>;
  rmsEqBaseIsolation?: Maybe<Scalars['String']['output']>;
  rmsEqConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsEqContentsRateGradeEQ?: Maybe<Scalars['String']['output']>;
  rmsEqCrippleWalls?: Maybe<Scalars['String']['output']>;
  rmsEqEarthquakeSprinklerCoverageFlag?: Maybe<Scalars['String']['output']>;
  rmsEqEngineeredFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqEnvelopeOpening?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentEQBracing?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentSupportMaintenance?: Maybe<Scalars['String']['output']>;
  rmsEqExteriorWallsCladding?: Maybe<Scalars['String']['output']>;
  rmsEqFrameBoltedtoFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqPounding?: Maybe<Scalars['String']['output']>;
  rmsEqPurlinAnchoringTiltUpRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqRedundancy?: Maybe<Scalars['String']['output']>;
  rmsEqShapeConfiguration?: Maybe<Scalars['String']['output']>;
  rmsEqShortColumnCondition?: Maybe<Scalars['String']['output']>;
  rmsEqSoftStory?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerLeakageSusceptibility?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerType?: Maybe<Scalars['String']['output']>;
  rmsEqStructuralUpgradeNonURM?: Maybe<Scalars['String']['output']>;
  rmsEqTank?: Maybe<Scalars['String']['output']>;
  rmsEqTorsion?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryPartition?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqVerticalIrregularitySetbackandOverhangs?: Maybe<Scalars['String']['output']>;
  rmsModifiers?: Maybe<Scalars['String']['output']>;
  rmsOtherBIPreparedness?: Maybe<Scalars['String']['output']>;
  rmsOtherBIRedundancy?: Maybe<Scalars['String']['output']>;
  rmsOtherPercentageContentsBelowGrade?: Maybe<Scalars['Float']['output']>;
  rmsRoofSheathingAttachment?: Maybe<Scalars['String']['output']>;
  rmsWindBIDependency?: Maybe<Scalars['String']['output']>;
  rmsWindBasementProtection?: Maybe<Scalars['String']['output']>;
  rmsWindCauseOfLossFlag?: Maybe<Scalars['String']['output']>;
  rmsWindCladding?: Maybe<Scalars['String']['output']>;
  rmsWindCommercialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsWindContentsRateGradeWind?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToFlood?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToWind?: Maybe<Scalars['String']['output']>;
  rmsWindCopingAndFlashingQuality?: Maybe<Scalars['String']['output']>;
  rmsWindFinishedFloorHeightAboveGround?: Maybe<Scalars['Float']['output']>;
  rmsWindFinishedFloorHeightMatchLevel?: Maybe<Scalars['Float']['output']>;
  rmsWindFirstFloorElevation?: Maybe<Scalars['Float']['output']>;
  rmsWindFloodMissiles?: Maybe<Scalars['String']['output']>;
  rmsWindFloodProtection?: Maybe<Scalars['String']['output']>;
  rmsWindFloorType?: Maybe<Scalars['String']['output']>;
  rmsWindFrameFoundationConnection?: Maybe<Scalars['String']['output']>;
  rmsWindGroundLevelEquipment?: Maybe<Scalars['String']['output']>;
  rmsWindOpeningProtection?: Maybe<Scalars['String']['output']>;
  rmsWindResidentialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindResistanceDoors?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAge?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  rmsWindRoofEquipmentWindBracing?: Maybe<Scalars['String']['output']>;
  rmsWindRoofFramingType?: Maybe<Scalars['String']['output']>;
  rmsWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  rmsWindRoofMaintenance?: Maybe<Scalars['String']['output']>;
  rmsWindRoofParapetsChimneys?: Maybe<Scalars['String']['output']>;
  rmsWindRoofSystemCovering?: Maybe<Scalars['String']['output']>;
  rmsWindVerticalExposureDistribution?: Maybe<Scalars['String']['output']>;
  rmsWindWindMissiles?: Maybe<Scalars['String']['output']>;
  roofChimneysAnchorage?: Maybe<Scalars['String']['output']>;
  roofCopingAndFlashing?: Maybe<Scalars['String']['output']>;
  roofDeckAnchorage?: Maybe<Scalars['String']['output']>;
  roofDeckType?: Maybe<Scalars['String']['output']>;
  roofDescription?: Maybe<Scalars['String']['output']>;
  roofDrainageType?: Maybe<Scalars['String']['output']>;
  roofEquipmentAnchorage?: Maybe<Scalars['String']['output']>;
  roofEstimatedReplacementYear?: Maybe<Scalars['Int']['output']>;
  roofGeometry?: Maybe<Scalars['String']['output']>;
  roofHailMitigation?: Maybe<Scalars['String']['output']>;
  roofImage?: Maybe<Scalars['String']['output']>;
  roofInspectionProgram?: Maybe<Scalars['String']['output']>;
  roofInstallYear?: Maybe<Scalars['Int']['output']>;
  roofParapets?: Maybe<Scalars['String']['output']>;
  roofScreensSignsAnchorageandBracing?: Maybe<Scalars['String']['output']>;
  roofSolarPanelCoverage?: Maybe<Scalars['String']['output']>;
  roofSolarPanelDescription?: Maybe<Scalars['String']['output']>;
  roofSolarPanelOwnership?: Maybe<Scalars['Float']['output']>;
  roofStructures?: Maybe<Scalars['String']['output']>;
  roofSystem?: Maybe<Scalars['String']['output']>;
  roofSystemAnchorage?: Maybe<Scalars['String']['output']>;
  roofingMaterialAnchorage?: Maybe<Scalars['String']['output']>;
  rooftopSolarPanels?: Maybe<Scalars['String']['output']>;
  rooftopWaterTanks?: Maybe<Scalars['String']['output']>;
  securityDescription?: Maybe<Scalars['String']['output']>;
  seismicDesignQuality?: Maybe<Scalars['String']['output']>;
  seismicDesignRValue?: Maybe<Scalars['Float']['output']>;
  seismicHazardClass?: Maybe<Scalars['String']['output']>;
  seismicInspectionDate?: Maybe<Scalars['Date']['output']>;
  seismicStatus?: Maybe<Scalars['String']['output']>;
  seismicZone?: Maybe<Scalars['String']['output']>;
  shortColumnConcrete?: Maybe<Scalars['String']['output']>;
  siteSoilClassification?: Maybe<Scalars['String']['output']>;
  smokingControlsProgram?: Maybe<Scalars['String']['output']>;
  softStory?: Maybe<Scalars['String']['output']>;
  sovSortOrder?: Maybe<Scalars['Int']['output']>;
  specificOccupancy?: Maybe<Scalars['String']['output']>;
  sprinklerHeadSize?: Maybe<Scalars['Float']['output']>;
  sprinklerHeadTemperatureRating?: Maybe<Scalars['String']['output']>;
  sprinklerLeakDetectionSystem?: Maybe<Scalars['Boolean']['output']>;
  sprinklerPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stockThroughputExcessInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputExcessInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  stockThroughputFactor?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  storageArrangementDescription?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  structuralDescription?: Maybe<Scalars['String']['output']>;
  structuralSystemUpgraded?: Maybe<Scalars['String']['output']>;
  structuralUpgradeType?: Maybe<Scalars['String']['output']>;
  suitabilityOfFireProtectionMeasures?: Maybe<Scalars['String']['output']>;
  surfaceRoughnessWind?: Maybe<Scalars['String']['output']>;
  tornadoHazardClass?: Maybe<Scalars['String']['output']>;
  torsion?: Maybe<Scalars['String']['output']>;
  totalInsuredValue?: Maybe<Scalars['Float']['output']>;
  totalInsuredValueDisplay?: Maybe<Scalars['Float']['output']>;
  treeExposure?: Maybe<Scalars['String']['output']>;
  tsunamiHazardClass?: Maybe<Scalars['String']['output']>;
  valuationCurrency?: Maybe<Scalars['String']['output']>;
  valueInspectionProgram?: Maybe<Scalars['String']['output']>;
  verticalIrregularity?: Maybe<Scalars['String']['output']>;
  wallCladdingSystem?: Maybe<Scalars['String']['output']>;
  waterHeaterBracing?: Maybe<Scalars['String']['output']>;
  waterSupplyFlowRate?: Maybe<Scalars['String']['output']>;
  waterSupplyTime?: Maybe<Scalars['Float']['output']>;
  wildfireHazardClass?: Maybe<Scalars['String']['output']>;
  wildfireZone?: Maybe<Scalars['String']['output']>;
  windHazardClass?: Maybe<Scalars['String']['output']>;
  windMissiles?: Maybe<Scalars['String']['output']>;
  windZone?: Maybe<Scalars['String']['output']>;
  windowProtection?: Maybe<Scalars['String']['output']>;
  windowType?: Maybe<Scalars['String']['output']>;
  yearBuilt?: Maybe<Scalars['Int']['output']>;
  yearLastUpgraded?: Maybe<Scalars['Int']['output']>;
  yearsInPortfolio?: Maybe<Scalars['Int']['output']>;
};

export type PropertyDataInput = {
  accessoryStructures?: InputMaybe<Scalars['String']['input']>;
  acquiredOrBuilt?: InputMaybe<Scalars['String']['input']>;
  acquisitionOrOccupancyDate?: InputMaybe<Scalars['Date']['input']>;
  airEqBrickVeneer?: InputMaybe<Scalars['String']['input']>;
  airEqBuildingCondition?: InputMaybe<Scalars['String']['input']>;
  airEqBuildingExteriorOpening?: InputMaybe<Scalars['String']['input']>;
  airEqBuildingShape?: InputMaybe<Scalars['String']['input']>;
  airEqChimney?: InputMaybe<Scalars['String']['input']>;
  airEqColdFormedTub?: InputMaybe<Scalars['String']['input']>;
  airEqColumnBasementType?: InputMaybe<Scalars['String']['input']>;
  airEqEquipment?: InputMaybe<Scalars['String']['input']>;
  airEqFoundationConnection?: InputMaybe<Scalars['String']['input']>;
  airEqFoundationType?: InputMaybe<Scalars['String']['input']>;
  airEqInternalPartitions?: InputMaybe<Scalars['String']['input']>;
  airEqIsFireSprinklerAvailable?: InputMaybe<Scalars['String']['input']>;
  airEqIsValueType?: InputMaybe<Scalars['String']['input']>;
  airEqLatticeType?: InputMaybe<Scalars['String']['input']>;
  airEqMultiStoryHallType?: InputMaybe<Scalars['String']['input']>;
  airEqOrnamentation?: InputMaybe<Scalars['String']['input']>;
  airEqPounding?: InputMaybe<Scalars['String']['input']>;
  airEqRedundancy?: InputMaybe<Scalars['String']['input']>;
  airEqRetrofitMeasures?: InputMaybe<Scalars['String']['input']>;
  airEqShortColumn?: InputMaybe<Scalars['String']['input']>;
  airEqSoftStory?: InputMaybe<Scalars['String']['input']>;
  airEqSpecialEQResistiveSystems?: InputMaybe<Scalars['String']['input']>;
  airEqStructuralIrregularity?: InputMaybe<Scalars['String']['input']>;
  airEqTallOneStory?: InputMaybe<Scalars['String']['input']>;
  airEqTank?: InputMaybe<Scalars['String']['input']>;
  airEqTorsion?: InputMaybe<Scalars['String']['input']>;
  airEqTransitionInSRCType?: InputMaybe<Scalars['String']['input']>;
  airEqWallSiding?: InputMaybe<Scalars['String']['input']>;
  airEqWallType?: InputMaybe<Scalars['String']['input']>;
  airEqWaterHeater?: InputMaybe<Scalars['String']['input']>;
  airEqWeldingDetail?: InputMaybe<Scalars['String']['input']>;
  airModifiers?: InputMaybe<Scalars['String']['input']>;
  airWindAdjacentBuildingHeight?: InputMaybe<Scalars['String']['input']>;
  airWindAppurtenantStructures?: InputMaybe<Scalars['String']['input']>;
  airWindBaseFloodElevation?: InputMaybe<Scalars['String']['input']>;
  airWindBaseFloodElevationUnit?: InputMaybe<Scalars['String']['input']>;
  airWindBasementFinishType?: InputMaybe<Scalars['String']['input']>;
  airWindBasementLevelCount?: InputMaybe<Scalars['String']['input']>;
  airWindCertifiedStructuresIbhs?: InputMaybe<Scalars['String']['input']>;
  airWindContentVulnerability?: InputMaybe<Scalars['String']['input']>;
  airWindCustomElevation?: InputMaybe<Scalars['String']['input']>;
  airWindCustomElevationUnit?: InputMaybe<Scalars['String']['input']>;
  airWindCustomFloodStandard?: InputMaybe<Scalars['Float']['input']>;
  airWindCustomFloodZone?: InputMaybe<Scalars['String']['input']>;
  airWindExteriorDoors?: InputMaybe<Scalars['String']['input']>;
  airWindFirmCompliance?: InputMaybe<Scalars['String']['input']>;
  airWindFirstFloorHeight?: InputMaybe<Scalars['Float']['input']>;
  airWindFirstFloorHeightUnit?: InputMaybe<Scalars['String']['input']>;
  airWindFloorOfInterest?: InputMaybe<Scalars['String']['input']>;
  airWindGlassPercentage?: InputMaybe<Scalars['String']['input']>;
  airWindGlassType?: InputMaybe<Scalars['String']['input']>;
  airWindLargeMissiles?: InputMaybe<Scalars['String']['input']>;
  airWindRoofAnchorage?: InputMaybe<Scalars['String']['input']>;
  airWindRoofAttachedStructures?: InputMaybe<Scalars['String']['input']>;
  airWindRoofCovering?: InputMaybe<Scalars['String']['input']>;
  airWindRoofCoveringAttachment?: InputMaybe<Scalars['String']['input']>;
  airWindRoofDeck?: InputMaybe<Scalars['String']['input']>;
  airWindRoofDeckAttachment?: InputMaybe<Scalars['String']['input']>;
  airWindRoofGeometry?: InputMaybe<Scalars['String']['input']>;
  airWindRoofPitch?: InputMaybe<Scalars['String']['input']>;
  airWindRoofYearBuilt?: InputMaybe<Scalars['Int']['input']>;
  airWindSealOfApproval?: InputMaybe<Scalars['String']['input']>;
  airWindServiceEquipmentProtection?: InputMaybe<Scalars['String']['input']>;
  airWindSmallDebris?: InputMaybe<Scalars['String']['input']>;
  airWindTerrainRoughness?: InputMaybe<Scalars['String']['input']>;
  airWindTreeExposure?: InputMaybe<Scalars['String']['input']>;
  airWindWallAttachedStructure?: InputMaybe<Scalars['String']['input']>;
  airWindWindowProtection?: InputMaybe<Scalars['String']['input']>;
  annualBaseRent?: InputMaybe<Scalars['Float']['input']>;
  annualEstimatedRent?: InputMaybe<Scalars['Float']['input']>;
  annualGrossProfit?: InputMaybe<Scalars['Float']['input']>;
  annualLossOfRevenue?: InputMaybe<Scalars['Float']['input']>;
  annualServiceCharge?: InputMaybe<Scalars['Float']['input']>;
  archipelagoFloodZone?: InputMaybe<Scalars['String']['input']>;
  archipelagoSeismicZone?: InputMaybe<Scalars['String']['input']>;
  archipelagoWindZone?: InputMaybe<Scalars['String']['input']>;
  backupGenerator?: InputMaybe<Scalars['String']['input']>;
  backupGeneratorDieselStorageCapacity?: InputMaybe<Scalars['String']['input']>;
  backupGeneratorLocation?: InputMaybe<Scalars['String']['input']>;
  baseFloodElevation?: InputMaybe<Scalars['Float']['input']>;
  basementFinishType?: InputMaybe<Scalars['String']['input']>;
  buildingCondition?: InputMaybe<Scalars['String']['input']>;
  buildingDescription?: InputMaybe<Scalars['String']['input']>;
  buildingExteriorOpening?: InputMaybe<Scalars['String']['input']>;
  buildingFootprintClass?: InputMaybe<Scalars['String']['input']>;
  buildingFoundationType?: InputMaybe<Scalars['String']['input']>;
  buildingRedundancy?: InputMaybe<Scalars['String']['input']>;
  buildingReplacementCost?: InputMaybe<Scalars['Float']['input']>;
  buildingReplacementCostPartner?: InputMaybe<Scalars['Float']['input']>;
  buildingReplacementCostPercentDifference?: InputMaybe<Scalars['Float']['input']>;
  buildingReplacementCostPercentDifferenceRange?: InputMaybe<Scalars['String']['input']>;
  buildingReplacementCostValueDifference?: InputMaybe<Scalars['Float']['input']>;
  buildingSprinklerType?: InputMaybe<Scalars['String']['input']>;
  buildingSprinklered?: InputMaybe<Scalars['String']['input']>;
  buildingValue?: InputMaybe<Scalars['Float']['input']>;
  businessContinuityPlans?: InputMaybe<Scalars['String']['input']>;
  businessIncomeValue?: InputMaybe<Scalars['Float']['input']>;
  businessInterruptionCost?: InputMaybe<Scalars['Float']['input']>;
  businessInterruptionPreparedness?: InputMaybe<Scalars['String']['input']>;
  businessInterruptionRedundancy?: InputMaybe<Scalars['String']['input']>;
  chimneys?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  commodityHazardClass?: InputMaybe<Scalars['String']['input']>;
  constructionDescription?: InputMaybe<Scalars['String']['input']>;
  constructionQuality?: InputMaybe<Scalars['String']['input']>;
  constructionType?: InputMaybe<Scalars['String']['input']>;
  constructionTypeAIR?: InputMaybe<Scalars['String']['input']>;
  constructionTypeATC?: InputMaybe<Scalars['String']['input']>;
  constructionTypeArchipelago?: InputMaybe<Scalars['String']['input']>;
  constructionTypeCluster?: InputMaybe<Scalars['String']['input']>;
  constructionTypeISO?: InputMaybe<Scalars['String']['input']>;
  constructionTypeRMS?: InputMaybe<Scalars['String']['input']>;
  contentsReplacementCost?: InputMaybe<Scalars['Float']['input']>;
  contentsVulnerabilityFlood?: InputMaybe<Scalars['String']['input']>;
  contentsVulnerabilityVerticalDistributionOfContents?: InputMaybe<Scalars['String']['input']>;
  contentsVulnerabilityWind?: InputMaybe<Scalars['String']['input']>;
  contingency?: InputMaybe<Scalars['String']['input']>;
  contingentBusinessInterruptionComments?: InputMaybe<Scalars['String']['input']>;
  contingentBusinessInterruptionFlag?: InputMaybe<Scalars['Boolean']['input']>;
  controlOfCombustiblesProgram?: InputMaybe<Scalars['String']['input']>;
  convectiveStormZone?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  crippleWalls?: InputMaybe<Scalars['String']['input']>;
  /** Risk Manager/Owner Custom Attributes */
  customAttributes?: InputMaybe<CustomAttributeInput>;
  customerProvidedGeocode?: InputMaybe<Scalars['Boolean']['input']>;
  dependencyComments?: InputMaybe<Scalars['String']['input']>;
  dependencyCoveragePercentage?: InputMaybe<Scalars['Float']['input']>;
  dependencyFlag?: InputMaybe<Scalars['Boolean']['input']>;
  dispositionDate?: InputMaybe<Scalars['Date']['input']>;
  distanceToCoast?: InputMaybe<Scalars['Float']['input']>;
  distanceToNearestBuilding?: InputMaybe<Scalars['Float']['input']>;
  distanceToNearestFault?: InputMaybe<Scalars['Float']['input']>;
  doorAndFrameType?: InputMaybe<Scalars['String']['input']>;
  effectiveFrom?: InputMaybe<Scalars['Time']['input']>;
  electronicsValue?: InputMaybe<Scalars['Float']['input']>;
  elevationOfGroundOrBasementLevelMEPEquipment?: InputMaybe<Scalars['String']['input']>;
  emergencyFloodProtectionMeasures?: InputMaybe<Scalars['String']['input']>;
  enriched?: InputMaybe<Scalars['Boolean']['input']>;
  eqSpecialSystems?: InputMaybe<Scalars['String']['input']>;
  equipmentEarthquakeBracing?: InputMaybe<Scalars['String']['input']>;
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  exchangeRateDate?: InputMaybe<Scalars['Date']['input']>;
  exclusions?: InputMaybe<Scalars['String']['input']>;
  extraExpenseValue?: InputMaybe<Scalars['Float']['input']>;
  fineArtJewelryValue?: InputMaybe<Scalars['Float']['input']>;
  fireDetectionSystems?: InputMaybe<Scalars['String']['input']>;
  fireEstimatedMaximumLoss?: InputMaybe<Scalars['Float']['input']>;
  fireProbableMaximumLoss?: InputMaybe<Scalars['Float']['input']>;
  fireProtectionDescription?: InputMaybe<Scalars['String']['input']>;
  fireProtectionInspectionProgram?: InputMaybe<Scalars['String']['input']>;
  firePumpBackupPeriod?: InputMaybe<Scalars['Float']['input']>;
  firePumpChurnRate?: InputMaybe<Scalars['Float']['input']>;
  firePumpExcessCapacity?: InputMaybe<Scalars['String']['input']>;
  firePumpFlowRate?: InputMaybe<Scalars['String']['input']>;
  firePumpPowerSupply?: InputMaybe<Scalars['String']['input']>;
  firePumpTestResults?: InputMaybe<Scalars['String']['input']>;
  firstFloorElevation?: InputMaybe<Scalars['Float']['input']>;
  firstFloorHeight?: InputMaybe<Scalars['Float']['input']>;
  flammableLiquidCapacity?: InputMaybe<Scalars['Float']['input']>;
  flammableLiquidDescription?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidGasLineManagement?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidProgram?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidStorageLocation?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidsOnsite?: InputMaybe<Scalars['String']['input']>;
  floodMissiles?: InputMaybe<Scalars['String']['input']>;
  floodZone?: InputMaybe<Scalars['String']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  floorOfInterest?: InputMaybe<Scalars['Int']['input']>;
  floorSystem?: InputMaybe<Scalars['String']['input']>;
  foundationToFrameConnection?: InputMaybe<Scalars['String']['input']>;
  generatorTestingProgram?: InputMaybe<Scalars['String']['input']>;
  geoCodeType?: InputMaybe<Scalars['String']['input']>;
  glassPercentage?: InputMaybe<Scalars['String']['input']>;
  glassType?: InputMaybe<Scalars['String']['input']>;
  hailHazardClass?: InputMaybe<Scalars['String']['input']>;
  hasSeismicInspectionReport?: InputMaybe<Scalars['Boolean']['input']>;
  hazardSummary?: InputMaybe<Scalars['Boolean']['input']>;
  heightOfRackStorage?: InputMaybe<Scalars['Float']['input']>;
  highHazardAreaSprinklerType?: InputMaybe<Scalars['String']['input']>;
  hotWorkProgram?: InputMaybe<Scalars['String']['input']>;
  hydrantFlowTestResultsResidual?: InputMaybe<Scalars['String']['input']>;
  hydrantFlowTestResultsStatic?: InputMaybe<Scalars['String']['input']>;
  improvementsValue?: InputMaybe<Scalars['Float']['input']>;
  inRackSprinklerType?: InputMaybe<Scalars['String']['input']>;
  interiorPartitions?: InputMaybe<Scalars['String']['input']>;
  inventoryValue?: InputMaybe<Scalars['Float']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  landslideSusceptibility?: InputMaybe<Scalars['String']['input']>;
  lastEngineeringVisitDate?: InputMaybe<Scalars['Date']['input']>;
  lastValuationDate?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  leasedOrOwned?: InputMaybe<Scalars['String']['input']>;
  lightningHazardClass?: InputMaybe<Scalars['String']['input']>;
  liquefactionSusceptibility?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  lossEngineeringReportPresent?: InputMaybe<Scalars['String']['input']>;
  machineryValue?: InputMaybe<Scalars['Float']['input']>;
  miscBuildingReplacementCostValue?: InputMaybe<Scalars['Float']['input']>;
  miscBusinessInterruptionValue?: InputMaybe<Scalars['Float']['input']>;
  miscContentsValue?: InputMaybe<Scalars['Float']['input']>;
  modelingAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  multiTenant?: InputMaybe<Scalars['Boolean']['input']>;
  naturalGasAutomaticShutoff?: InputMaybe<Scalars['String']['input']>;
  naturalGasPipesSeismicallyBraced?: InputMaybe<Scalars['String']['input']>;
  numberOfBuildings?: InputMaybe<Scalars['Int']['input']>;
  numberOfFireHazardAreas?: InputMaybe<Scalars['Int']['input']>;
  numberOfHighPriorityRecommendations?: InputMaybe<Scalars['Int']['input']>;
  numberOfStoriesAboveGround?: InputMaybe<Scalars['Int']['input']>;
  numberOfStoriesBelowGround?: InputMaybe<Scalars['Int']['input']>;
  numberOfUnits?: InputMaybe<Scalars['Int']['input']>;
  occupancyDescription?: InputMaybe<Scalars['String']['input']>;
  occupancyType?: InputMaybe<Scalars['String']['input']>;
  occupancyTypeATC?: InputMaybe<Scalars['String']['input']>;
  occupancyTypeNAICS?: InputMaybe<Scalars['String']['input']>;
  occupancyTypeSIC?: InputMaybe<Scalars['String']['input']>;
  organizationLevelFour?: InputMaybe<Scalars['String']['input']>;
  organizationLevelOne?: InputMaybe<Scalars['String']['input']>;
  organizationLevelThree?: InputMaybe<Scalars['String']['input']>;
  organizationLevelTwo?: InputMaybe<Scalars['String']['input']>;
  originalAddress?: InputMaybe<Scalars['String']['input']>;
  ornamentation?: InputMaybe<Scalars['String']['input']>;
  otherContentsValue?: InputMaybe<Scalars['Float']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  payrollValue?: InputMaybe<Scalars['Float']['input']>;
  percentageSprinklered?: InputMaybe<Scalars['Float']['input']>;
  permanentFloodMeasuresPresent?: InputMaybe<Scalars['String']['input']>;
  portableFireExtinguisherProgram?: InputMaybe<Scalars['String']['input']>;
  portfolio?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryTenantNAICS?: InputMaybe<Scalars['String']['input']>;
  propertyManager?: InputMaybe<Scalars['String']['input']>;
  propertyName?: InputMaybe<Scalars['String']['input']>;
  propertyStatus?: InputMaybe<Scalars['String']['input']>;
  quakeScenarioEstimatedLoss?: InputMaybe<Scalars['Float']['input']>;
  quakeScenarioUpperLoss?: InputMaybe<Scalars['Float']['input']>;
  rackStoragePresent?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  remoteMonitoringOfSprinklerSystem?: InputMaybe<Scalars['Boolean']['input']>;
  rentAndServiceIndemnityPeriod?: InputMaybe<Scalars['Int']['input']>;
  replacementCostMethodology?: InputMaybe<Scalars['String']['input']>;
  replacementCostPerFloorAreaPartner?: InputMaybe<Scalars['Float']['input']>;
  replacementCostPerFloorAreaPartnerDifference?: InputMaybe<Scalars['Float']['input']>;
  replacementCostPerSquareFootage?: InputMaybe<Scalars['Float']['input']>;
  residentialGarage?: InputMaybe<Scalars['String']['input']>;
  rmsEqAppendagesAndOrnamentation?: InputMaybe<Scalars['String']['input']>;
  rmsEqBaseIsolation?: InputMaybe<Scalars['String']['input']>;
  rmsEqConstructionQuality?: InputMaybe<Scalars['String']['input']>;
  rmsEqContentsRateGradeEQ?: InputMaybe<Scalars['String']['input']>;
  rmsEqCrippleWalls?: InputMaybe<Scalars['String']['input']>;
  rmsEqEarthquakeSprinklerCoverageFlag?: InputMaybe<Scalars['String']['input']>;
  rmsEqEngineeredFoundation?: InputMaybe<Scalars['String']['input']>;
  rmsEqEnvelopeOpening?: InputMaybe<Scalars['String']['input']>;
  rmsEqEquipmentEQBracing?: InputMaybe<Scalars['String']['input']>;
  rmsEqEquipmentSupportMaintenance?: InputMaybe<Scalars['String']['input']>;
  rmsEqExteriorWallsCladding?: InputMaybe<Scalars['String']['input']>;
  rmsEqFrameBoltedtoFoundation?: InputMaybe<Scalars['String']['input']>;
  rmsEqPounding?: InputMaybe<Scalars['String']['input']>;
  rmsEqPurlinAnchoringTiltUpRetrofit?: InputMaybe<Scalars['String']['input']>;
  rmsEqRedundancy?: InputMaybe<Scalars['String']['input']>;
  rmsEqShapeConfiguration?: InputMaybe<Scalars['String']['input']>;
  rmsEqShortColumnCondition?: InputMaybe<Scalars['String']['input']>;
  rmsEqSoftStory?: InputMaybe<Scalars['String']['input']>;
  rmsEqSprinklerLeakageSusceptibility?: InputMaybe<Scalars['String']['input']>;
  rmsEqSprinklerType?: InputMaybe<Scalars['String']['input']>;
  rmsEqStructuralUpgradeNonURM?: InputMaybe<Scalars['String']['input']>;
  rmsEqTank?: InputMaybe<Scalars['String']['input']>;
  rmsEqTorsion?: InputMaybe<Scalars['String']['input']>;
  rmsEqUnreinforcedMasonryPartition?: InputMaybe<Scalars['String']['input']>;
  rmsEqUnreinforcedMasonryRetrofit?: InputMaybe<Scalars['String']['input']>;
  rmsEqVerticalIrregularitySetbackandOverhangs?: InputMaybe<Scalars['String']['input']>;
  rmsModifiers?: InputMaybe<Scalars['String']['input']>;
  rmsOtherBIPreparedness?: InputMaybe<Scalars['String']['input']>;
  rmsOtherBIRedundancy?: InputMaybe<Scalars['String']['input']>;
  rmsOtherPercentageContentsBelowGrade?: InputMaybe<Scalars['Float']['input']>;
  rmsRoofSheathingAttachment?: InputMaybe<Scalars['String']['input']>;
  rmsWindBIDependency?: InputMaybe<Scalars['String']['input']>;
  rmsWindBasementProtection?: InputMaybe<Scalars['String']['input']>;
  rmsWindCauseOfLossFlag?: InputMaybe<Scalars['String']['input']>;
  rmsWindCladding?: InputMaybe<Scalars['String']['input']>;
  rmsWindCommercialAppurtenantStructures?: InputMaybe<Scalars['String']['input']>;
  rmsWindConstructionQuality?: InputMaybe<Scalars['String']['input']>;
  rmsWindContentsRateGradeWind?: InputMaybe<Scalars['String']['input']>;
  rmsWindContentsVulnerabilityDueToFlood?: InputMaybe<Scalars['String']['input']>;
  rmsWindContentsVulnerabilityDueToWind?: InputMaybe<Scalars['String']['input']>;
  rmsWindCopingAndFlashingQuality?: InputMaybe<Scalars['String']['input']>;
  rmsWindFinishedFloorHeightAboveGround?: InputMaybe<Scalars['Float']['input']>;
  rmsWindFinishedFloorHeightMatchLevel?: InputMaybe<Scalars['Float']['input']>;
  rmsWindFirstFloorElevation?: InputMaybe<Scalars['Float']['input']>;
  rmsWindFloodMissiles?: InputMaybe<Scalars['String']['input']>;
  rmsWindFloodProtection?: InputMaybe<Scalars['String']['input']>;
  rmsWindFloorType?: InputMaybe<Scalars['String']['input']>;
  rmsWindFrameFoundationConnection?: InputMaybe<Scalars['String']['input']>;
  rmsWindGroundLevelEquipment?: InputMaybe<Scalars['String']['input']>;
  rmsWindOpeningProtection?: InputMaybe<Scalars['String']['input']>;
  rmsWindResidentialAppurtenantStructures?: InputMaybe<Scalars['String']['input']>;
  rmsWindResistanceDoors?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofAge?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofAnchorage?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofEquipmentWindBracing?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofFramingType?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofGeometry?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofMaintenance?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofParapetsChimneys?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofSystemCovering?: InputMaybe<Scalars['String']['input']>;
  rmsWindVerticalExposureDistribution?: InputMaybe<Scalars['String']['input']>;
  rmsWindWindMissiles?: InputMaybe<Scalars['String']['input']>;
  roofChimneysAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofCopingAndFlashing?: InputMaybe<Scalars['String']['input']>;
  roofDeckAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofDeckType?: InputMaybe<Scalars['String']['input']>;
  roofDescription?: InputMaybe<Scalars['String']['input']>;
  roofDrainageType?: InputMaybe<Scalars['String']['input']>;
  roofEquipmentAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofEstimatedReplacementYear?: InputMaybe<Scalars['Int']['input']>;
  roofGeometry?: InputMaybe<Scalars['String']['input']>;
  roofHailMitigation?: InputMaybe<Scalars['String']['input']>;
  roofImage?: InputMaybe<Scalars['String']['input']>;
  roofInspectionProgram?: InputMaybe<Scalars['String']['input']>;
  roofInstallYear?: InputMaybe<Scalars['Int']['input']>;
  roofParapets?: InputMaybe<Scalars['String']['input']>;
  roofScreensSignsAnchorageandBracing?: InputMaybe<Scalars['String']['input']>;
  roofSolarPanelCoverage?: InputMaybe<Scalars['String']['input']>;
  roofSolarPanelDescription?: InputMaybe<Scalars['String']['input']>;
  roofSolarPanelOwnership?: InputMaybe<Scalars['Float']['input']>;
  roofStructures?: InputMaybe<Scalars['String']['input']>;
  roofSystem?: InputMaybe<Scalars['String']['input']>;
  roofSystemAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofingMaterialAnchorage?: InputMaybe<Scalars['String']['input']>;
  rooftopSolarPanels?: InputMaybe<Scalars['String']['input']>;
  rooftopWaterTanks?: InputMaybe<Scalars['String']['input']>;
  securityDescription?: InputMaybe<Scalars['String']['input']>;
  seismicDesignQuality?: InputMaybe<Scalars['String']['input']>;
  seismicDesignRValue?: InputMaybe<Scalars['Float']['input']>;
  seismicHazardClass?: InputMaybe<Scalars['String']['input']>;
  seismicInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  seismicStatus?: InputMaybe<Scalars['String']['input']>;
  seismicZone?: InputMaybe<Scalars['String']['input']>;
  shortColumnConcrete?: InputMaybe<Scalars['String']['input']>;
  siteSoilClassification?: InputMaybe<Scalars['String']['input']>;
  smokingControlsProgram?: InputMaybe<Scalars['String']['input']>;
  softStory?: InputMaybe<Scalars['String']['input']>;
  sovSortOrder?: InputMaybe<Scalars['Int']['input']>;
  specificOccupancy?: InputMaybe<Scalars['String']['input']>;
  sprinklerHeadSize?: InputMaybe<Scalars['Float']['input']>;
  sprinklerHeadTemperatureRating?: InputMaybe<Scalars['String']['input']>;
  sprinklerLeakDetectionSystem?: InputMaybe<Scalars['Boolean']['input']>;
  sprinklerPipesSeismicallyBraced?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  stockThroughputFactor?: InputMaybe<Scalars['Float']['input']>;
  storageArrangementDescription?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  structuralDescription?: InputMaybe<Scalars['String']['input']>;
  structuralSystemUpgraded?: InputMaybe<Scalars['String']['input']>;
  structuralUpgradeType?: InputMaybe<Scalars['String']['input']>;
  suitabilityOfFireProtectionMeasures?: InputMaybe<Scalars['String']['input']>;
  surfaceRoughnessWind?: InputMaybe<Scalars['String']['input']>;
  tornadoHazardClass?: InputMaybe<Scalars['String']['input']>;
  torsion?: InputMaybe<Scalars['String']['input']>;
  totalInsuredValue?: InputMaybe<Scalars['Float']['input']>;
  treeExposure?: InputMaybe<Scalars['String']['input']>;
  tsunamiHazardClass?: InputMaybe<Scalars['String']['input']>;
  valuationCurrency?: InputMaybe<Scalars['String']['input']>;
  valueInspectionProgram?: InputMaybe<Scalars['String']['input']>;
  verticalIrregularity?: InputMaybe<Scalars['String']['input']>;
  wallCladdingSystem?: InputMaybe<Scalars['String']['input']>;
  waterHeaterBracing?: InputMaybe<Scalars['String']['input']>;
  waterSupplyFlowRate?: InputMaybe<Scalars['String']['input']>;
  waterSupplyTime?: InputMaybe<Scalars['Float']['input']>;
  wildfireHazardClass?: InputMaybe<Scalars['String']['input']>;
  wildfireZone?: InputMaybe<Scalars['String']['input']>;
  windHazardClass?: InputMaybe<Scalars['String']['input']>;
  windMissiles?: InputMaybe<Scalars['String']['input']>;
  windZone?: InputMaybe<Scalars['String']['input']>;
  windowProtection?: InputMaybe<Scalars['String']['input']>;
  windowType?: InputMaybe<Scalars['String']['input']>;
  yearBuilt?: InputMaybe<Scalars['Int']['input']>;
  yearLastUpgraded?: InputMaybe<Scalars['Int']['input']>;
  yearsInPortfolio?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyDataValidationError = {
  __typename?: 'PropertyDataValidationError';
  attributeName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  type: PropertyDataValidationErrorType;
};

export enum PropertyDataValidationErrorType {
  DuplicateClientId = 'DuplicateClientID',
  InvalidData = 'InvalidData',
}

export type PropertyDataValidations = {
  __typename?: 'PropertyDataValidations';
  /**  Unique identifier for the property within job.  */
  jobPropertyID: Scalars['String']['output'];
  /**  Property data before the job. Only available for edit properties jobs.  */
  originalPropertyData?: Maybe<PropertyData>;
  /**  Property data after the job is applied.  */
  submittedPropertyData: PropertyData;
  /**  Data validation errors which are required to be resolved before applying the job.  */
  validationErrors: Array<PropertyDataValidationError>;
  /**  Data validation errors which are optional to resolve before applying the job.  */
  validationWarnings: Array<PropertyDataValidationError>;
};

export type PropertyLock = {
  __typename?: 'PropertyLock';
  /**  Deprecated - use jobInfo field instead  */
  jobs?: Maybe<Array<Job>>;
  /**  Returns true if enclosing property or stream is not available for edits for current user. */
  locked: Scalars['Boolean']['output'];
  /**  Details on locks blocking edits on this property.  */
  locks?: Maybe<Array<Lock>>;
};

export type PropertyPermissionInput = {
  action: Action;
  propertyAIds: Array<Scalars['ArchipelagoID']['input']>;
};

export type PropertyProvenanceInput = {
  attributeComments?: InputMaybe<Array<AttributeCommentsInput>>;
  /**  specifies attribute values which need to be updated  */
  attributeData?: InputMaybe<PropertyDataInput>;
  propertyID: Scalars['ArchipelagoID']['input'];
  /**  Must include one entry for each attribute specified in attributeData  */
  provenance?: InputMaybe<Array<AttributeProvenanceInput>>;
  /**  Deprecated  */
  unsetAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum PropertyStatus {
  /**
   * Active properties can be ongoing or disposed, but still visible to users
   *   with edit permissions
   */
  Active = 'Active',
  /**
   * Deleted properties are only visible to admins and risk managers if explicitely
   *   requested, for example to restore them
   */
  Deleted = 'Deleted',
  /**
   * Disposed properties are not visible in any stream configuration,
   *   but still visible to users with edit permissions
   */
  Disposed = 'Disposed',
  /**  Ongoing properties are visible in default stream configuration  */
  Ongoing = 'Ongoing',
}

export type PropertyStatusInput = {
  archipelagoId: Scalars['String']['input'];
  /**
   * Optional disposition date. Only applies when input status is Disposed
   *   Format must be YYYY-MM-DD
   */
  dispositionDate?: InputMaybe<Scalars['String']['input']>;
};

export type PropertyValidationErrors = {
  __typename?: 'PropertyValidationErrors';
  archipelagoID: Scalars['String']['output'];
  errors?: Maybe<Array<ValidateError>>;
};

/**
 * Assume properties A, B and C has following linked documents
 * A - d1, d2, d3
 * B - d1, d5
 * C - d1, d3
 * When property A is selected, then
 * ProvenanceJobData.sharedDocuments = [d1]
 * A.sharedWithPartialGroupDocuments = [d3]
 * A.exclusiveDocuments = [d2]
 *
 * ProvenanceJobData.sharedDocuments = [d1]
 * B.sharedWithPartialGroupDocuments = []
 * B.exclusiveDocuments = [d5]
 *
 * ProvenanceJobData.sharedDocuments = [d1]
 * C.sharedWithPartialGroupDocuments = [d3]
 * C.exclusiveDocuments = []
 */
export type PropertyWithLinkedDocuments = {
  __typename?: 'PropertyWithLinkedDocuments';
  attributeComments?: Maybe<Array<AttributeComments>>;
  /**  list of Documents exclusive to individual property  */
  exclusiveDocuments?: Maybe<Array<Scalars['ID']['output']>>;
  linkedDocuments?: Maybe<Array<Document>>;
  property: Property;
  /**  list of Documents shared by some properties of the group  */
  sharedWithPartialGroupDocuments?: Maybe<Array<Scalars['ID']['output']>>;
  /** lists all attributes to be unset as part of current job */
  unsetAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

/**  Provenance describes the source of an attribute's value. Holds a collection of links to documents that refer to this attribute  */
export type Provenance = {
  __typename?: 'Provenance';
  /**  Name of the attribute for which this evidence applies. */
  attributeName: Scalars['String']['output'];
  /**  URL for if the source is not a document but a website  */
  externalSourceURLs?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  organizationID: Scalars['ID']['output'];
  /**  Archipelago ID of the property  */
  propertyID: Scalars['ID']['output'];
  /**  Collection of documents that belong to this property and attributeName  */
  sourceDocuments?: Maybe<Array<DocumentLink>>;
  /**  Text to describe source of attribute. Any formatting should use markdown syntax.  */
  sources?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProvenanceJobData = {
  __typename?: 'ProvenanceJobData';
  properties: Array<PropertyWithLinkedDocuments>;
  /**  list of Documents shared by all properties of the group. Please see example in PropertyWithLinkedDocuments  */
  sharedDocuments?: Maybe<Array<Scalars['ID']['output']>>;
};

export type ProvenanceJobDataInput = {
  jobID: Scalars['ID']['input'];
  properties: Array<PropertyProvenanceInput>;
};

export type Query = {
  __typename?: 'Query';
  /**  Returns cleansed sov documents for the given orgName.  */
  cleansedSovDocuments: Array<Document>;
  /**  Returns user profiles for users who can be assigned to jobs for specified orgName.  */
  dataEngineers: Array<UserProfile>;
  /** Deprecated. Use domainsV2 instead */
  domains?: Maybe<Array<Domain>>;
  /** return a list of domains related to the organization */
  domainsV2?: Maybe<Array<Domain>>;
  /**
   * Returns a worksheet for fixing add and edit properties job validation errors.
   * After fixing validation errors in the the worksheet, use updateJobPropertyData to validate the edits.
   */
  downloadJobPropertyValidationsWorksheet: DownloadJobPropertyValidationsWorksheetOutput;
  /**
   * Returns a worksheet for adding/editing properties or an error if the worksheet is not ready yet.
   * After adding/editing property data the worksheet, use validateJobWorksheet to validate.
   * A job must first be initialized using init<Add|Edit>PropertiesJob.
   */
  downloadJobWorksheet: DownloadJobWorksheetOutput;
  /** Returns the current geo job data stored for the provided job ID. Expects the provided job is of type Geo. */
  geoJobData: GeoJobData;
  job?: Maybe<Job>;
  /**
   * Returns a page of suggested addresses for properties submitted in an add or edit properties job.
   * Addresses are only returned for properties which have a submitted address that differs from a suggested address.
   */
  jobAddressSuggestionsPage: JobAddressSuggestionsPageOutput;
  /**
   * Returns a page of data validation results for properties validated in an add or edit properties job.
   * Errors must be corrected using updateJobPropertyData before adds/edits can be applied.
   */
  jobPropertyDataValidationsPage: JobPropertyDataValidationsPageOutput;
  /**
   * Return number of jobs requested. Results are filtered by userID, projectName, orgName, jobsIDs and sorted
   *       by their due date.
   */
  jobsV2?: Maybe<JobsPage>;
  /**  Returns loss info required for loss edit jobs processing.  */
  lossesForEnrichment?: Maybe<Array<Loss>>;
  /** Returns attribute info related to Occupancy & Construction, for a OC job. */
  ocAttributes?: Maybe<OcAttributesResponse>;
  /** Returns attribute info needed to calculate ML metrics, for a OC job. */
  ocMetrics?: Maybe<OcMetricsResponse>;
  /**
   * Occupancy or Construction information at property level. Returns one OCInfo object per property.
   *     In response to this query, the property group size('propertyCount') returned is always one.
   */
  ocProperties: OcPropertyGroupsResponse;
  /**
   * Returns property groups based on Occupancy and Construction information, grouped by original value,
   * suggested value & no. of stories.
   * 'attributeType' field determines if this query returns Occupancy or Construction information.
   */
  ocPropertyGroups: OcPropertyGroupsResponse;
  /**
   * Deprecated. Use sovMappingsV2 instead.
   * Returns a list of the original mappings for the raw SOV specified by the document ID
   */
  originalSOVMappings: SovMappingsResponse;
  /**
   * Returns project for the given orgName and projectName or projectID.
   * One of projectName or projectID must be provided. If both are specified, projectName is used.
   */
  project?: Maybe<Project>;
  /**
   * Returns projects for the given orgName.
   *       If orgName is not provided, returns all projects across all organizations accessible to current user.
   */
  projects?: Maybe<Array<Project>>;
  /**
   * Returns property info required for jobs processing. User must have permission to process jobs for the specified org.
   *   If projectName is provided return properties for the given project, if not return properties for current project.
   */
  propertiesForEnrichment?: Maybe<Array<Property>>;
  /**
   *  Returns property info required for loss add/edit jobs processing. User must have permission to process jobs for the specified org.
   * If projectName is provided return properties for the given project, if not return properties for current project.
   * Query response includes disposed properties
   */
  propertiesForLosses?: Maybe<Array<Property>>;
  /**  Returns current unsubmitted working copy of provenance job data  */
  provenanceJobData: ProvenanceJobData;
  /**
   * Returns attribute value and document recommendations for the given job and attribute.
   * Documents have to be linked to the job properties to be searched.
   * Search uses property documents (like PCA, Roof Inspection, Loss Engineering Report...) for value recommendations.
   */
  recommendAttributeValues?: Maybe<AttributeRecommendationsOutput>;
  /** Returns the reverse geocoded property data for the provided location */
  reverseGeocode: PropertyData;
  /**  Check revert job progress. */
  revertJobProgress?: Maybe<Job>;
  /**  Get progress of set hazards request. */
  setHazardsProgress?: Maybe<SetHazardsProgress>;
  /**  Returns sov documents for the given orgName.  */
  sovDocuments: Array<Document>;
  /**
   * Deprecated. Use sovMappingsV2 instead.
   * Returns a list of the current mappings for the raw SOV specified by the document ID
   */
  sovMappings: SovMappingsResponse;
  /** Returns a list of the current mappings for the raw SOV associated to the specified job. */
  sovMappingsV2: SovMappingsResponse;
  /**
   * Validates property attribute values for Manage Properties. This API does not require an existing job to use.
   * Duplicate Client ID checks are the only validations run currently.
   */
  validateMPData: ValidateMpDataOutput;
  /**  Returns rule validation errors for list of properties. */
  validatePropertiesInJob?: Maybe<ValidatePropertiesInJobOutput>;
  /**
   * Deprecated. Use validatePropertiesInJob instead.
   * Returns rule validation errors for the given property.
   */
  validatePropertyInJob?: Maybe<ValidatePropertyInJobOutput>;
};

export type QueryCleansedSovDocumentsArgs = {
  orgName: Scalars['String']['input'];
};

export type QueryDataEngineersArgs = {
  orgName: Scalars['String']['input'];
};

export type QueryDomainsArgs = {
  orgID: Scalars['ID']['input'];
};

export type QueryDomainsV2Args = {
  orgName: Scalars['String']['input'];
};

export type QueryDownloadJobPropertyValidationsWorksheetArgs = {
  input: DownloadJobPropertyValidationsWorksheetInput;
};

export type QueryDownloadJobWorksheetArgs = {
  input: DownloadJobWorksheetInput;
};

export type QueryGeoJobDataArgs = {
  jobID: Scalars['ID']['input'];
};

export type QueryJobArgs = {
  jobID: Scalars['ID']['input'];
};

export type QueryJobAddressSuggestionsPageArgs = {
  input: JobAddressSuggestionsPageInput;
};

export type QueryJobPropertyDataValidationsPageArgs = {
  input: JobPropertyDataValidationsPageInput;
};

export type QueryJobsV2Args = {
  filters?: InputMaybe<JobsFilter>;
  pageCursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryLossesForEnrichmentArgs = {
  input: LossesForEnrichmentInput;
};

export type QueryOcAttributesArgs = {
  input: OcAttributesInput;
};

export type QueryOcMetricsArgs = {
  input: OcMetricsInput;
};

export type QueryOcPropertiesArgs = {
  input: OcPropertiesInput;
};

export type QueryOcPropertyGroupsArgs = {
  input: OcPropertyGroupsInput;
};

export type QueryOriginalSovMappingsArgs = {
  documentID: Scalars['ID']['input'];
};

export type QueryProjectArgs = {
  orgName: Scalars['String']['input'];
  projectID?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProjectsArgs = {
  orgName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPropertiesForEnrichmentArgs = {
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgName: Scalars['String']['input'];
  projectName?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Array<Sort>>;
};

export type QueryPropertiesForLossesArgs = {
  input: PropertiesForLossesInput;
};

export type QueryProvenanceJobDataArgs = {
  jobID: Scalars['ID']['input'];
};

export type QueryRecommendAttributeValuesArgs = {
  input: AttributeRecommendationsInput;
};

export type QueryReverseGeocodeArgs = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type QueryRevertJobProgressArgs = {
  input: RevertJobInput;
};

export type QuerySetHazardsProgressArgs = {
  input: SetHazardsProgressInput;
};

export type QuerySovDocumentsArgs = {
  orgName: Scalars['String']['input'];
};

export type QuerySovMappingsArgs = {
  documentID: Scalars['ID']['input'];
};

export type QuerySovMappingsV2Args = {
  input: SovMappingsV2Input;
};

export type QueryValidateMpDataArgs = {
  input: ValidateMpDataInput;
};

export type QueryValidatePropertiesInJobArgs = {
  input: ValidatePropertiesInJobInput;
};

export type QueryValidatePropertyInJobArgs = {
  input: ValidatePropertyInJobInput;
};

export type RejectJobInput = {
  comment: Scalars['String']['input'];
  jobID: Scalars['ID']['input'];
};

export type ReleasePropertyLocksInput = {
  /**  Only locks with this group ID will be released.  */
  groupID: Scalars['String']['input'];
  /**  Only locks with this organization ID will be released.  */
  orgID: Scalars['ID']['input'];
};

export type ReleasePropertyLocksResponse = {
  __typename?: 'ReleasePropertyLocksResponse';
  /** IDs of properties which have had attribute locks released. */
  propertyIDs: Array<Scalars['ArchipelagoID']['output']>;
};

export type RemoveAllScopesInput = {
  principalId: Scalars['String']['input'];
  role: Role;
  scopeType: ScopeType;
};

export type RemoveOrgFromDomainInput = {
  domain: Scalars['String']['input'];
  orgID: Scalars['ID']['input'];
};

export type RemoveOrgFromDomainInputV2 = {
  domain: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type RemoveScopesInput = {
  principalId: Scalars['String']['input'];
  role: Role;
  scopeType: ScopeType;
  /**  Empty values are not allowed  */
  scopeValues: Array<Scalars['String']['input']>;
};

export type RenewPropertyLocksInput = {
  /**
   * The number of hours from the current time at which the locks will expire.
   * A maximum of 336 hours may be specified.
   */
  expireInHours: Scalars['Int']['input'];
  /**  Only locks with this group ID will be updated.  */
  groupID: Scalars['String']['input'];
  /**  Only locks with this organization ID will be updated.  */
  orgID: Scalars['ID']['input'];
};

export type RenewPropertyLocksResponse = {
  __typename?: 'RenewPropertyLocksResponse';
  /** IDs of properties which have had attribute locks updated. */
  propertyIDs: Array<Scalars['ArchipelagoID']['output']>;
};

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ResourceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type: ResourceType;
};

export enum ResourceType {
  /**
   * Represents documents owned by current principal within assigned scopes.
   *     * Create Action on either this or Document
   *     resource type, grants user permission to upload documents.
   *     * View/List/Edit action on this resource gives permission to a user to only view/list documents uploaded by them.
   */
  AssignedDocument = 'AssignedDocument',
  /** A data job, as defined by the Jobs service, assigned to the current user. */
  AssignedJob = 'AssignedJob',
  /** Underwriting Assessment Report - Carrier Section */
  CarrierAssessment = 'CarrierAssessment',
  ConsumerAttributes = 'ConsumerAttributes',
  /**  Represents all documents in assigned scopes.  */
  Document = 'Document',
  /** A data job as defined by the Jobs service. */
  Job = 'Job',
  Loss = 'Loss',
  /** My Properties stream. */
  MpStream = 'MPStream',
  Org = 'Org',
  Policy = 'Policy',
  /** A project as defined by the Jobs service. */
  Project = 'Project',
  Property = 'Property',
  /** Underwriting Assessment Report - Risk Manager Section */
  RiskManagerAssessment = 'RiskManagerAssessment',
  Stream = 'Stream',
  /**
   *  Access to reports which are available for Stream Owners as part of their work on the Stream/Org,
   * e.g. Data Quality and Enrichment.
   */
  StreamOwnerReports = 'StreamOwnerReports',
  Submission = 'Submission',
  /** Permissions to see PII data related to users (from any Org) who accessed/viewed the Stream */
  UserAccessData = 'UserAccessData',
  VirtualInspection = 'VirtualInspection',
}

export type RetryJobInput = {
  jobID: Scalars['ID']['input'];
};

export type RevertJobInput = {
  jobID: Scalars['ID']['input'];
};

export enum Role {
  /** A user who has access to all the jobs and can see the jobs assigned to all users. */
  DataManager = 'DataManager',
  /** User with read and write permissions on documents only. */
  DocUploader = 'DocUploader',
  /** This role inherits the permissions of a Risk Manager and allows view access to user data */
  LeadRiskManager = 'LeadRiskManager',
  /**
   * PropertyViewer + ability to view property data.
   * The subset of properites allowed is specified using ResourceIds in the assignment filter.
   */
  PropertyEditor = 'PropertyEditor',
  /**
   * A user who can view and manage documents for specific set of properties in an org. This user
   * cannot edit property data.
   */
  PropertyViewer = 'PropertyViewer',
  /**
   *  This role represents a admin user for a Risk Manager organization. Currently allows document view, updates & uploads to an org.
   * In future this will allow read/write accesss to all streams, properties in the assigned organizations
   */
  RiskManager = 'RiskManager',
  /**
   * A user who has permission to view a stream and its constituent properties and documents.
   * This role can only be assigned at with scope type StreamSlug
   */
  StreamViewer = 'StreamViewer',
  /**
   *  A user who has access to read and update all submissions to a carrier organization.
   * Assigned at scope OrgId scopes and no filters.
   */
  Underwriter = 'Underwriter',
  /** Grants permissions to administer user managers and users who can view streams. */
  UserAdmin = 'UserAdmin',
  /** Grants permissions to manage which users can view streams. */
  UserManager = 'UserManager',
}

export type RoleAssignmentV2 = {
  __typename?: 'RoleAssignmentV2';
  principalId: Scalars['String']['output'];
  principalType: PrincipalType;
  role: Role;
  scopes: Array<Scope>;
};

export type RoleAssignmentsResponse = {
  __typename?: 'RoleAssignmentsResponse';
  assignments?: Maybe<Array<RoleAssignmentV2>>;
  /** Any assigned global legacy role */
  legacyRole?: Maybe<Scalars['String']['output']>;
  /** Org name for this user's primary organization, if assigned. */
  primaryOrgName?: Maybe<Scalars['String']['output']>;
};

export type RoleFilter = {
  __typename?: 'RoleFilter';
  description: Scalars['String']['output'];
  predicates?: Maybe<Array<AttributeFilterOutput>>;
  resourceType: ResourceType;
  resources?: Maybe<Array<Resource>>;
};

export type RoleFilterInput = {
  description: Scalars['String']['input'];
  predicates?: InputMaybe<Array<AttributeFilter>>;
  resource: ResourceType;
  /** Support for predicates and/or filters is dependent on the role being assigned. See `#Role` */
  resourceIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum RuleTypeEnum {
  MappingRule = 'MappingRule',
  ValidateRule = 'ValidateRule',
}

export type SovMapping = {
  __typename?: 'SOVMapping';
  /** The schema attribute name for the mappings */
  attributeName: Scalars['String']['output'];
  /** The columns in the raw SOV that are mapped to the schema attribute */
  sourceColumns?: Maybe<Array<SovSourceColumn>>;
};

export type SovMappingInput = {
  /** The schema attribute name for the mappings */
  attributeName: Scalars['String']['input'];
  /** The columns in the raw SOV that are mapped to the schema attribute */
  sourceColumns?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SovMappingsResponse = {
  __typename?: 'SOVMappingsResponse';
  /** The set of column names in the raw SOV that are empty */
  emptyColumns?: Maybe<Array<SovSourceColumn>>;
  /** The currently applied mappings for the SOV */
  mappings?: Maybe<Array<SovMapping>>;
  /** The set of column names in the raw SOV that we were unable to determine mappings for */
  unmappedColumns?: Maybe<Array<SovSourceColumn>>;
};

export type SovMappingsV2Input = {
  jobID: Scalars['String']['input'];
  /** Set to true to get original mappings. Default returns current mappings. */
  originalMappings?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SovSourceColumn = {
  __typename?: 'SOVSourceColumn';
  /** The alphabetical index of the cell column - for example 'A', 'AB', etc */
  excelColumn: Scalars['String']['output'];
  /** Text in the first header row for the column */
  name: Scalars['String']['output'];
};

/**
 * Assignment scope with resource filters.
 * Example: {"type": "OrgId", "value": "fea8300e-045a-11ec-be21-679a2eb8eb54", "name": "Acme Corp."}
 */
export type Scope = {
  __typename?: 'Scope';
  /**  If true, assignment applies to all values of this scope type & `value` attribute should be ignored.  */
  allValues: Scalars['Boolean']['output'];
  filters?: Maybe<Array<RoleFilter>>;
  name?: Maybe<Scalars['String']['output']>;
  type: ScopeType;
  value: Scalars['String']['output'];
};

export type ScopeInput = {
  type: ScopeType;
  /**  Empty values are not allowed  */
  value: Scalars['String']['input'];
};

export enum ScopeType {
  OrgId = 'OrgId',
  StreamSlug = 'StreamSlug',
}

export type Scopes = {
  __typename?: 'Scopes';
  /**
   * Names of scope values at the respective indices in values array.
   * Any orgs or streams which are not found are returned with empty names.
   */
  names: Array<Scalars['String']['output']>;
  type: ScopeType;
  values: Array<Scalars['String']['output']>;
};

export type SecondaryModifier = {
  __typename?: 'SecondaryModifier';
  codeName?: Maybe<Scalars['String']['output']>;
  coding?: Maybe<Scalars['String']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
};

export type SectionReference = {
  __typename?: 'SectionReference';
  /**
   * This stores a string used to describe the specific section of the document which was used
   *   to source the provenance.
   *   For example, this is used by iPad app to indicate that the provenance contains an in-line reference into a document
   *   iPad format: {"x": float, "y": float, "width": float, "height": float, "length": int, "content": string
   *   "pageNumber": int, "startIndex": int, "extractorVersion": int}
   */
  documentSection?: Maybe<Scalars['String']['output']>;
  provenanceID: Scalars['ID']['output'];
};

export type SetHazardsInput = {
  orgName: Scalars['String']['input'];
  /**  Default set archipelago wind/flood/seismic zone hazards only. */
  primaryHazardsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetHazardsProgress = {
  __typename?: 'SetHazardsProgress';
  orgName: Scalars['String']['output'];
  propertyCheckpoint: Scalars['Int']['output'];
  status: HazardsStatus;
};

export type SetHazardsProgressInput = {
  orgName: Scalars['String']['input'];
};

export type SetPropertiesStatusInput = {
  /**  Optional job id used as a reference for property updates  */
  jobId?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  properties: Array<PropertyStatusInput>;
  status: PropertyStatus;
  /**  Optional user id to perform this action on behalf of. If not provided, the current user is used.  */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SetPropertiesStatusResponse = {
  __typename?: 'SetPropertiesStatusResponse';
  /**  Number of properties updated  */
  count: Scalars['Int']['output'];
};

export type Snapshot = {
  __typename?: 'Snapshot';
  bound: Scalars['Boolean']['output'];
  date: Scalars['Time']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Optional date to display instead of the actual date in the UI */
  displayDate?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  /** ID of a project associated with the snapshot */
  projectID?: Maybe<Scalars['ID']['output']>;
  /** Flag to indicate whether or not snapshot was created by SOV */
  type?: Maybe<Scalars['Boolean']['output']>;
  /** If set to false, non admin users should never have access to the snapshot */
  visible: Scalars['Boolean']['output'];
};

export enum SnapshotType {
  /**  Indicates snapshot was created by SOV  */
  Sov = 'SOV',
  /**  Standard snapshot  */
  Standard = 'STANDARD',
  /**  Indicates snapshot was created following a valuation integration run  */
  Valuation = 'VALUATION',
}

export type SoftMapping = {
  __typename?: 'SoftMapping';
  modelSchema: SoftMappingModelSchema;
  secondaryModifier: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum SoftMappingModelSchema {
  Air = 'AIR',
  Arch = 'ARCH',
  Rms = 'RMS',
}

export type Sort = {
  attributeName: Scalars['String']['input'];
  order: SortOrder;
};

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type StartAddLossesJobInput = {
  orgName: Scalars['String']['input'];
  projectName?: InputMaybe<Scalars['String']['input']>;
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type StartAddLossesJobOutput = {
  __typename?: 'StartAddLossesJobOutput';
  jobID: Scalars['String']['output'];
  worksheet: Worksheet;
};

export type StartEditLossesJobInput = {
  /** List of loss attributes to lock for editing. If not specified, the entire worksheet is editable */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  orgName: Scalars['String']['input'];
  projectName?: InputMaybe<Scalars['String']['input']>;
  /** List of property ArchipelagoIDs which will have attributes locked for editing by this job. */
  propertyArchipelagoIDs: Array<Scalars['ArchipelagoID']['input']>;
};

export type StartEditLossesJobOutput = {
  __typename?: 'StartEditLossesJobOutput';
  jobID: Scalars['String']['output'];
  worksheet: Worksheet;
};

export type StartEditPropertiesJobInput = {
  /**  List of attributes to lock for editing.  */
  attributeNames: Array<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  /**  List of property ArchipelagoIDs which will have attributes locked for editing by this job.  */
  propertyIDs: Array<Scalars['ArchipelagoID']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type StartEditPropertiesJobOutput = {
  __typename?: 'StartEditPropertiesJobOutput';
  jobID: Scalars['String']['output'];
  worksheet: Worksheet;
};

export type StartProvenanceEnrichmentInput = {
  doerUserID: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  projectName: Scalars['String']['input'];
  /**  properties subject of this job.  */
  propertyIDs: Array<Scalars['ArchipelagoID']['input']>;
  reviewerUserID: Scalars['String']['input'];
};

export type StateTransition = {
  __typename?: 'StateTransition';
  /**  User action which triggered this state transition. This value will be null for internal transitions.  */
  action?: Maybe<StateTransitionAction>;
  /**  Any user comments related to this state change.  */
  comment?: Maybe<Scalars['String']['output']>;
  /**
   * Errors in data input for current job. Contains one item per property attribute.
   *   Data errors are sorted by type in the following order:
   *   DuplicateColumnHeader, UnknownColumnHeader, InvalidPropertyIDs, InvalidDataFormat.
   *   Other types are returned last and are not sorted
   */
  dataErrors?: Maybe<Array<DataError>>;
  /**  This field is empty until the transition completes  */
  endTime?: Maybe<Scalars['Time']['output']>;
  /** Deprecated. use dataErrors instead. This field will always return an empty list.  */
  errors?: Maybe<Array<DataError>>;
  /**  This field is calculated based on the number of properties  */
  estimatedCompletionTime?: Maybe<Scalars['Time']['output']>;
  from: JobStatus;
  id: Scalars['ID']['output'];
  /**  Progress state of this transition.  */
  progress: Status;
  startTime: Scalars['Time']['output'];
  summary?: Maybe<PropertyCount>;
  /**  Unexpected system errors while processing a job step. Contains one item per property.  */
  systemErrors?: Maybe<Array<SystemError>>;
  to: JobStatus;
  /**  Indicates property data which might be incorrect for review and updates. Updates are not required for the job to be applied.  */
  warnings?: Maybe<Array<DataError>>;
  /**  Job worksheet document associated with this transition.  */
  worksheet?: Maybe<Worksheet>;
};

export enum StateTransitionAction {
  Approve = 'Approve',
  Create = 'Create',
  RequestChanges = 'RequestChanges',
  Retry = 'Retry',
  Revert = 'Revert',
  Start = 'Start',
  Submit = 'Submit',
}

export enum Status {
  Completed = 'Completed',
  Failed = 'Failed',
  Started = 'Started',
  TimedOut = 'TimedOut',
}

export type Stream = {
  __typename?: 'Stream';
  /** List of all explorer names for this stream. */
  allExplorers: Array<Scalars['String']['output']>;
  /** List of enabled exports. */
  allowedExports?: Maybe<Array<ExportCode>>;
  broker?: Maybe<Scalars['String']['output']>;
  businessType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Time']['output']>;
  /**
   * Name of the default snapshot the stream will load, depending on the user permission.
   * If the user is an admin, this field should be empty, if not, it should be the latest snapshot
   * that user is allowed to see.
   */
  defaultSnapshot?: Maybe<Scalars['String']['output']>;
  /**
   * Three character code (ISO_4217) to override the currency formatting per organization. E.g. USD, EUR, ...
   * If ZZZ then currencies are rendered as unit-less.
   */
  displayCurrency?: Maybe<Scalars['String']['output']>;
  documentsCount?: Maybe<Scalars['Int']['output']>;
  effectiveDate?: Maybe<Scalars['Int']['output']>;
  /** List of enabled features */
  enabledFeatures?: Maybe<Array<Scalars['String']['output']>>;
  expiryDate?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  /** Indicates if a stream is a My Properties stream. */
  isMyProperties: Scalars['Boolean']['output'];
  isPublic: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  propertiesCount?: Maybe<Scalars['Int']['output']>;
  /** Customized value for "Last Bound" property status label for this stream. */
  propertyStatusLabel?: Maybe<Scalars['String']['output']>;
  reit?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  snapshots?: Maybe<Array<Snapshot>>;
  status?: Maybe<Scalars['String']['output']>;
  totalInsuredValue?: Maybe<Scalars['Int']['output']>;
  updateStatus?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Time']['output']>;
};

export type StreamGroup = {
  __typename?: 'StreamGroup';
  group?: Maybe<Scalars['String']['output']>;
  stream: Stream;
};

export type StreamPermissions = {
  __typename?: 'StreamPermissions';
  /** Returns true if current user has permission to add losses on this stream. */
  canAddLosses: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to add or dispose properties on this stream. */
  canAddProperties: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to edit any losses on this stream. */
  canEditLosses: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to edit any properties on this stream. */
  canEditProperties: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to export losses on this stream */
  canExportLosses: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to export policies on this stream */
  canExportPolicies: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to manage the domain allowlist */
  canManageDomainAllowList: Scalars['Boolean']['output'];
  /** Returns true if current user has permisssion to manager User Managers for the stream */
  canManageUserManagers: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to manage view access for other users */
  canManageViewers: Scalars['Boolean']['output'];
  /** Returns true if current user has permission to add documents on this stream */
  canUpload: Scalars['Boolean']['output'];
  /** Returns true if the current user has permission to view losses on this stream */
  canViewLosses: Scalars['Boolean']['output'];
};

export type StreamResponse = {
  __typename?: 'StreamResponse';
  /** Edit in progress by current or other user. */
  lock?: Maybe<PropertyLock>;
  permissions: StreamPermissions;
  stream: Stream;
};

export type SubmitAddLossesJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['String']['input'];
  /** Worksheet containing new losses. */
  worksheet: Scalars['Upload']['input'];
};

export type SubmitAddLossesJobOutput = {
  __typename?: 'SubmitAddLossesJobOutput';
  transitionID: Scalars['ID']['output'];
};

export type SubmitEditLossesJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['String']['input'];
  /** Worksheet containing edits. */
  worksheet: Scalars['Upload']['input'];
};

export type SubmitEditLossesJobOutput = {
  __typename?: 'SubmitEditLossesJobOutput';
  transitionID: Scalars['ID']['output'];
};

export type SubmitEditPropertiesJobInput = {
  jobID: Scalars['String']['input'];
  /**  Worksheet containing edits.  */
  worksheet: Scalars['Upload']['input'];
};

export type SubmitEditPropertiesJobOutput = {
  __typename?: 'SubmitEditPropertiesJobOutput';
  transitionID: Scalars['ID']['output'];
};

export type SubmitJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /**  Deprecated. Used by VI jobs to indicate list of doc types to include in followup email template.   */
  followUpDocClassifications?: InputMaybe<Array<Scalars['String']['input']>>;
  jobID: Scalars['String']['input'];
  /**
   * Deprecated. Use initEditPropertyJob and applyEditPropertyJob instead.
   * Used for edit property jobs. New values for partial property update.
   */
  propertyData?: InputMaybe<PropertyDataInput>;
  /**
   * Required for Enrichment & Ingest jobs. For ColumnMapping job, if not specified the service
   *   confirms that skeletonSov from updated mapping is already created for review.
   */
  worksheet?: InputMaybe<Scalars['Upload']['input']>;
};

export type SubmitOcJobInput = {
  ocJobId: Scalars['String']['input'];
};

export type SubmitProvenanceEnrichmentInput = {
  jobID: Scalars['String']['input'];
};

export type SubmitProvenanceEnrichmentOutput = {
  __typename?: 'SubmitProvenanceEnrichmentOutput';
  transitionID: Scalars['String']['output'];
  validationErrors?: Maybe<Array<ValidateError>>;
};

export type SystemError = {
  __typename?: 'SystemError';
  /**  Archipelago ID when available  */
  archipelagoID?: Maybe<Scalars['String']['output']>;
  /**  location/client ID when available  */
  locationID?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  /**  Opaque reference id for Archipelago support.  */
  referenceID?: Maybe<Scalars['String']['output']>;
  /**  For worksheet based input  */
  rowNumber?: Maybe<Scalars['Int']['output']>;
  /**  For worksheet based input  */
  sheetName?: Maybe<Scalars['String']['output']>;
};

export type UpdateDocumentInput = {
  classification?: InputMaybe<Scalars['String']['input']>;
  documentID: Scalars['ID']['input'];
  fileType?: InputMaybe<FileType>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateJobInput = {
  /**  Attributes to be enriched as part of this job.  */
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  doerUserID?: InputMaybe<Scalars['String']['input']>;
  dueOn?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Optional increment expire in hours. If specified, system adds the specified number of hours,
   * to job's current expiry time (see CreateJobInput).
   * The sum of the remaining hours and the requested increment cannot exceed 2 weeks.
   */
  incrementExpireInHours?: InputMaybe<Scalars['Int']['input']>;
  jobID: Scalars['ID']['input'];
  priority?: InputMaybe<JobPriority>;
  references?: InputMaybe<Array<Scalars['String']['input']>>;
  requesterUserID?: InputMaybe<Scalars['String']['input']>;
  reviewerUserID?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<JobStatus>;
};

export type UpdateJobPropertyAddressesInput = {
  jobID: Scalars['ID']['input'];
  /**  New property address values to submit for the job. Overwrites previous property address values.  */
  propertyAddresses: Array<PropertyAddressInput>;
};

export type UpdateJobPropertyDataInput = {
  jobID: Scalars['ID']['input'];
  /**  Properties to update. Must be provided if worksheet is not. Returns an error if worksheet is also provided.  */
  properties?: InputMaybe<Array<JobPropertyDataInput>>;
  /** Worksheet containing edited property data. Must be provided if properties is not. Returns an error if properties is also provided. */
  worksheet?: InputMaybe<Scalars['Upload']['input']>;
};

export type UpdateOcDataInput = {
  attributeType: OcAttributeType;
  ocJobId: Scalars['ID']['input'];
  ocPropertyIds: Array<Scalars['String']['input']>;
  updatedValue: Scalars['String']['input'];
};

export type UpdateProjectInput = {
  currentName: Scalars['String']['input'];
  /**  UTC date on which this project is due. Value format as YYYY-MM-DD  */
  dueOn?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Effective date, in UTC, for all property changes in this project. Value format as YYYY-MM-DD
   *       If not specified, the change timeframe is assumed to be current.
   */
  effectiveFrom?: InputMaybe<Scalars['Date']['input']>;
  newName?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
};

export type UpdatePropertyMembershipInput = {
  orgID: Scalars['ID']['input'];
  propertyID: Scalars['ID']['input'];
};

export type UpdateProvenanceInput = {
  /** Names of attributes for which provenance update should be updated */
  provenanceAttributes: Array<Scalars['String']['input']>;
  provenanceDocumentId: Scalars['String']['input'];
};

export type UpdateSovMappingsInput = {
  /** The document ID for the SOV to be updated */
  documentID: Scalars['ID']['input'];
  /** The new column mappings for the SOV */
  mappings?: InputMaybe<Array<SovMappingInput>>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  /**
   * The organization for the SOV to be updated. At least one of organizationID or orgName must be specified
   *     If both are, organizationID will be used
   */
  organizationID?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateSovMappingsV2Input = {
  jobID: Scalars['String']['input'];
  /** The new column mappings for the SOV. */
  mappings?: InputMaybe<Array<SovMappingInput>>;
};

export type UpdateStreamMembershipReportDataInput = {
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  jobID: Scalars['String']['input'];
  updatedStreamCount: Scalars['Int']['input'];
};

export type UploadJobDocumentInput = {
  classification?: InputMaybe<Scalars['String']['input']>;
  document: Scalars['Upload']['input'];
  orgID: Scalars['ID']['input'];
  origin: Scalars['String']['input'];
  path: Scalars['String']['input'];
};

export type UserFilter = {
  emailSubstring?: InputMaybe<Scalars['String']['input']>;
  familyNameSubstring?: InputMaybe<Scalars['String']['input']>;
  firstNameSubstring?: InputMaybe<Scalars['String']['input']>;
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  active: Scalars['Boolean']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  email: Scalars['ID']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['Time']['output']>;
  pictureURL?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  userID: Scalars['ID']['output'];
};

export type UserRole = {
  __typename?: 'UserRole';
  profile: UserProfile;
  role: Role;
};

export type UserSystemInfo = {
  __typename?: 'UserSystemInfo';
  consentGiven: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Time']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  intent?: Maybe<Scalars['String']['output']>;
  profile: UserProfile;
  userId: Scalars['String']['output'];
};

export type UsersByRolesInput = {
  /** Set to true to include admins. */
  includeAdmins?: InputMaybe<Scalars['Boolean']['input']>;
  organizationID: Scalars['ID']['input'];
  roles: Array<Role>;
};

export type UsersByRolesResponse = {
  __typename?: 'UsersByRolesResponse';
  adminUsers?: Maybe<Array<UserProfile>>;
  nonAdminUsers?: Maybe<Array<UserRole>>;
};

export type ValidEnumValues = {
  __typename?: 'ValidEnumValues';
  attributeName: Scalars['String']['output'];
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type ValidateEnumValuesInput = {
  enumValues: Array<EnumValueInput>;
  orgName: Scalars['String']['input'];
  /** for invalid enum value, if returnValidEnumValues is true, returns valid enum values */
  returnValidEnumValues?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ValidateEnumValuesResponse = {
  __typename?: 'ValidateEnumValuesResponse';
  /** errorMessage is nil if input enum values are valid */
  errorMessage?: Maybe<Scalars['String']['output']>;
  validEnumValues?: Maybe<Array<ValidEnumValues>>;
};

export type ValidateError = {
  __typename?: 'ValidateError';
  /** The assert expression that raised the error */
  assert: Scalars['String']['output'];
  /** The human readable description of the validation rule for presentation to the user */
  desc?: Maybe<Scalars['String']['output']>;
  /** The list of fields used by the validation rule that raised the error */
  fields?: Maybe<Array<Field>>;
  /** The filter expression for the rule that raised the error */
  filter?: Maybe<Scalars['String']['output']>;
  /** The property on which the validation rule failed */
  property?: Maybe<Property>;
  /** The integer id for the rule that failed */
  ruleId: Scalars['Int']['output'];
  /** The rule revision ID, if it has one */
  ruleRevisionId: Scalars['ID']['output'];
  /** The type of validation error */
  type?: Maybe<ValidateErrorType>;
};

export enum ValidateErrorSeverity {
  Critical = 'Critical',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
}

export enum ValidateErrorType {
  Error = 'ERROR',
  Warning = 'WARNING',
}

export type ValidateFieldInput = {
  name: Scalars['String']['input'];
  value: Scalars['Any']['input'];
};

export type ValidateFilterByLabel = {
  /**  List of label IDs to filter by */
  labelIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ValidateJobPropertyDataInput = {
  jobID: Scalars['ID']['input'];
};

export type ValidateJobWorksheetInput = {
  jobID: Scalars['ID']['input'];
  /**  Worksheet containing new property data.  */
  worksheet: Scalars['Upload']['input'];
};

export type ValidateLabel = {
  __typename?: 'ValidateLabel';
  id: Scalars['ID']['output'];
  /**  Tells whether a rule is internal or not  */
  internal: Scalars['Boolean']['output'];
  /**  Name of the label  */
  name: Scalars['String']['output'];
};

export type ValidateMpDataInput = {
  archipelagoId?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  orgName: Scalars['String']['input'];
  propertyData?: InputMaybe<PropertyDataInput>;
};

export type ValidateMpDataOutput = {
  __typename?: 'ValidateMPDataOutput';
  /**  Validation errors which are required to be fixed to apply an add/edit job  */
  errors: Array<PropertyDataValidationError>;
  /**  Validation errors which are not required to be fixed to apply an add/edit job  */
  warnings: Array<PropertyDataValidationError>;
};

export type ValidatePropertiesByIDsInput = {
  /**  Archipelago ID  */
  archipelagoIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filter?: InputMaybe<ValidateFilterByLabel>;
  overrideValues?: InputMaybe<Array<ValidateFieldInput>>;
};

export type ValidatePropertiesInJobInput = {
  jobID: Scalars['ID']['input'];
  properties?: InputMaybe<Array<ValidatePropertyDataInput>>;
};

export type ValidatePropertiesInJobOutput = {
  __typename?: 'ValidatePropertiesInJobOutput';
  errors?: Maybe<Array<PropertyValidationErrors>>;
};

export type ValidatePropertyByIdInput = {
  /**  Archipelago ID  */
  archipelagoID: Scalars['ID']['input'];
  filter?: InputMaybe<ValidateFilterByLabel>;
  overrideValues?: InputMaybe<Array<ValidateFieldInput>>;
};

export type ValidatePropertyDataInput = {
  archipelagoID: Scalars['String']['input'];
  attributeData?: InputMaybe<PropertyDataInput>;
};

export type ValidatePropertyInJobInput = {
  archipelagoID: Scalars['String']['input'];
  attributeData?: InputMaybe<PropertyDataInput>;
  jobID: Scalars['ID']['input'];
};

export type ValidatePropertyInJobOutput = {
  __typename?: 'ValidatePropertyInJobOutput';
  validationErrors?: Maybe<Array<ValidateError>>;
};

export enum ValidateRuleCategory {
  Code = 'Code',
  DesignConditions = 'DesignConditions',
  ObservedData = 'ObservedData',
}

export type ValidateRuleV2 = {
  __typename?: 'ValidateRuleV2';
  /** Adds a rule category to the rule */
  category?: Maybe<ValidateRuleCategory>;
  /** whether this rule is deleted */
  deleted: Scalars['Boolean']['output'];
  /** The human readable description of the rule */
  desc?: Maybe<Scalars['String']['output']>;
  /** Tells whether a rule is turned on or off. Rules that are turned off are not being executed when validating */
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** The error message that the user would see when this rule is shown */
  errorMessage: Scalars['String']['output'];
  /** The CEL expression that will filter properties on which to apply the rule assertion */
  filter?: Maybe<Scalars['String']['output']>;
  /** System generated identifier of the rule */
  id: Scalars['ID']['output'];
  /** Adds labels to the rule */
  labels?: Maybe<Array<Maybe<ValidateLabel>>>;
  /** When the rule implementation was last changed */
  lastUpdatedAt: Scalars['Time']['output'];
  /** The CEL expression that will be asserted against properties matching the rule filter */
  rule: Scalars['String']['output'];
  /**  Human readable identifier of the rule. Cannot be used as an identifier to perform API operations.  */
  ruleId: Scalars['Int']['output'];
  /** The rule's revision ID (if it has one) */
  ruleRevisionId: Scalars['ID']['output'];
  /** Determines whether the rule is a mapping rule or a validation rule. */
  ruleType?: Maybe<RuleTypeEnum>;
  /** The severity of validation error the rule will raise if a property fails the rule assertion */
  severity?: Maybe<ValidateErrorSeverity>;
  /** Suggestion on how the error can be resolved */
  solution: Scalars['String']['output'];
  /** The type of the validation error the rule will raise if a property fails the rule assertion */
  type?: Maybe<ValidateErrorType>;
};

export type Worksheet = {
  __typename?: 'Worksheet';
  contentType?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  httpURL: Scalars['String']['output'];
  /**  Id for this worksheet document, registered in the document service. */
  id: Scalars['ID']['output'];
  sizeInBytes: Scalars['Int']['output'];
};

export enum WorksheetFormat {
  /**  Default worksheet format. Displays attributes with additional .Suggested and .New columns  */
  MultiColumn = 'MultiColumn',
  SingleColumn = 'SingleColumn',
}

/** Identifies the requester when the request is submitted by a system user on behalf of an actual user. */
export type Requester = {
  email: Scalars['String']['input'];
  userID: Scalars['ID']['input'];
};

export type WriteLinkPropertiesInput = {
  orgName: Scalars['String']['input'];
};

export type WriteLinkPropertiesResponse = {
  __typename?: 'writeLinkPropertiesResponse';
  propertiesNum: Scalars['Int']['output'];
};
