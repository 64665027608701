import React, { useEffect, useState } from 'react';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useAnalyticsConfigQuery } from '@app/graphql/queries/analyticsConfig/__generated__/analyticsConfig.generated';
import { AnalyticsType } from '@app/graphql/types';
import { FEATURE_SPOTLIGHT_REACT_SDK } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { GoodDataIntegration } from '../GoodDataIntegration/GoodDataIntegration';
import GoodDataReactSDKIntegration from '../GoodDataReactSDKIntegration/GoodDataReactSDKIntegration';

export const DashboardV2 = () => {
  const { selectedOrganization } = useUserSession();

  const [selectedDashboard, setSelectedDashboard] = useState(null);

  const { data } = useAnalyticsConfigQuery({
    variables: {
      input: {
        analyticsType: AnalyticsType.Spotlight,
        orgName: selectedOrganization?.name,
      },
    },
  });

  useEffect(() => {
    if (dashboardID) {
      setSelectedDashboard(dashboardID);
    }
  }, [data]);

  if (!data) {
    return null;
  }

  const signedJWT = data?.analyticsConfig?.signedJWT;
  const enterpriseReportingHostURL = data?.analyticsConfig?.enterpriseReportingHostURL;
  const analyticsConfig = data?.analyticsConfig?.analyticsConfig;
  const workspaceID = analyticsConfig?.workspaceID;
  const dashboard = analyticsConfig?.dashboards[0];
  const filterID = analyticsConfig?.dashboards[0].filters[0]?.id;
  const dashboardID = dashboard?.id;

  const isSpotlightReactSDKEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_SPOTLIGHT_REACT_SDK,
  );

  if (!isSpotlightReactSDKEnabled) {
    return (
      <GoodDataIntegration
        setSelectedDashboard={setSelectedDashboard}
        apiToken={signedJWT}
        workspaceID={workspaceID}
        dashboardID={selectedDashboard || dashboardID}
        hostUrl={enterpriseReportingHostURL}
      />
    );
  }

  return (
    <GoodDataReactSDKIntegration
      apiToken={signedJWT}
      workspaceID={workspaceID}
      hostname={enterpriseReportingHostURL}
      dashboardID={dashboardID}
      filterID={filterID}
      orgID={selectedOrganization?.id}
    />
  );
};
