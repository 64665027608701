import {
  BRCComparison,
  BRCSummary,
  PropertyValuationSortAttribute,
  TableSortOptionsType,
} from '../types';

export const formatNumberValueToString = (value: number) =>
  (Math.round(value * 100) / 100).toString();

export const makeBRCComparison = (
  customerValue: number,
  thirdPartyValue: number,
): BRCComparison => ({
  customerValue: formatNumberValueToString(customerValue),
  thirdPartyValue: formatNumberValueToString(thirdPartyValue),
});

export const makeBRCSummary = (overallPercent: number, overallValue: number): BRCSummary => ({
  BRCCost: formatNumberValueToString(overallValue),
  BRCPercent: formatNumberValueToString(overallPercent),
});

export const getSortValue = (option: TableSortOptionsType | PropertyValuationSortAttribute) => {
  switch (option) {
    case TableSortOptionsType.BRCDiffHighLow:
    case TableSortOptionsType.BRCDiffLowHigh:
      return PropertyValuationSortAttribute.BRCPercentDiff;
    case TableSortOptionsType.BRCCurrencyDiffHighLow:
    case TableSortOptionsType.BRCCurrencyDiffLowHigh:
      return PropertyValuationSortAttribute.BRCValueDiff;
    default:
      return option;
  }
};
