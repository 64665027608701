import React from 'react';
import moment from 'moment';
import { EuiAccordion, EuiFlexGroup, EuiFlexItem, EuiLink, EuiSpacer, Icon } from 'ui';
import getExtensionType from '@app/components/DocumentExtension/getExtensionType';
import { Extension } from '@app/components/DocumentExtension/types';
import { Document } from '@app/graphql/precheck/precheck.types';
import { getFileURL } from '@app/utils/download';
import { StyledPanel } from './Documents.emotion';

const svgFile = {
  [Extension.Image]: 'imageFileColor',
  [Extension.Other]: 'fileColor',
  [Extension.PDF]: 'pdfFileColor',
  [Extension.Spreadsheet]: 'excelFileColor',
  [Extension.Word]: 'wordFileColor',
};

export interface DocumentsProps {
  documents: Document[];
}

export const Documents = ({ documents }: DocumentsProps) => {
  if (documents.length === 0) {
    return null;
  }

  return (
    <>
      <EuiSpacer size="l" />
      <EuiAccordion
        id="uploaded-docs"
        buttonProps={{ style: { fontWeight: 600 } }}
        buttonContent={`Uploaded Documents (${documents.length})`}
      >
        {documents.map((doc, i) => (
          <StyledPanel key={i} color="subdued">
            <EuiFlexGroup justifyContent="spaceBetween">
              <EuiFlexItem>
                <EuiFlexGroup alignItems="flexEnd" gutterSize="s">
                  <EuiFlexItem grow={false}>
                    <Icon
                      data-testid="document-extension-file-icon"
                      name={svgFile[getExtensionType(doc.extension || 'pdf')]}
                      size="m"
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiLink target="_blank" href={getFileURL(doc.id)}>
                      {doc.filename}
                    </EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>{moment(doc.createdAt).format('MMM DD, YYYY')}</EuiFlexItem>
            </EuiFlexGroup>
          </StyledPanel>
        ))}
        <EuiSpacer size="s" />
      </EuiAccordion>
    </>
  );
};
