import styled from '@emotion/styled';

export const LabelInfo = styled.div`
  align-items: flex-start;
  display: flex;

  & > span {
    display: flex;
    align-items: flex-start;
  }
`;
