import styled from '@emotion/styled';
import { EuiBadge } from 'ui';

export const DocumentsBadge = styled(EuiBadge)`
  margin-left: 0;
  & > span > span {
    align-items: center;
    display: flex;
  }
`;

export const Label = styled.span`
  margin-right: 8px;
`;

export const Content = styled.span`
  font-weight: 700;
`;
