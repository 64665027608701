import React from 'react';
import { EuiPanel } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { Charts } from '../Charts/Charts';
import { Helmet } from '../Helmet';
import { HUB_LABEL } from '../Layout/SideNav/constants';
import { EnterpriseSpotlightPageHeader } from './EnterpriseSpotlightPage.emotion';

export const EnterpriseSpotlightPage = () => {
  const { selectedOrganization } = useUserSession();

  return (
    <EuiPanel paddingSize="l">
      <Helmet title={HUB_LABEL} />
      <EnterpriseSpotlightPageHeader pageTitle={selectedOrganization?.name} />
      <Charts />
    </EuiPanel>
  );
};
