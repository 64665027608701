import styled from '@emotion/styled';

export const ActionsCellWrapper = styled.div`
  display: flex;
  row-gap: 3px;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderTitle = styled.h2`
  max-width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
