import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import useAxios from 'axios-hooks';
import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiPanel, EuiTitle, Spinner } from 'ui';
import ErrorMessage from '@app/components/ErrorMessage/ErrorMessage';
import { COPILOT_URL } from '@app/config';
import { SourceDocument } from '@app/platform/Inbox/InboxDetailsContent/CopilotReports/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { SourceNav } from './SourceNav';
import { SourcePage } from './SourcePage';
import { NavSettings } from './types';
import { initNavSettings } from './utils';

const Container = styled(EuiPanel)`
  height: 100%;
  overflow-y: auto;
  > .euiFlexGroup {
    height: 100%;
  }
`;

interface Props {
  source: SourceDocument;
}

interface SourceData {
  classification: string;
  docHttpUrl: string;
  documentID: string;
  pageCount: number;
}

export const SourceViewer: React.FC<Props> = ({ source }) => {
  const [navSettings, setNavSettings] = useState<NavSettings>(null);
  const [{ data, loading, error }] = useAxios<SourceData, any>({
    method: 'GET',
    url: `${COPILOT_URL}/sources/${source.document_id}`,
  });

  useEffect(() => {
    if (data) {
      setNavSettings(initNavSettings(data, source.sources));
    }
  }, [JSON.stringify(data)]);

  if (loading || !navSettings) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage title="Could not get source" message={getErrorMessage(error)} />;
  }

  const { classification, docHttpUrl } = data;

  const setPage = (index: number) => {
    setNavSettings({ ...navSettings, currentPageIndex: index });
  };
  return (
    <Container>
      <EuiFlexGroup direction="column">
        <EuiFlexItem grow={false}>
          <EuiTitle size="s">
            <h4>{classification}</h4>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ alignSelf: 'flex-start' }}>
          <EuiLink onClick={() => window.open(docHttpUrl, '_blank')}>
            Download
          </EuiLink>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <SourceNav
            navSettings={navSettings}
            onNextPage={() => setPage(navSettings.currentPageIndex + 1)}
            onPreviousPage={() => setPage(navSettings.currentPageIndex - 1)}
            onNextHighlight={() =>
              setPage(
                navSettings.highlightIndexes.find(
                  (highlightIndex) => highlightIndex > navSettings.currentPageIndex,
                ),
              )
            }
            onPreviousHighlight={() =>
              setPage(
                [...navSettings.highlightIndexes]
                  .reverse()
                  .find((highlightIndex) => highlightIndex < navSettings.currentPageIndex),
              )
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <SourcePage page={navSettings.pages[navSettings.currentPageIndex]} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Container>
  );
};
