import React from 'react';
import {
  Avatar,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  MultiSelect,
  Toggle,
} from 'ui';
import { useTaskContext } from './context/TaskContext';
import { StyledBadge } from './TaskTable.emotion';
import './pill.css';
import { ValuationOutlierReason } from '@app/graphql/precheck/precheck.types';
import { CATReasons } from './context/types';

const catReasons = Object.values(CATReasons);
const outlierReasons = Object.values(ValuationOutlierReason);

export const Filters = () => {
  const { filters } = useTaskContext();

  const searchUsers = (options): void => {
    filters.setFilter(
      'users',
      options.map((option) => option.value),
    );
  };

  const availableReasons = filters.reasonOptions.map((option) => option.value);
  const availableCatReasons = catReasons.filter(reason => availableReasons.includes(reason));
  const availableOutlierReasons = outlierReasons.filter(reason => availableReasons.includes(reason));
  
  const searchReasons = (options): void => {
    let values = [...options.map((option) => option.value)];

    if (!filters.reasons.includes(ValuationOutlierReason.AnyOutlier) && values.includes(ValuationOutlierReason.AnyOutlier)) {
      values = values.filter(reason => reason === ValuationOutlierReason.AnyOutlier || (reason !== ValuationOutlierReason.AnyOutlier && !availableOutlierReasons.includes(reason)));
    }

    if (!filters.reasons.includes(CATReasons.anyCat) && values.includes(CATReasons.anyCat)) {
      values = values.filter(reason => reason === CATReasons.anyCat || (reason !== CATReasons.anyCat && !availableCatReasons.includes(reason)));
    }

    if (values.includes(ValuationOutlierReason.AnyOutlier) && outlierReasons.some(reason => reason !== ValuationOutlierReason.AnyOutlier && values.includes(reason))) {
      const findIndex = values.findIndex(reason => reason === ValuationOutlierReason.AnyOutlier);
      values.splice(findIndex, 1);
    }

    if (values.includes(CATReasons.anyCat) && catReasons.some(reason => reason !== CATReasons.anyCat && values.includes(reason))) {
      const findIndex = values.findIndex(reason => reason === CATReasons.anyCat);
      values.splice(findIndex, 1);
    }

    filters.setFilter(
      'reasons',
      values,
    );
  };

  const onSearch = (value: string) => {
    filters.setFilter('name', value);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  return (
    <EuiFlexGroup gutterSize="m" justifyContent="spaceBetween">
      <EuiFlexItem grow={2}>
        <EuiFieldSearch
          data-testid="task-property-search"
          placeholder="Search properties"
          value={filters.name}
          onChange={onChange}
          onSearch={onSearch}
          isClearable
          fullWidth
        />
      </EuiFlexItem>
      <EuiFlexItem grow={2}>
        <MultiSelect
          key={filters.users.toString()}
          data-testid="task-hazard-filter-select"
          placeholder="All assignees"
          options={filters.userOptions}
          //@ts-ignore
          renderOption={(option, searchValue) => {
            const { label } = option;

            return (
              <EuiFlexGroup gutterSize="s">
                <EuiFlexItem grow={false}>
                  <Avatar name={label} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiHighlight search={searchValue}>{label}</EuiHighlight>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          }}
          onChange={searchUsers}
          initialSelected={filters.userOptions.filter((option) =>
            filters.users.includes(option.value),
          )}
          fullWidth
        />
      </EuiFlexItem>
      <EuiFlexItem grow={4}>
        <MultiSelect
          key={filters.reasons.toString()}
          data-testid="task-reason-filter"
          //@ts-ignore
          renderOption={(option) => <StyledBadge color={option.color}>{option.label}</StyledBadge>}
          placeholder="All reasons"
          options={filters.reasonOptions}
          onChange={searchReasons}
          initialSelected={filters.reasonOptions.filter((option) =>
            filters.reasons.includes(option.value),
          )}
          fullWidth
        />
      </EuiFlexItem>
      <EuiFlexItem style={{ justifyContent: 'center' }} grow={6}>
        <EuiFlexGroup gutterSize="m">
          <EuiFlexItem style={{ justifyContent: 'center' }} grow={2}>
            <Toggle
              label="Show dismissed"
              checked={filters.dismissed}
              name="show pe docs toggle"
              onChange={(event) => {
                filters.setFilter('dismissed', event.target.checked.toString());
              }}
            />
          </EuiFlexItem>
            <EuiFlexItem style={{ justifyContent: 'center' }} grow={5}>
              <Toggle
                label="Assigned to me"
                checked={filters.selfAssigned}
                name="show pe docs toggle"
                onChange={(event) => {
                  filters.setFilter('selfAssigned', event.target.checked.toString());
                }}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
