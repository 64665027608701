import React from 'react';
import { generatePath } from 'react-router';
import { EuiFlexItem, Icon } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useNavContext } from '../SideNavContext';
import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';

export const Precheck = () => {
  const { selectedOrganization } = useUserSession();
  const { permissions } = useNavContext();

  const precheckLink = generatePath('/organizations/:organizationName/precheck', {
    organizationName: selectedOrganization.name,
  });

  if (!permissions.canViewPrecheck) {
    return null;
  }

  return (
    <NavGroup title="PRECHECK">
      <EuiFlexItem grow={1}>
        <NavItem
          testId="header-precheck-tab"
          icon={<Icon name="checkCircle" />}
          link={precheckLink}
          label="Recommendations"
        />
      </EuiFlexItem>
    </NavGroup>
  );
};
