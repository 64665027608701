import React, { Dispatch, SetStateAction } from 'react';
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import UploadPortalContent from './UploadPortalContent';

interface Props {
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  organizationName: string;
  organizationId: string;
}
const UploadModal: React.FC<Props> = ({ setIsModalVisible, organizationId, organizationName }) => (
  <EuiModal onClose={() => setIsModalVisible(false)}>
    <EuiModalHeader>
      <EuiModalHeaderTitle>Upload File</EuiModalHeaderTitle>
    </EuiModalHeader>
    <EuiModalBody>
      <UploadPortalContent
        organizationName={organizationName}
        organizationId={organizationId}
        showTitle
        eventPrefix={'Upload Modal'}
      />
    </EuiModalBody>
    <EuiModalFooter>
      <EuiButton onClick={() => setIsModalVisible(false)} fill>
        Close
      </EuiButton>
    </EuiModalFooter>
  </EuiModal>
);

export default UploadModal;
