import React, { useEffect, useMemo, useState } from 'react';
import tigerFactory, { TigerTokenAuthProvider } from '@gooddata/sdk-backend-tiger';
import {
  areObjRefsEqual,
  IDashboard,
  idRef,
  IFilterContext,
  newPositiveAttributeFilter,
} from '@gooddata/sdk-model';
import {
  BackendProvider,
  useBackendStrict,
  useWorkspaceStrict,
  WorkspaceProvider,
} from '@gooddata/sdk-ui-all';
import {
  changeFilterContextSelection,
  Dashboard,
  isDashboardDrillToDashboardResolved,
  isDashboardInitialized,
} from '@gooddata/sdk-ui-dashboard';
import '@gooddata/sdk-ui-filters/styles/css/main.css';
import '@gooddata/sdk-ui-charts/styles/css/main.css';
import '@gooddata/sdk-ui-geo/styles/css/main.css';
import '@gooddata/sdk-ui-pivot/styles/css/main.css';
import '@gooddata/sdk-ui-kit/styles/css/main.css';
import '@gooddata/sdk-ui-ext/styles/css/main.css';
import '@gooddata/sdk-ui-dashboard/styles/css/main.css';

const isDrillToSameDashboard = (e) =>
  !e.payload.drillDefinition.target ||
  areObjRefsEqual(e.payload.drillDefinition.target, e.ctx.dashboardRef);

// note that if this component is not lazy loaded, it will bring in all the css files above
// of whether or not it is actually rendered, which may impact styling of other components
// since they are global style sheets
export const GoodDataReactSDKIntegration = ({
  apiToken,
  workspaceID,
  dashboardID,
  hostname,
  filterID,
  orgID,
}) => {
  const backend = tigerFactory({
    hostname: hostname || 'https://onarchipelago.cloud.gooddata.com',
  }).withAuthentication(new TigerTokenAuthProvider(apiToken));

  return (
    <BackendProvider backend={backend}>
      <WorkspaceProvider workspace={workspaceID}>
        <CustomDashboard dashboardID={dashboardID} filterID={filterID} orgID={orgID} />
      </WorkspaceProvider>
    </BackendProvider>
  );
};

export default GoodDataReactSDKIntegration;

export const CustomDashboard = ({ dashboardID, filterID, orgID }) => {
  const backend = useBackendStrict();
  const workspace = useWorkspaceStrict();

  const [dashboard, setDashboard] = useState<IDashboard | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const dsb = await backend.workspace(workspace).dashboards().getDashboard(idRef(dashboardID));

      if (filterID && orgID) {
        const newFilter = newPositiveAttributeFilter(filterID, {
          uris: [orgID],
        });

        const effectiveFilters = dsb.filterContext?.filters
          ? // @ts-ignore
            dsb.filterContext?.filters.map(({ attributeFilter }) => {
              if (attributeFilter.displayForm.identifier === filterID) {
                return {
                  attributeFilter: {
                    attributeElements: newFilter.positiveAttributeFilter.in,
                    displayForm: attributeFilter.displayForm,
                    localIdentifier: attributeFilter.localIdentifier,
                    negativeSelection: attributeFilter.negativeSelection,
                    selectionMode: attributeFilter.selectionMode,
                  },
                };
              }

              return {
                attributeFilter,
              };
            })
          : [];

        const dashboardWithNewFilters: IDashboard = {
          ...dsb,
          filterContext: {
            ...dsb.filterContext,
            filters: effectiveFilters,
          } as IFilterContext,
        };

        setDashboard(dashboardWithNewFilters);
      } else {
        setDashboard(dsb);
      }
    })();
  }, []);

  const [drillEvent, setDrillEvent] = useState(null);

  const defaultDashboardEventHandlers = useMemo(() => {
    const handlers = [
      {
        eval: (e) => isDashboardDrillToDashboardResolved(e) && isDrillToSameDashboard(e),
        handler: (e, dispatch) => {
          dispatch(changeFilterContextSelection(e.payload.filters));
        },
      },
      {
        eval: (e) => isDashboardDrillToDashboardResolved(e) && !isDrillToSameDashboard(e),
        handler: (e) => {
          setDrillEvent(e);
        },
      },
      {
        eval: () => true,
        handler: (event) => {
          // list of events: https://github.com/gooddata/gooddata-ui-sdk/blob/da546a50cf6ef21f688fd61d60377e891d9627a9/libs/sdk-ui-dashboard/src/model/commands/base.ts#L13
          if (event.type === 'GDC.DASH/EVT.DRILL.DRILL_TO_CUSTOM_URL.RESOLVED') {
            window.location.href = event.payload.url;
          }
        },
      },
    ];

    return handlers;
  }, []);

  const drillDashboardEventHandlers = useMemo(() => {
    const handlers = [
      {
        eval: (e) => isDashboardInitialized(e),
        handler: (_, dispatch) => {
          if (drillEvent) {
            dispatch(changeFilterContextSelection(drillEvent.payload.filters));
          }
        },
      },
    ];

    return handlers;
  }, [drillEvent]);
  return (
    <>
      {!!drillEvent && (
        <>
          <button
            className="gd-button gd-button-primary"
            onClick={() => {
              setDrillEvent(null);
            }}
          >
            &lt; Go back
          </button>
          <Dashboard
            dashboard={drillEvent?.payload.drillDefinition.target}
            eventHandlers={drillDashboardEventHandlers}
          />
        </>
      )}
      <div style={{ height: drillEvent ? 0 : 'auto', overflow: 'hidden' }}>
        <Dashboard dashboard={dashboard} eventHandlers={defaultDashboardEventHandlers} />
      </div>
    </>
  );
};
