import React from 'react';

export const Check = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16.5" cy="16" r="16" fill="#0F766E" />
    <path
      d="M21.8337 12L14.5003 19.3333L11.167 16"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
