import styled from '@emotion/styled';
import { EuiFlexItem, EuiPage } from 'ui';

export const ProcessingImage = styled.img`
  width: 100%;
  max-width: 810px;
`;

export const StyledProcessingPage = styled(EuiPage)`
  background-color: white;
  max-height: 1300px;
  padding-top: 24px;
`;

export const StyledFlexItem = styled(EuiFlexItem)`
  padding-top: 48px;
  space-between: 24px;
`;
