import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { GetTasksQuery } from '@app/graphql/precheck/queries/getTasks/__generated__/getTasks.generated';

export const formatTasksAndOptions = (tasks: GetTasksQuery['getTasks']['tasks'] = []) => {
  const docOptions = {};
  const reasonOptions = {};

  const transformedTasks = tasks.map((task, index) => ({
    assignees: (task.assignees || []).map((assignee) => ({
      email: assignee?.assignee,
      name: assignee?.userInfo
        ? `${assignee?.userInfo?.givenName} ${assignee?.userInfo?.familyName?.replace(
            'temporarily_unavailable',
            '',
          )}`
        : null,
    })),
    attributes: task.propertyRecommendation.attribute || [],
    id: task.id,
    priority: index + 1,
    property: task.property,
    propertyCityAndState: task.property.city
      ? `${task.property.city || ''}, ${task.property.state || ''} ${
          task.property.postalCode || ''
        }`
      : '',
    //@ts-ignore
    propertyDocuments: task.property.documents || [],
    propertyId: task.propertyRecommendation.archipelagoID,
    propertyName:
      task.property.locationName ||
      task.property.streetAddress ||
      task.propertyRecommendation.archipelagoID,
    propertyUid: task.property.id,
    rawTiv: task.property.totalInsuredValueDisplay || 0,
    reasons: Object.keys(
      (task.propertyRecommendation.attribute || []).reduce((acc, attribute) => {
        attribute.reasons?.map((reason) => {
          if (reason === 'Valuation Outlier') {
            if (task?.propertyRecommendation?.valuationOutlierReason) {
              reasonOptions[task.propertyRecommendation.valuationOutlierReason] =
                task.propertyRecommendation.valuationOutlierReason;
              acc[task.propertyRecommendation.valuationOutlierReason] =
                task.propertyRecommendation.valuationOutlierReason;
            }
          } else {
            reasonOptions[reason] = reason;
            acc[reason] = reason;
          }
        });

        return acc;
      }, {}),
    ),
    recommendedDocuments: Object.keys(
      (task.propertyRecommendation.attribute || []).reduce((acc, attribute) => {
        attribute.documents?.map((doc) => {
          docOptions[doc] = doc;
          acc[doc] = doc;
        });

        return acc;
      }, {}),
    ),

    status: task.status,
    tiv: formatStreamCurrency(task.property.totalInsuredValueDisplay || 0),
  }));

  return {
    docOptions,
    reasonOptions,
    transformedTasks,
  };
};
