import { IJob } from '@onarchipelago/dice/EnrichmentProjects/types';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { Job, JobStatus, JobType, Status } from '@app/graphql/types';
import { OrgAttributeMetadataResult } from '@app/queries/propertyMetadata/types';

export const LOADING_LABEL = 'Loading...';
export const JOB_STATUS_LABELS = {
  Archived: 'Archived',
  ChangesRequested: 'Changes Requested',
  Complete: 'Complete',
  Created: 'Created',
  Expired: 'Expired',
  Failed: 'Failed',
  HazardsUpdated: 'Hazards Updated',
  InProgress: 'In Progress',
  MappingRulesApplied: 'Mapping Rules Applied',
  MappingRulesApplyFailed: 'Mapping Rules Apply Failed',
  None: 'None',
  PropertyDataValidateFailed: 'Property Data Validate Failed',
  PropertyDataValidated: 'Property Data Validated',
  PropertyUpdatesCompleted: 'Property Updates Completed',
  Ready: 'Ready',
  ReadyForReview: 'Ready for Review',
  Revert: 'Revert',
  Reverted: 'Reverted',
  StreamsUpdated: `${STREAMS_LABEL} Updated`,
  ValidateFailed: 'Validation Failed',
};

export const JOB_TYPE_LABELS = {
  AddProperties: 'Add Properties',
  Enrichment: 'Enrichment',
  Geo: 'Geo Enrichment',
  Ingest: 'Ingest',
};

export const SORT_OPTIONS = [
  {
    label: 'By date added',
    value: 'added',
  },
  {
    label: 'By due date',
    value: 'due_date',
  },
  {
    label: 'By status',
    value: 'status',
  },
];

// The API names, not the display names
// ciarify, is it "county" or "country" we are requiring?
export const REQUIRED_MAPPINGS = ['streetAddress', 'city', 'state'];

export const groupMetadataByName = (orgAttrPayload: OrgAttributeMetadataResult): object => {
  const { organizationAttributeMetadata: attrList } = orgAttrPayload;

  const groupedMetadata = {};

  attrList.reduce((list, { metadata }) => {
    groupedMetadata[metadata.name] = metadata.displayName;
    return list;
  }, []);

  return groupedMetadata;
};

export const sortJobs = (jobs: IJob[] = [], showCompleted: boolean, sortOption: string): IJob[] => {
  const filteredJobs = jobs.filter((job) =>
    showCompleted
      ? job
      : job.status !== 'Complete' &&
        job.status !== 'Archived' &&
        job.status !== 'Expired' &&
        job.status !== 'Reverted',
  );
  const statusOrder = Object.keys(JOB_STATUS_LABELS);

  switch (sortOption) {
    case 'due_date':
      return filteredJobs;

    case 'status':
      return filteredJobs.sort((a, b): any =>
        statusOrder.indexOf(a.status) > statusOrder.indexOf(b.status) ? 1 : -1,
      );

    case 'added':
      return filteredJobs.sort((a, b): any => (a.createdAt > b.createdAt ? 1 : -1));

    default:
      return filteredJobs.sort((a, b): any => (a.createdAt > b.createdAt ? 1 : -1));
  }
};

export const formatStatus = (job: Job) => {
  //  TODO - Use transition status in all case for rendering label, needs to be discussed when designing MP2
  if (
    job.type === JobType.Ingest &&
    job.status === JobStatus.Ready &&
    job.lifeCycle?.[0]?.progress === Status.Started
  ) {
    return LOADING_LABEL;
  }
  return JOB_STATUS_LABELS[job.status];
};
