import React from 'react';

export const ThreeConfetti = () => (
  <svg viewBox="0 0 421 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8690_10254)">
      <path d="M0 -77.9656H421V283.437H0V-77.9656Z" fill="white" />
      <path
        d="M404.194 19.3541L406.89 18.0952L406.17 14.5001L403.474 15.7589L404.194 19.3541Z"
        fill="#F8F3D4"
      />
      <path
        d="M251.786 66.9138L252.119 63.5742L249.404 63.2232L249.071 66.5627L251.786 66.9138Z"
        fill="#FFDE7D"
      />
      <path
        d="M30.789 43.5093L32.3379 41.8979L28.2464 37.0327L26.6976 38.644L30.789 43.5093Z"
        fill="#FFDE7D"
      />
      <path
        d="M367.45 2.40608L368.255 0.112305L365.151 -2.38318L364.346 -0.0893991L367.45 2.40608Z"
        fill="#FFDE7D"
      />
      <path
        d="M29.4324 53.8543L30.3916 52.2109L27.8289 49.8036L26.8696 51.4469L29.4324 53.8543Z"
        fill="#FFDE7D"
      />
      <path
        d="M373.918 45.2342L372.971 42.4329L370.842 43.0796L371.789 45.8809L373.918 45.2342Z"
        fill="#F8F3D4"
      />
      <path
        d="M56.6171 67.1653L56.6221 64.3989L52.7352 63.015L52.7302 65.7813L56.6171 67.1653Z"
        fill="#00B8A9"
      />
      <path
        d="M267.953 62.0327L266.975 59.437L264.114 61.0129L265.093 63.6086L267.953 62.0327Z"
        fill="#F6416C"
      />
      <path
        d="M146.232 55.9085L148.729 55.8542L146.735 50.1542L144.237 50.2085L146.232 55.9085Z"
        fill="#F6416C"
      />
      <path
        d="M401.19 7.86895L403.744 7.49121L403.861 3.38919L401.307 3.76693L401.19 7.86895Z"
        fill="#00B8A9"
      />
      <path
        d="M10.4716 4.54459L12.2021 3.24292L10.4257 0.0888634L8.69516 1.39053L10.4716 4.54459Z"
        fill="#00B8A9"
      />
      <path
        d="M352.123 16.7492L351.012 13.8572L345.504 16.54L346.616 19.432L352.123 16.7492Z"
        fill="#F6416C"
      />
      <path
        d="M341.697 61.6682L339.229 60.2893L338.255 64.7657L340.722 66.1446L341.697 61.6682Z"
        fill="#F6416C"
      />
      <path
        d="M157.197 76.5251L157.113 74.7048L154.394 74.6458L154.478 76.4661L157.197 76.5251Z"
        fill="#F8F3D4"
      />
      <path
        d="M240.25 26.1356L241.766 23.8894L237.645 19.7415L236.13 21.9876L240.25 26.1356Z"
        fill="#F8F3D4"
      />
      <path
        d="M152.613 65.6615L154.196 64.8491L151.112 58.4012L149.529 59.2136L152.613 65.6615Z"
        fill="#F8F3D4"
      />
      <path
        d="M310.039 43.6175L309.422 41.509L307.222 42.7107L307.839 44.8193L310.039 43.6175Z"
        fill="#00B8A9"
      />
      <path
        d="M373.324 21.5509L375.206 20.8284L372.905 14.781L371.024 15.5035L373.324 21.5509Z"
        fill="#00B8A9"
      />
      <path
        d="M376.695 36.7754L376.295 34.4492L373.974 34.3996L374.374 36.7257L376.695 36.7754Z"
        fill="#00B8A9"
      />
      <path
        d="M409.032 80.9773L407.485 79.9346L406.09 83.7725L407.637 84.8152L409.032 80.9773Z"
        fill="#F6416C"
      />
      <path
        d="M382.664 78.1887L384.602 76.8037L382.299 70.5976L380.361 71.9826L382.664 78.1887Z"
        fill="#F6416C"
      />
      <path
        d="M119.056 37.3377L120.51 36.3682L119.849 33.93L118.396 34.8995L119.056 37.3377Z"
        fill="#FFDE7D"
      />
      <path
        d="M233.292 47.5801L231.544 45.8308L228.779 49.9018L230.527 51.6511L233.292 47.5801Z"
        fill="#F8F3D4"
      />
      <path
        d="M392.598 22.4305L392.294 20.3491L388.823 20.8922L389.126 22.9736L392.598 22.4305Z"
        fill="#F8F3D4"
      />
      <path
        d="M239.27 41.4218L238.396 39.3794L235.522 40.1238L236.396 42.1662L239.27 41.4218Z"
        fill="#FFDE7D"
      />
      <path
        d="M217.725 25.5951L218.446 22.749L215.821 22.336L215.1 25.1821L217.725 25.5951Z"
        fill="#00B8A9"
      />
      <path
        d="M294.316 6.6683L296.316 5.78418L294.431 -1.26018L292.431 -0.37606L294.316 6.6683Z"
        fill="#F6416C"
      />
      <path
        d="M75.1394 64.2761L75.709 61.1555L72.4187 60.8646L71.8491 63.9851L75.1394 64.2761Z"
        fill="#FFDE7D"
      />
      <path
        d="M54.5181 52.2216L51.8428 51.5083L51.2233 58.7363L53.8987 59.4497L54.5181 52.2216Z"
        fill="#00B8A9"
      />
      <path
        d="M299.315 29.9913L297.623 27.1899L293.561 28.9426L295.253 31.744L299.315 29.9913Z"
        fill="#00B8A9"
      />
      <path
        d="M239.454 45.6317L237.268 44.9163L237.321 49.4075L239.508 50.123L239.454 45.6317Z"
        fill="#00B8A9"
      />
      <path
        d="M239.384 27.974L237.886 27.1255L237.154 30.7729L238.652 31.6213L239.384 27.974Z"
        fill="#F6416C"
      />
      <path
        d="M165.671 16.4063L164.217 13.0308L161.104 14.5494L162.559 17.9249L165.671 16.4063Z"
        fill="#00B8A9"
      />
      <path
        d="M264.044 -2.35973L263.229 -3.66455L260.722 -1.14446L261.538 0.16037L264.044 -2.35973Z"
        fill="#00B8A9"
      />
      <path
        d="M364.645 69.4104L363.354 66.5679L357.797 69.9921L359.088 72.8345L364.645 69.4104Z"
        fill="#F6416C"
      />
      <path
        d="M349.768 8.00856L347.771 6.77271L347.364 8.97026L349.36 10.2061L349.768 8.00856Z"
        fill="#F8F3D4"
      />
      <path
        d="M309.931 71.6077L312.103 70.9685L310.742 66.6899L308.57 67.3291L309.931 71.6077Z"
        fill="#00B8A9"
      />
      <path
        d="M140.452 46.5943L142.485 45.5728L140.518 40.9763L138.484 41.9978L140.452 46.5943Z"
        fill="#FFDE7D"
      />
      <path
        d="M123.292 31.2711L123.756 29.4426L120.053 29.2785L119.589 31.1069L123.292 31.2711Z"
        fill="#F8F3D4"
      />
      <path
        d="M129.074 6.96268L129.741 5.83936L126.837 2.74709L126.17 3.87042L129.074 6.96268Z"
        fill="#F8F3D4"
      />
      <path
        d="M213.032 58.8293L214.168 56.9075L212.673 55.5618L211.537 57.4836L213.032 58.8293Z"
        fill="#00B8A9"
      />
      <path
        d="M27.0114 24.2167L25.1914 22.6382L23.9727 25.4626L25.7927 27.041L27.0114 24.2167Z"
        fill="#FFDE7D"
      />
      <path
        d="M195.687 47.8478L192.953 46.8237L193.579 53.0198L196.313 54.044L195.687 47.8478Z"
        fill="#F6416C"
      />
      <path
        d="M77.1691 15.5185L75.5762 14.5327L74.7231 18.6441L76.316 19.6299L77.1691 15.5185Z"
        fill="#F6416C"
      />
      <path
        d="M230.368 56.9335L230.635 54.9814L227.276 53.4828L227.01 55.4348L230.368 56.9335Z"
        fill="#F8F3D4"
      />
      <path
        d="M294.884 67.6208L295.967 65.9692L293.871 63.9469L292.788 65.5984L294.884 67.6208Z"
        fill="#F6416C"
      />
      <path
        d="M301.408 66.365L300.925 64.3079L298.672 63.7142L299.156 65.7714L301.408 66.365Z"
        fill="#F6416C"
      />
      <path
        d="M48.9552 58.2934L50.6035 57.3357L48.3962 53.3437L46.7479 54.3014L48.9552 58.2934Z"
        fill="#F8F3D4"
      />
      <path
        d="M18.8446 31.0058L17.8262 28.3037L14.1501 30.2886L15.1685 32.9907L18.8446 31.0058Z"
        fill="#F6416C"
      />
      <path
        d="M182.926 9.08775L184.169 7.84326L182.901 5.74611L181.658 6.9906L182.926 9.08775Z"
        fill="#00B8A9"
      />
      <path
        d="M261.556 36.012L258.93 35.3142L257.509 41.1227L260.136 41.8205L261.556 36.012Z"
        fill="#FFDE7D"
      />
      <path
        d="M178.647 23.1343L177.463 20.3704L174.307 22.3654L175.491 25.1293L178.647 23.1343Z"
        fill="#FFDE7D"
      />
      <path
        d="M296.224 70.5504L293.875 69.0769L292.668 74.4273L295.017 75.9008L296.224 70.5504Z"
        fill="#F8F3D4"
      />
      <path
        d="M59.2686 55.7495L61.2891 55.9861L61.0156 50.0374L58.9951 49.8008L59.2686 55.7495Z"
        fill="#FFDE7D"
      />
      <path
        d="M42.5154 78.5147L43.8389 77.1667L41.3318 73.1507L40.0084 74.4987L42.5154 78.5147Z"
        fill="#FFDE7D"
      />
      <path
        d="M317.936 67.0232L317.207 63.6592L309.542 63.1709L310.271 66.535L317.936 67.0232Z"
        fill="#F6416C"
      />
      <path
        d="M404.907 22.1646L404.17 20.1309L401.8 20.6688L402.537 22.7025L404.907 22.1646Z"
        fill="#00B8A9"
      />
      <path
        d="M170.154 6.61662L169.078 3.5354L165.236 4.04814L166.312 7.12937L170.154 6.61662Z"
        fill="#FFDE7D"
      />
      <path
        d="M157.756 25.7698L155.465 25.8691L156.102 28.9725L158.393 28.8732L157.756 25.7698Z"
        fill="#F6416C"
      />
      <path
        d="M384.042 61.3451L384 58.4832L378.373 59.3457L378.415 62.2076L384.042 61.3451Z"
        fill="#00B8A9"
      />
      <path
        d="M179.523 6.76754L182.285 7.6604L180.692 1.9518L177.93 1.05894L179.523 6.76754Z"
        fill="#00B8A9"
      />
      <path
        d="M402.501 9.37299L404.146 8.03857L402.457 4.65056L400.813 5.98497L402.501 9.37299Z"
        fill="#FFDE7D"
      />
      <path
        d="M166.534 79.307L167.888 77.0828L163.199 72.2636L161.845 74.4878L166.534 79.307Z"
        fill="#00B8A9"
      />
      <path
        d="M43.9667 83.267L44.9141 81.8203L41.856 78.8614L40.9087 80.3081L43.9667 83.267Z"
        fill="#F6416C"
      />
      <path
        d="M93.2255 26.3654L95.8281 25.1052L93.4864 16.4677L90.8838 17.7279L93.2255 26.3654Z"
        fill="#00B8A9"
      />
      <path
        d="M215.062 45.7399L212.67 42.384L207.938 44.6047L210.33 47.9606L215.062 45.7399Z"
        fill="#F8F3D4"
      />
      <path
        d="M77.8163 78.6789L76.791 76.8052L74.1636 79.1584L75.1889 81.0321L77.8163 78.6789Z"
        fill="#FFDE7D"
      />
      <path
        d="M174.507 36.8471L174.694 33.6938L167.747 32.2788L167.56 35.4321L174.507 36.8471Z"
        fill="#00B8A9"
      />
      <path
        d="M16.9488 10.1847L15.8652 7.92139L12.0847 10.556L13.1683 12.8193L16.9488 10.1847Z"
        fill="#F6416C"
      />
      <path
        d="M37.655 0.701788L39.4805 0.497314L38.6002 -2.61895L36.7748 -2.41447L37.655 0.701788Z"
        fill="#F8F3D4"
      />
      <path
        d="M201.1 35.6977L199.953 34.1609L198.262 36.0636L199.409 37.6004L201.1 35.6977Z"
        fill="#00B8A9"
      />
      <path
        d="M269.868 -1.48618L268.61 -2.62964L266.836 0.597912L268.094 1.74137L269.868 -1.48618Z"
        fill="#FFDE7D"
      />
      <path
        d="M227.789 2.91086L226.389 2.03809L224.795 5.07952L226.196 5.9523L227.789 2.91086Z"
        fill="#F6416C"
      />
      <path
        d="M332.225 23.7662L334.728 23.4412L334.357 19.0601L331.855 19.3851L332.225 23.7662Z"
        fill="#00B8A9"
      />
      <path
        d="M36.6529 56.1675L35.3154 55.2903L34.6931 56.8729L36.0305 57.7502L36.6529 56.1675Z"
        fill="#FFDE7D"
      />
      <path
        d="M276.741 80.345L274.929 78.488L272.105 82.7918L273.917 84.6487L276.741 80.345Z"
        fill="#F8F3D4"
      />
      <path
        d="M317.731 35.9572L317.188 34.1714L314.579 34.6967L315.122 36.4825L317.731 35.9572Z"
        fill="#00B8A9"
      />
      <path
        d="M357.107 57.6706L358.329 55.2329L355.543 52.6977L354.321 55.1354L357.107 57.6706Z"
        fill="#FFDE7D"
      />
      <path
        d="M322.851 59.8338L325.229 59.2419L325.414 52.6608L323.036 53.2526L322.851 59.8338Z"
        fill="#FFDE7D"
      />
      <path
        d="M111.618 70.6448L111.909 69.2261L107.734 66.0548L107.443 67.4735L111.618 70.6448Z"
        fill="#FFDE7D"
      />
      <path
        d="M420.062 27.4919L421.062 25.4707L417.681 22.5245L416.681 24.5457L420.062 27.4919Z"
        fill="#FFDE7D"
      />
      <path
        d="M243.002 40.554L241.14 40.7576L241.849 45.6706L243.71 45.4669L243.002 40.554Z"
        fill="#00B8A9"
      />
      <path
        d="M179.887 19.786L178.389 19.6599L178.794 21.979L180.292 22.1051L179.887 19.786Z"
        fill="#FFDE7D"
      />
      <path
        d="M35.429 8.95811L36.5459 7.69995L33.4492 3.74472L32.3322 5.00288L35.429 8.95811Z"
        fill="#FFDE7D"
      />
      <path
        d="M391.886 53.1689L390.287 51.1877L388.114 53.3885L389.713 55.3697L391.886 53.1689Z"
        fill="#FFDE7D"
      />
      <path
        d="M218.137 10.5477L216.434 8.58643L212.969 13.531L214.672 15.4922L218.137 10.5477Z"
        fill="#F6416C"
      />
      <path
        d="M63.1558 35.0586L64.8516 34.073L63.3776 30.3154L61.6818 31.301L63.1558 35.0586Z"
        fill="#FFDE7D"
      />
      <path
        d="M92.4807 56.116L92.5391 54.0461L88.9709 53.9693L88.9126 56.0392L92.4807 56.116Z"
        fill="#00B8A9"
      />
      <path
        d="M45.5431 9.05884L46.6895 8.05518L43.3718 3.75489L42.2254 4.75856L45.5431 9.05884Z"
        fill="#00B8A9"
      />
      <path
        d="M31.4845 29.5826L29.5967 28.489L27.9401 32.7105L29.8279 33.8042L31.4845 29.5826Z"
        fill="#FFDE7D"
      />
      <path
        d="M407.312 79.1301L406.98 76.907L401.554 78.8705L401.886 81.0937L407.312 79.1301Z"
        fill="#F8F3D4"
      />
      <path
        d="M204.111 54.4648L201.798 53.0417L201.634 56.2301L203.947 57.6532L204.111 54.4648Z"
        fill="#F6416C"
      />
      <path
        d="M192.803 18.3435L194.347 17.8718L192.543 12.5083L191 12.98L192.803 18.3435Z"
        fill="#F8F3D4"
      />
      <path
        d="M259.894 54.3461L258.769 53.1802L257.691 54.7682L258.816 55.9342L259.894 54.3461Z"
        fill="#FFDE7D"
      />
      <path
        d="M407.652 6.54558L410.153 5.07446L407.669 -1.65413L405.167 -0.183011L407.652 6.54558Z"
        fill="#F6416C"
      />
      <path
        d="M215.174 43.1979L213.819 41.5271L211.076 44.5618L212.431 46.2325L215.174 43.1979Z"
        fill="#F6416C"
      />
      <path
        d="M346.425 22.2986L344.778 21.2019L342.742 25.7856L344.389 26.8823L346.425 22.2986Z"
        fill="#F6416C"
      />
      <path
        d="M274.861 1.2989L273.334 -0.974609L267.566 5.24069L269.094 7.51419L274.861 1.2989Z"
        fill="#00B8A9"
      />
      <path d="M181.765 47.5431L180.916 44.5891H175.048L175.897 47.5431H181.765Z" fill="#F6416C" />
    </g>
    <defs>
      <clipPath id="clip0_8690_10254">
        <rect width="421" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
