import React, { createContext, useContext, useState } from 'react';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { OrgLevelType } from '@app/graphql/types';

interface IContext {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  permissions: {
    canViewAlerts: boolean;
    canViewInsights: boolean;
    canViewSOVManager: boolean;
    canViewLibrary: boolean;
    canViewUsers: boolean;
    canViewPrecheck: boolean;
    casualty: {
      canViewUsers: boolean;
      canViewLibrary: boolean;
    };
  };
}
export const NavContext = createContext({} as IContext);

export const useNavContext = () => useContext(NavContext);

export const NavProvider = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const orgPermissions = getOrgPermissions(account, selectedOrganization?.id);

  const isEnterprise = selectedOrganization?.orgLevel === OrgLevelType.Enterprise;

  const { data } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const hasEditAlerts = data?.orgInfo?.permissionsV2?.some(
    (permission) => permission.resource === 'Alert' && permission.action === 'Edit',
  );

  const permissions = {
    canViewAlerts: !isEnterprise && (hasEditAlerts || account.permissions.admin),
    canViewInsights: data?.orgInfo?.permissions?.canViewInsights,
    canViewLibrary: orgPermissions.includes('canManageDocuments'),
    canViewPrecheck: orgPermissions?.includes('canManageProperties') && !isEnterprise,
    canViewSOVManager: !isEnterprise,
    canViewUsers: orgPermissions.includes('canManageUsers'),
    casualty: {
      canViewLibrary: orgPermissions.includes('canManageDocuments'),
      canViewUsers: orgPermissions.includes('canManageUsers'),
    },
  };

  return (
    <NavContext.Provider
      value={{
        isCollapsed,
        permissions,
        setIsCollapsed,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};
