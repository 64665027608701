import React from 'react';
import { Icon } from 'ui';
import { NavItem } from './NavItem';

export const SignOut = () => (
  <NavItem
    testId="logout-menu-item"
    icon={<Icon name="logOut" />}
    link="/logout"
    label="Sign Out"
  />
);
