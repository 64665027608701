import React, { useEffect } from 'react';
import { Button, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, useToast } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useUploads } from '@app/contexts/UploadsContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import DocumentUpload from '@app/platform/Library/DocumentUpload/DocumentUpload';
import { DocumentBadge } from './DocumentBadge';
import { StyledContainer, StyledDescription } from './Documents.emotion';

export const UploadDocuments = ({ propertyID, recommendedDocs, refetch }) => {
  const { selectedOrganization } = useUserSession();
  const { uploads } = useUploads();
  const loading = Object.values(uploads).some((upload) => upload.progress === 0);

  const toast = useToast();

  useEffect(() => {
    if (loading) {
      toast({
        label:
          'We have received your document, and it is currently processing for enrichment. All eligible fields will be autocompleted once enrichment is complete',
        timeout: 4000,
        title: 'Document Uploaded',
        type: 'success',
      });
    }

    setTimeout(() => {
      refetch();
    }, 5000);
  }, [Object.values(uploads).length]);

  const renderDocsBadges = () => (
    <EuiFlexGroup wrap gutterSize="s">
      {recommendedDocs.map((doc, i) => (
        <DocumentBadge key={i} doc={doc} />
      ))}
    </EuiFlexGroup>
  );

  return (
    <DocumentUpload organizationId={selectedOrganization.id} propertyID={propertyID}>
      <StyledContainer>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem>
            <StyledDescription>
              <EuiIcon style={{ marginBottom: 4 }} type="sparkles" /> Save Time by Autofilling From
              Documents
            </StyledDescription>
            <EuiSpacer size="s" />
            {renderDocsBadges()}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {loading ? <LoadingSpinnerV2 /> : <Button label="Upload" />}
          </EuiFlexItem>
        </EuiFlexGroup>
      </StyledContainer>
    </DocumentUpload>
  );
};
