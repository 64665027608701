import React, { useEffect } from 'react';
import { EuiPageHeader, EuiPanel, EuiSpacer, Tabs } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { TrackSpotlight, useTracker } from '@app/hooks/useTracker';
import { FEATURE_TYPE_ACTIVITY_LOG } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { DashboardV2 } from '../DashboardV2/DashboardV2';
import { EventsContainer } from '../HomePage/ActivityFeed/EventsContainer';
import NoSovPage from '../NoSovPage/NoSovPage';

export const Spotlight = () => {
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const { stream } = useStreamContext();
  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const canManageProperties = permissions?.includes('canManageProperties');

  const showActivityLog =
    isFeatureEnabled(selectedOrganization?.enabledFeatures, FEATURE_TYPE_ACTIVITY_LOG) &&
    canManageProperties;

  const tracker = useTracker();

  useEffect(() => {
    tracker.track(`${TrackSpotlight.prefix} Page viewed`, {
      event_surface: TrackSpotlight.event_surface,
      organization_id: selectedOrganization?.id,
      organization_name: selectedOrganization?.name,
    });
  }, []);

  if (!stream) {
    return <NoSovPage />;
  }

  const tabs = [
    {
      content: <DashboardV2 />,
      id: 'dashboardV2',
      label: 'Dashboard',
    },
  ];

  if (showActivityLog) {
    tabs.push({
      content: <EventsContainer />,
      id: 'list',
      label: 'Activity Log',
    });
  }

  return (
    <EuiPanel paddingSize="l">
      <EuiPageHeader pageTitle="Spotlight"></EuiPageHeader>
      <>
        <EuiSpacer size="l" />
        {tabs.length > 1 ? (
          <Tabs initialTabId={'dashboardV2'} size="l" bottomBorder tabs={tabs} />
        ) : (
          <DashboardV2 />
        )}
      </>
    </EuiPanel>
  );
};
