import { GetStreamsQuery } from '@app/graphql/queries/streams/__generated__/GetStreams.generated';

export const makeEffectiveStreams = (data: GetStreamsQuery, limit?: number) => {
  // 1. isMyProperties: true` at the top
  // 2. Sort by effectiveDate (soonest first)
  // 3. if limit is provided, keep only the first `limit` streams
  const sortedStreams = [...(data?.streamsV2?.streams || [])].sort((a, b) => {
    if (a.isMyProperties && !b.isMyProperties) {
      return -1;
    }
    if (!a.isMyProperties && b.isMyProperties) {
      return 1;
    }
    if (a.effectiveDate === null && b.effectiveDate !== null) {
      return -1;
    }
    if (a.effectiveDate !== null && b.effectiveDate === null) {
      return 1;
    }
    if (a.effectiveDate === null && b.effectiveDate === null) {
      return 0;
    }
    return b.effectiveDate - a.effectiveDate;
  });

  return limit ? sortedStreams.slice(0, limit) : sortedStreams;
};
