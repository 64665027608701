import styled from '@emotion/styled';
import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiLink, EuiTitle } from 'ui';

export const SummaryContainer = styled(EuiFlexItem)`
  display: inline;
`;

export const ReasonsContainer = styled(EuiFlexItem)`
  display: flex;
  flex-direction: row;
`;

export const PrecheckCard = styled(EuiCard)`
  padding-top: 0;
`;

export const TitleContainer = styled(EuiFlexItem)`
  align-items: flex-start;
  background-color: #69707d;
  border-radius: 6px 6px 0 0;
  padding: 5px 0 3px 16px;
`;

export const Title = styled(EuiTitle)`
  color: white !important;
`;
interface TableContainerProps {
  marginTop?: string;
}

export const TableContainer = styled(EuiFlexItem)<TableContainerProps>`
  margin-top: ${(props) => props.marginTop || '12px'};
`;

export const Reasons = styled.p`
  margin-left: 6px;
`;

export const ExplanationContainer = styled(EuiFlexItem)`
  color: #69707d;
`;

export const PrecheckRecommendationsExportContainer = styled.div`
  height: 100%;
  overflow: scroll;
`;

export const LinkContainer = styled(EuiLink)`
  &.hide-for-print {
    display: none !important;
  }
`;
