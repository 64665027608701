import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem } from 'ui';

export const StyledGroup = styled(EuiFlexGroup)<{ color: string }>`
  background-color: ${(props) => props.color};
  color: white;
  font-weight: 700;
`;

export const StyledItem = styled(EuiFlexItem)`
  align-items: center;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
`;
