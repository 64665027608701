import styled from '@emotion/styled';
import {
  ButtonIcon,
  ButtonIconProps,
  EuiCollapsibleNav,
  EuiCollapsibleNavProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiListGroup,
} from 'ui';

export const StyledListGroup = styled(EuiListGroup)`
  font-weight: 600;
  padding: 0;
  gap: 0px;
  .euiListGroupItem__button {
    min-height: 40px;
    padding: 8px 16px;
    &[class*='isActive'] {
      background-color: #f7f8fc;
      text-decoration: underline;
      color: ${({ theme }) => theme?.euiTheme?.colors?.primary};
    }
  }
`;

interface StyledSideNavProps extends EuiCollapsibleNavProps {
  backgroundColor?: string;
  hasAlert?: boolean;
}

interface StyledLogoProps {
  isCollapsed?: boolean;
}

export const StyledSideNav = styled(EuiCollapsibleNav, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<StyledSideNavProps>`
  top: ${(props) => (props?.hasAlert ? '96px' : '48px')};
  padding-top: 12px;
  background-color: ${(props) => props.backgroundColor};
  transition: ${({ theme }) =>
    `max-inline-size ${theme.euiTheme.animation.slow} ${theme.euiTheme.animation.resistance}`};
`;

export const StyledLogoContainer = styled(EuiFlexItem)<StyledLogoProps>`
  padding: ${(props) => (props?.isCollapsed ? '0px 12px;' : '0px 16px;')};
`;

interface StyledButtonIcon extends ButtonIconProps {
  isCollapsed: boolean;
}
export const StyledButtonIcon = styled(ButtonIcon, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<StyledButtonIcon>`
  ${({ theme, isCollapsed }) => `
    ${
      isCollapsed
        ? `
          border-radius: 0px 24px 24px 0px;
          border-top: 1px solid ${theme.euiTheme.colors.lightShade};
          border-right: 1px solid ${theme.euiTheme.colors.lightShade};
          border-bottom: 1px solid ${theme.euiTheme.colors.lightShade};
          background: #F7F8FC;
          position: absolute;
          left: 48px;
          width: 26px;
          height: 28px;
          z-index: 100;
          :focus, :hover {
            background: ${theme.euiTheme.colors.lightestShade} !important;
          }
        `
        : `
          border-radius: 24px 0px 0px 24px;
          border-top: 1px solid ${theme.euiTheme.colors.lightShade};
          border-bottom: 1px solid ${theme.euiTheme.colors.lightShade};
          border-left: 1px solid ${theme.euiTheme.colors.lightShade};
          border-right: 1px solid white;
          background: white;
          position: relative;
          left: 2px;
          width: 28px;
          height: 28px;
          :focus, :hover {
            background: white !important;
          }
    `
    }

  `}
`;

export const NotificationBadge = styled.span`
  display: flex;
  flex-flow: wrap;
  place-content: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 10px;
  min-width: 14px;
  line-height: 1;
  padding: 0px 4px;
  height: 14px;
  border-radius: 7px;
  z-index: 1;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #bd271e;
  color: #ffffff;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
`;

export const InboxItemContainer = styled(EuiFlexGroup)`
  gap: 0px;
`;

export const MenuOptionsContainer = styled.div<{ hasAlert: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${(props) => (props?.hasAlert ? '100px' : '48px')};
`;

export const UserMenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;
