import { ModalWithButtons } from '@app/components/ModalWithButtons/ModalWithButtons';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useDismissTasksMutation } from '@app/graphql/precheck/mutations/dismissTasks/__generated__/dismissTasks.generated';
import { useUnDismissTasksMutation } from '@app/graphql/precheck/mutations/undismissTasks.ts/__generated__/undismissTasks.generated';
import { TaskStatus } from '@app/graphql/precheck/precheck.types';
import { useTracker } from '@app/hooks/useTracker';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import React, { useState } from 'react';
import { Avatar, Button, ButtonEmpty, Comparators, EuiFlexGroup, EuiFlexItem, EuiSpacer, useToast } from 'ui';
import { TaskFlyoutWrapper } from '../TaskFlyout/TaskFlyout';
import { Assignment } from './Assignment';
import { colorMap } from './constants/colorMap';
import { labelMap } from './constants/labelMap';
import { useTaskContext } from './context/TaskContext';
import { IFormattedtask } from './context/types';
import { StyledBadge, StyledStat, StyledSummary, StyledTaskTable } from './TaskTable.emotion';

export const TaskTable = () => {
  const {
    tasks,
    setSelectedTasks,
    selectedTasks,
    filters,
    loading,
    filteredTiv,
    tivPercent,
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
  } = useTaskContext();
  const client = usePrecheckClient();
  const tracker = useTracker();
  const { selectedTaskId, setSelectedTaskId } = useTaskContext();
  const [isOpen, setIsOpen] = useState(false);
  const { selectedOrganization } = useUserSession();
  const toast = useToast();

  const [dismissTasksMutation, { loading: dismissLoading }] = useDismissTasksMutation({
    client,
    onCompleted: () => {
      setSelectedTasks([]);
      setSelectedTaskId(null);
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    variables: {
      input: {
        orgName: selectedOrganization?.name,
        taskIDs: selectedTasks.map((task) => task.id),
      },
    },
  });

  const [unDismissTasksMutation, { loading: unDismissLoading }] = useUnDismissTasksMutation({
    client,
    onCompleted: () => {
      setSelectedTasks([]);
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    variables: {
      input: {
        orgName: selectedOrganization?.name,
        taskIDs: selectedTasks.map((task) => task.id),
      },
    },
  });

  const dismissTxt = filters.dismissed ? `Undismiss` : `Dismiss`;

  const handleDismiss = async () => {
    if (filters.dismissed) {
      tracker.track('Pre-Check: Tasks Undismissed', {
        taskIds: selectedTasks.map((task) => task.id),
      });
      await unDismissTasksMutation();
    } else {
      tracker.track('Pre-Check: Tasks Dismissed', {
        taskIds: selectedTasks.map((task) => task.id),
      });
      await dismissTasksMutation();
    }

    setIsOpen(false);
  };

  const onSelectionChange = (selectedItems) => {
    setSelectedTasks(selectedItems);
  };

  const selection = {
    initialSelected: selectedTasks,
    onSelectionChange,
  };

  const columns = [
    {
      field: 'priority',
      name: 'Priority',
      sortable: true,
      width: '80px',
    },
    {
      name: 'Property',
      render: (task) => (
        <EuiFlexGroup gutterSize="none" direction="column">
          <EuiFlexItem style={{ fontWeight: 600 }} grow={false}>
            {task.propertyName}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{task.propertyCityAndState}</EuiFlexItem>
        </EuiFlexGroup>
      ),

      sortable: () => {},

      width: '27%',
    },

    {
      field: 'tiv',
      name: 'TIV',
      sortable: true,
      width: '7%',
    },
    {
      field: 'assignees',
      name: 'Assignees',
      render: (assignees) => (
        <>
          {assignees.length === 0 ? (
            <Avatar name={null} />
          ) : (
            <EuiFlexGroup gutterSize="xs">
              {assignees.map((assignee) => (
                <EuiFlexItem key={assignee.email} grow={false}>
                  <Avatar name={assignee.name} />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          )}
        </>
      ),
      width: '7%',
    },
    {
      field: 'reasons',
      name: 'Reason',
      render: (reasons) => (
        <EuiFlexGroup wrap gutterSize="xs">
          {reasons.map((reason, index) => (
            <EuiFlexItem key={index} grow={false}>
              <StyledBadge color={colorMap[reason]}>{labelMap[reason]}</StyledBadge>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      ),
      width: '27%',
    },
    {
      field: 'recommendedDocuments',
      name: '',
      render: () => !filters.dismissed && (
          <ButtonEmpty
            label="Click to update"
          />
      ),
      width: '20%',
    },
    {
      name: '',
      render: (task) => (
        <ButtonEmpty
          label={`Click to ${filters.dismissed ? 'undismiss' : 'dismiss'}`}
          onClick={(event) => {
            event.stopPropagation();
            setSelectedTasks([task]);
            setIsOpen(true);
          }}
        />
      ),
      width: '20%',
      align: 'right',
    },
  ];

  const getRowProps = (task: IFormattedtask) => ({
    className: `customtaskClass ${task.status === TaskStatus.Dismissed ? 'dismissed' : ''}`,
    onClick: (e) => {
      if (task.status === TaskStatus.Dismissed) {
        e.stopPropagation();
        return;
      }
      // if a user clicks anywhere in checkbox or action cells do not
      // selected task and open flyout
      if (
        e.target.closest('td').className === 'euiTableRowCellCheckbox' ||
        e.target.closest('td').className.includes('euiTableRowCell--hasActions')
      ) {
        e.stopPropagation();
        return;
      }

      tracker.track('Pre-Check: Task Flyout Opened', {
        taskId: task.id,
      });

      // if it's the same, just return
      if (selectedTaskId === task.id) {
        return;
      }

      setSelectedTaskId(task.id);
    },
  });

  const sorting = {
    sort: {
      direction: sortDirection,
      field: sortField,
    },
  };

  const onTableChange = ({ sort }) => {
    if (sort) {
      const { field, direction } = sort;
      setSortField(field);
      setSortDirection(direction);
    }
  };

  let items = [];

  if (sortField) {
    let effectiveSortField = sortField;
    if (effectiveSortField === 'Property') {
      effectiveSortField = 'propertyName';
    }

    if (effectiveSortField === 'tiv') {
      effectiveSortField = 'rawTiv';
    }

    items = tasks
      .slice(0)
      .sort(Comparators.property(effectiveSortField, Comparators.default(sortDirection)));
  } else {
    items = tasks;
  }

  return (
    <>
      {selectedTaskId && (
        <TaskFlyoutWrapper
          dismissHandler={() => {
            const task = tasks.find((item) => item.id === selectedTaskId);
            setSelectedTasks([task]);
            setIsOpen(true);
          }}
        />
      )}
      {isOpen && (
        <ModalWithButtons
          header={`Are you sure you want to ${dismissTxt.toLocaleLowerCase()} ${
            selectedTasks.length > 1 ? `${selectedTasks.length} recommendations` : '1 recommendation'
          }?`}
          buttonActionLabel={dismissTxt}
          onClose={() => {
            setIsOpen(false);
            setSelectedTasks([]);
          }}
          onClick={handleDismiss}
          buttonActionVariant="danger"
          isLoading={dismissLoading || unDismissLoading}
        ></ModalWithButtons>
      )}
      {selectedTasks.length > 0 && (
        <>
          <EuiFlexGroup>
            {!filters.dismissed && (
              <EuiFlexItem grow={false}>
                <Assignment
                  type="bulkAssign"
                  tasks={tasks.filter((task) => selectedTasks.map((t) => t.id).includes(task.id))}
                />
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={false}>
              <Button
                fill
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                label={`${dismissTxt} Recommendations (${selectedTasks.length})`}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="l" />
        </>
      )}
      <StyledSummary>
        Showing recommendations for <StyledStat>{tasks.length}</StyledStat> properties, representing{' '}
        <StyledStat>{tivPercent}</StyledStat> ({filteredTiv}) of total TIV
      </StyledSummary>
      <EuiSpacer size="m" />
      <StyledTaskTable
        // hack to get table update when tasks are unselected
        // after assign / unassign
        key={selectedTasks.length}
        isSelectable={true}
        data-testid="precheck-task-table"
        //   @ts-ignore
        columns={columns}
        items={items}
        loading={loading}
        itemId="id"
        sorting={sorting}
        selection={selection}
        rowProps={getRowProps}
        onChange={onTableChange}
      />
    </>
  );
};
