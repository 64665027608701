import styled from '@emotion/styled';
import { EuiBadge, EuiText } from 'ui';

export const StyledResultsText = styled(EuiText)`
  padding-top: 8px;
`;

export const StyledBadge = styled(EuiBadge)`
  color: #fff;
  margin: 3px;
  background-color: #69707d;
`;

export const ProcessingBadge = styled(EuiBadge)`
  color: #fff;
  margin: 3px;
  background-color: #0077cc;
`;
