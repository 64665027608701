import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem, EuiToolTip } from 'ui';
import { useTracker } from '@app/hooks/useTracker';
import { useNavContext } from '../SideNavContext';

const StyledLink = styled(Link)<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? '' : 'unset')};
  &:hover {
    text-decoration: underline;
  }
`;

interface IProps {
  icon: React.ReactNode;
  label: string;
  link?: string;
  onClick?: (e) => void;
  testId?: string;
}

export const NavItem: FC<IProps> = ({ icon, label, link, onClick, testId }) => {
  // once all links are fully formed links using react router, replace active
  // checks with react router api checks
  const isActive = link && location.pathname.toLocaleLowerCase() === link.toLowerCase();
  const { isCollapsed } = useNavContext();
  const tracker = useTracker();

  const content = isCollapsed ? label : null;

  return (
    <EuiToolTip content={content} position="right">
      <StyledLink
        data-testid={testId}
        onClick={(e) => {
          tracker.track(`${label} Navigation Item Clicked`);

          if (onClick) {
            onClick(e);
          }
        }}
        isActive={isActive}
        to={link}
      >
        <EuiFlexGroup
          responsive={false}
          alignItems="center"
          style={{ paddingLeft: '16px' }}
          gutterSize="s"
        >
          <EuiFlexItem grow={false}>{icon}</EuiFlexItem>
          {!isCollapsed && <EuiFlexItem grow={false}>{label}</EuiFlexItem>}
        </EuiFlexGroup>
      </StyledLink>
    </EuiToolTip>
  );
};
