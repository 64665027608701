import { AssetName } from 'ui';
import { IAccountState } from '@app/containers/AuthProvider/types';
import {
  AJG_LOGO_STYLE,
  AJG_THEME,
  ALT_LOGO_STYLE,
  AON_LOGO_STYLE,
  AON_THEME,
  DEFAULT_LOGO_STYLE,
  MARSH_LOGO_STYLE,
  MARSH_THEME,
  WTW_LOGO_STYLE,
  WTW_THEME,
} from './constants';

export const hasEmailSuffixes = (email: string, ...suffixes: string[]): boolean =>
  suffixes.some(
    (suffix) =>
      email?.endsWith(`+${suffix}@onarchipelago.com`) || email?.endsWith(`@${suffix}.com`),
  );

export const useWhiteLabel = (account: IAccountState, isCollapsed = false) => {
  const email = account?.email;

  if (isCollapsed) {
    return {
      assetName: AssetName.logoAlt,
      logoStyle: ALT_LOGO_STYLE,
      themeOverride: undefined,
    };
  }

  // AON
  if (hasEmailSuffixes(email, 'aon')) {
    return {
      assetName: AssetName.aonLogo,
      logoStyle: AON_LOGO_STYLE,
      themeOverride: AON_THEME,
    };
  }

  // WTW
  if (hasEmailSuffixes(email, 'wtwco', 'willistowerswatson')) {
    return {
      assetName: AssetName.wtwLogo,
      logoStyle: WTW_LOGO_STYLE,
      themeOverride: WTW_THEME,
    };
  }

  if (hasEmailSuffixes(email, 'marsh')) {
    return {
      assetName: AssetName.marshLogo,
      logoStyle: MARSH_LOGO_STYLE,
      themeOverride: MARSH_THEME,
    };
  }

  if (hasEmailSuffixes(email, 'ajg')) {
    return {
      assetName: AssetName.ajgLogo,
      logoStyle: AJG_LOGO_STYLE,
      themeOverride: AJG_THEME,
    };
  }

  if (hasEmailSuffixes(email, 'alliant')) {
    return {
      assetName: AssetName.alliantLogo,
      logoStyle: AJG_LOGO_STYLE,
      themeOverride: AJG_THEME,
    };
  }

  return {
    assetName: AssetName.logo,
    logoStyle: DEFAULT_LOGO_STYLE,
    themeOverride: undefined,
  };
};
