import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { convertRuleGroupToFilters } from '@app/components/Filter/utils';
import { useDecodedParams } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useGetStreamsQuery } from '@app/graphql/queries/streams/__generated__/GetStreams.generated';
import { StreamPropertiesPageQuery } from '@app/graphql/queries/streams/__generated__/StreamPropertiesPage.generated';
import { useGetStreamPropertyGroupsQuery } from '@app/graphql/queries/streams/__generated__/StreamPropertyGroups.generated';
import { useGetStreamQuery } from '@app/graphql/queries/streams/__generated__/StreamV2.generated';
import {
  AttributeMetadata,
  Exact,
  Stream,
  StreamPermissions,
  StreamPropertiesPageInput,
} from '@app/graphql/types';
import { getPreparedDataAsGroups } from '@app/queries/streams/PropertyGroupsQuery/utils';
import { getGroupByFromState, resolveFilters } from '../Stream/groupbyv2';
import { usePageState } from '../Stream/utils';
import { IRouteProps } from './types';
import { getSlug } from './utils';

interface IPropertPageContext {
  loading: boolean;
  stream: Stream;
  permissions: StreamPermissions & { canViewReports: boolean };
  refetchPropertiesDataGrid?: (
    variables?: Partial<
      Exact<{
        input: StreamPropertiesPageInput;
      }>
    >,
  ) => Promise<ApolloQueryResult<StreamPropertiesPageQuery>>;
  setRefetchPropertiesDataGrid?: React.Dispatch<
    React.SetStateAction<
      (
        variables?: Partial<
          Exact<{
            input: StreamPropertiesPageInput;
          }>
        >,
      ) => Promise<ApolloQueryResult<StreamPropertiesPageQuery>>
    >
  >;
  propertyAttributeMetadata?: AttributeMetadata[];
  showmarketVisibleToggle: boolean;
  setMarketVisibleToggle: (boolean) => void;
  marketVisibleToggle: boolean;
}

const PropertiesPageContext = createContext({} as IPropertPageContext);

export const PropertiesPageProvider = ({ children }: { children: React.ReactNode }) => {
  const { account, refresh: refreshAccount } = useAuth();
  const { selectedOrganization, setOrgByName } = useUserSession();
  const [pageState] = usePageState();

  const { currentSnapshot, changesSince } = pageState;
  const filters = pageState.filters;
  const [selectedProperties, setSelectedProperties] = useState([]);
  const groupBys = getGroupByFromState(pageState.groupByV2);
  const [refetchPropertiesDataGrid, setRefetchPropertiesDataGrid] = useState<
    (
      variables?: Partial<
        Exact<{
          input: StreamPropertiesPageInput;
        }>
      >,
    ) => Promise<ApolloQueryResult<StreamPropertiesPageQuery>>
  >();

  const isAdmin = account?.permissions?.admin || false;
  const isDM = isAdmin || !!account?.permissions?.canViewProjects;

  const { streamSlug, organizationName } = useDecodedParams<IRouteProps>();
  const {
    data: dataStreams,
    loading: loadingStreams,
    error: errorStreams,
  } = useGetStreamsQuery({
    fetchPolicy: 'cache-first',
    skip: !!streamSlug,
    variables: {
      orgName: isAdmin || isDM ? organizationName : null,
    },
  });

  const { data, loading, error, refetch } = useGetStreamQuery({
    onCompleted: () => {
      refreshAccount();
    },
    skip: !streamSlug && !dataStreams,
    variables: {
      isAdmin,
      slug: getSlug(dataStreams?.streamsV2?.streams || [], streamSlug, organizationName) || '',
      snapshot: pageState.currentSnapshot,
      userCode: account?.userCode || '',
    },
  });

  const streamV2 = data?.streamV2;

  const {
    loading: loadingGroups,
    data: dataGroups,
    error: errorGroups,
    refetch: refetchPropertyGroups,
  } = useGetStreamPropertyGroupsQuery({
    skip: !streamV2?.stream?.slug,
    variables: {
      changesSince: changesSince || '',
      compareChanges: !!changesSince,
      currentSnapshot: currentSnapshot || '',
      filter: resolveFilters(
        convertRuleGroupToFilters(filters),
        pageState.groupByV2,
        selectedProperties.map((property) => property.value),
      ),
      // NOTE: The API only supports grouping on 1 single attribute.
      groupBy: getGroupByFromState(pageState.groupByV2),
      streamSlug: streamV2?.stream?.slug || streamSlug,
    },
  });

  const effectiveGroupedProperties = useMemo(
    // FIX ME
    // @ts-ignore
    () =>
      getPreparedDataAsGroups(
        dataGroups,
        // @ts-ignore
        streamV2?.stream?.snapshots,
        !!changesSince,
        data?.propertyAttributeMetadataV2,
      ),
    [JSON.stringify(dataGroups), groupBys],
  );

  useEffect(() => {
    const orgName = data?.streamV2?.stream?.orgName;
    if (!orgName || !account?.managedOrgs?.find((o) => o.org.name === orgName)) {
      return;
    }
    setOrgByName(orgName);
  }, [JSON.stringify(streamV2), JSON.stringify(account?.managedOrgs)]);
  const reportsEnabled = !!streamV2?.stream?.allowedReports?.length;

  return (
    <PropertiesPageContext.Provider
      value={{
        effectiveGroupedProperties,
        errorGroups,
        groups: dataGroups?.streamPropertyGroups?.groups || dataGroups?.compareChanges?.groups,
        loading: loading || loadingStreams,

        loadingGroups,

        lock: streamV2?.lock,

        // FIX ME
        // @ts-ignore
        permissions: {
          ...streamV2?.permissions,
          canViewReports: reportsEnabled,
        },
        // FIX ME
        // @ts-ignore
        propertyAttributeMetadata: data?.propertyAttributeMetadataV2,
        refetchPropertiesDataGrid,
        refetchPropertyGroups,
        selectedProperties,
        setRefetchPropertiesDataGrid,
        setSelectedProperties,
        stream: streamV2?.stream as Stream,
      }}
    >
      {children}
    </PropertiesPageContext.Provider>
  );
};

export const usePropertiesPageContext = () => useContext(PropertiesPageContext);
