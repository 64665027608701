import React from 'react';
import { EuiPageHeader, EuiPanel, EuiSpacer } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { StreamProvider } from '@app/cx/Stream/StreamProvider';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { TaskProvider } from '@app/precheck/Components/V2/context/TaskContext';
import { ActivitySummary } from '../ActivitySummary/ActivitySummary';
import { OnboardAccountButton } from '../Layout/TopNav/OnboardAccountButton';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import { RecommendationSummary } from '../RecommendationSummary/RecommendationSummary';
import { SOVSummary } from '../SOVSummary/SOVSummary';
import { SOVSummaryEnterprise } from '../SOVSummaryEnterprise/SOVSummaryEnterprise';
import { SupportSummary } from '../SupportSummary/SupportSummary';
import { HomeContainer } from './Home.emotion';

export const Home = () => {
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const isAdmin = !!account?.permissions?.admin;

  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const canManageProperties = permissions?.includes('canManageProperties') || isAdmin;

  const { data: orgInfoData, loading } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const isEnterpriseAccount = orgInfoData?.orgInfo?.orgInfo?.orgLevel === 'Enterprise';
  const isEnterpriseOrgChild = !!orgInfoData?.orgInfo?.orgInfo?.enterpriseOrg;

  if (loading) {
    return <LoadingSpinnerV2 />;
  }
  return (
    <EuiPanel paddingSize="l">
      <EuiPageHeader
        pageTitle="Dashboard"
        rightSideItems={[<OnboardAccountButton key="oab" />]}
      ></EuiPageHeader>
      <EuiSpacer size="l" />
      <HomeContainer>
        {isEnterpriseAccount ? <SOVSummaryEnterprise /> : <SOVSummary />}
        {(isEnterpriseAccount || canManageProperties) && (
          <TaskProvider withUsers assignedToSelf isEnterpriseOrgChild={isEnterpriseOrgChild}>
            <RecommendationSummary />
          </TaskProvider>
        )}
        <SupportSummary />
        {canManageProperties && !isEnterpriseAccount && (
          <StreamProvider>
            <ActivitySummary />
          </StreamProvider>
        )}
      </HomeContainer>
    </EuiPanel>
  );
};
