import { Dictionary } from 'lodash';
import { SelectOption } from 'ui/dist/atoms/inputs/select/Select.types';
import { SubmissionAttributeMetadata } from './types';
import { SubmissionFormMeta } from './validator';

// Same regex as used in the api
export const toEmailOrBlank = (text: string): string =>
  /[a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/.test(text) ? text : '';

export const getBusinessTypes = (industry: string | undefined, orgEnumData: any[]) => {
  const industryBusinesses = orgEnumData?.filter((i: any) => i.value === industry);

  return industryBusinesses[0]?.list;
};

export const touch =
  (fieldName: string, meta: SubmissionFormMeta, setMeta: (m: SubmissionFormMeta) => void) => () => {
    setMeta({
      ...meta,
      [fieldName]: {
        ...meta[fieldName],
        touched: true,
      },
    });
  };

export const isFieldInvalid = (fieldName: string, meta: SubmissionFormMeta) => {
  const { error, touched } = meta[fieldName] || [];
  return !!error && !!touched;
};

export const isFieldEnabled = (fieldName: string, data?: SubmissionAttributeMetadata[]) => {
  const field = (data || []).find((d) => d?.displayName === fieldName);

  return field?.enabled || false;
};

export const getAccountStatusOptions = (
  metadataMap: Dictionary<SubmissionAttributeMetadata>,
  currentStatus: string,
): Array<SelectOption> => {
  const valueDisabled =
    !!currentStatus &&
    !(metadataMap.accountStatus?.enumValues || []).find(
      ({ displayName }) => displayName === currentStatus,
    );
  const options: Array<SelectOption> = (metadataMap.accountStatus?.enumValues || []).map(
    ({ displayName }) => ({
      label: displayName,
      // TODO - Change when api is updated to handle status enum values instead of labels
      value: displayName,
    }),
  );

  if (valueDisabled) {
    return [
      {
        disabled: true,
        label: currentStatus,
        value: currentStatus,
      },
      ...options,
    ];
  }

  return options;
};
