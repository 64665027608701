import styled from '@emotion/styled';
import { EuiPanel, EuiText } from 'ui';

export const CompletedTable = styled(EuiPanel)`
  margin-top: 24px;
  height: 380px;
`;

export const CompletedText = styled(EuiText)`
  color: #69707d;
  width: 635px;
  text-align: center;
`;

export const ConfettiContainer = styled.div`
  height: 80px;
  width: 100%;
  overflow: hidden;
`;

export const FiltersText = styled(EuiText)`
  color: #69707d;
  width: 624px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

export const InsightsPageLink = styled.span`
  color: #0f766e;
  font-weight: 700;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const DismissedLink = styled.span`
  color: #0f766e;
  font-weight: 700;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;
