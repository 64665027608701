import gql from 'graphql-tag';
import { SubmissionAttributeMetadata } from './types';

export const SUBMISSION_FRAGMENT = gql`
  fragment submission on Submission {
    id
    name
    orgName
    totalInsuredValue
    broker
    underwriter
    underwriterEmail
    underwriterTechnician
    underwriterTechnicianEmail
    gusl
    accountStatus
    submissionType
    accountOrgType
    effectiveDate
    expiryDate
    quoteDueDate
    industry
    businessType
    broker
    brokerEmail
    region
    team
    teamMemberEmails
    clientId
    submitterEmail
    createdAt
    comment
    assumptions
    internalNotes
    propertiesCount
    sla
    adjustedSLA
    accountOrgName
    reasonForAdjustment
    uwFeedbackRequired
    qaIssuesExternalCount
    archipelagoTransformations
    accountHealth
    sovState
    stream {
      id
      name
      slug
      updatedAt
      orgName
      organizationId
      displayCurrency
    }
    finalized
    finalizationCheckpoint
    finalizationStatus
  }
`;

export const CREATE_SUBMISSION = gql`
  mutation createSubmission($input: CreateSubmissionInput!) {
    createSubmission(input: $input) {
      ...submission
    }
  }
  ${SUBMISSION_FRAGMENT}
`;

export const UPDATE_SUBMISSION = gql`
  mutation updateSubmission($input: UpdateSubmissionInput!) {
    updateSubmission(input: $input) {
      ...submission
    }
  }
  ${SUBMISSION_FRAGMENT}
`;

export const GET_SUBMISSIONS = gql`
  query GetSubmissionsPage($input: SubmissionsPageInput!) {
    submissionsPageV2(input: $input) {
      pageInfo {
        hasNextPage
        cursor
        totalCount
      }
      submissions {
        ...submission
      }
    }
  }
  ${SUBMISSION_FRAGMENT}
`;

export const DELETE_SUBMISSION = gql`
  mutation DeleteSubmission($input: DeleteSubmissionInput!) {
    deleteSubmission(input: $input)
  }
`;

export const UPDATE_SUBMISSION_STREAM = gql`
  mutation UpdateSubmissionStream($input: UpdateSubmissionStreamInput!) {
    updateSubmissionStream(input: $input) {
      id
      stream {
        id
        name
        slug
      }
    }
  }
`;

export const GET_USERS_BY_ORGANIZATION = gql`
  query GetUsersByOrganization($orgName: String!) {
    usersByOrganization(orgName: $orgName) {
      email
      familyName
      givenName
    }
  }
`;

export interface SubmissionAttributeMetadataVariables {
  input: {
    orgName: string;
  };
}
export interface SubmissionAttributeMetadataResp {
  submissionAttributeMetadata: Array<SubmissionAttributeMetadata>;
}
export const SUBMISSION_ATTRIBUTE_METADATA = gql`
  query SubmissionAttributeMetadata($input: SubmissionAttributeMetadataInput!) {
    submissionAttributeMetadata(input: $input) {
      type
      name
      displayName
      enabled
      required
      enumEditable
      enumValues {
        value
        displayName
        enabled
      }
      group
    }
  }
`;

export const SUBMISSION_REGIONS = gql`
  query SubmissionRegions($input: SubmissionRegionsInput!) {
    submissionRegions(input: $input) {
      regions {
        name
        teams
      }
    }
  }
`;
