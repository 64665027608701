import styled from '@emotion/styled';

export const ActivitySummaryContainer = styled.div`
  padding-bottom: 24px;
`;

export const StyledProperty = styled.div`
  & > div {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
