import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { TaskStatus, ValuationOutlierReason } from '@app/graphql/precheck/precheck.types';
import { useGetTasksQuery } from '@app/graphql/precheck/queries/getTasks/__generated__/getTasks.generated';
import { useGetAccountUsersQuery } from '@app/graphql/queries/rbac/__generated__/AccountUsers.generated';
import { Role } from '@app/graphql/types';
import { useQueryState } from '@app/hooks/useQueryState';
import { useTracker } from '@app/hooks/useTracker';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';
import { colorMap } from '../constants/colorMap';
import { labelMap } from '../constants/labelMap';
import { AttritionalReason, CATReasons, COPEReason, IFormattedtask, ITaskContext } from './types';
import { formatTasksAndOptions } from './utils';

export const TaskContext = createContext({} as ITaskContext);

interface TaskProviderProps {
  withUsers?: boolean;
  assignedToSelf?: boolean;
  children?: ReactNode;
  isEnterpriseOrgChild?: boolean;
}

const catReasons = Object.values(CATReasons);
const outlierReasons = Object.values(ValuationOutlierReason);

export const TaskProvider = ({
  withUsers = false,
  children,
  assignedToSelf,
  isEnterpriseOrgChild,
}: TaskProviderProps) => {
  const client = usePrecheckClient();
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const { stream } = useStreamContext();
  const tracker = useTracker();

  // eslint-disable-next-line
  const [_qs, setQueryState] = useQueryState();
  const location = useLocation();
  const [selectedTasks, setSelectedTasks] = useState([]);

  const qs: any = queryString.parse(location.search);

  const name = qs.name;
  // check if the selfAssigned query param is set to true
  const selfAssignedQueryParam = qs?.selfAssigned ? qs?.selfAssigned === 'true' : false;

  // set the users to the current user to add the current user to Assignee Filter
  const users =
    qs?.users?.length > 0 ? qs?.users?.split(',') : selfAssignedQueryParam ? [account.email] : [];
  // if the users in the query param is equal to the current user, set the selfAssigned to true
  const selfAssigned =
    users.length === 1 && users[0] === account.email
      ? true
      : users.length > 1
        ? false
        : selfAssignedQueryParam;
  const reasons = qs?.reasons?.length > 0 ? qs?.reasons?.split(',') : [];
  const dismissed = qs?.dismissed && qs?.dismissed === 'true';
  const selectedTaskId = qs?.taskId;
  const setSelectedTaskId = (taskId) => {
    setQueryState({ taskId });
  };

  const taskTotals = {
    attritional: {
      properties: 0,
      tiv: 0,
    },
    cat: {
      properties: 0,
      tiv: 0,
    },
    cope: {
      properties: 0,
      tiv: 0,
    },
    valuation: {
      properties: 0,
      tiv: 0,
    },
  };

  const { data, loading, error, refetch } = useGetTasksQuery({
    client,
    nextFetchPolicy: 'cache-first',
    variables: {
      input: assignedToSelf
        ? {
            orgName: isEnterpriseOrgChild ? selectedOrganization.name : undefined,
            pageOffset: 0,
            pageSize: 3,
          }
        : {
            orgName: selectedOrganization.name,
          },
    },
  });

  const { data: availableUsers } = useGetAccountUsersQuery({
    skip: !withUsers,
    variables: {
      input: {
        limit: 1500,
        offset: 0,
        orgName: selectedOrganization.name,
        // FIX ME
        // @ts-ignore
        roleFilter: [Role.RiskManager, Role.Editor],
      },
    },
  });

  const [sortField, setSortField] = useState('priority');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const userOptions = (availableUsers?.accountUsers || []).reduce(
    (acc, user) => {
      if (user.profile.email !== account.email) {
        acc.push({
          label: `${
            user?.profile?.givenName && user?.profile?.familyName
              ? `${user.profile.givenName} ${user.profile.familyName.replace(
                  'temporarily_unavailable',
                  '',
                )}`
              : user.profile.email
          }`,
          value: user.profile.email,
        });
      }

      return acc;
    },
    [{ label: `${account.givenName} ${account.familyName}`, value: account.email }],
  );

  const { reasonOptions, transformedTasks } = useMemo(
    () => formatTasksAndOptions(data?.getTasks?.tasks),
    [data],
  );

  const filterReasonsTasks = (taskData: IFormattedtask[], availableReasons: string[]) => {
    let data = [...taskData];

    const availableCatReasons = catReasons.filter((reason) => availableReasons.includes(reason));
    const availableOutlierReasons = outlierReasons.filter((reason) =>
      availableReasons.includes(reason),
    );

    if (reasons.includes(CATReasons.anyCat)) {
      data = data.filter((task) => catReasons.some((reason) => task.reasons.includes(reason)));
    }

    if (!reasons.includes(CATReasons.anyCat) && availableCatReasons.length > 0) {
      data = data.filter((task) =>
        availableCatReasons.every((reason) => task.reasons.includes(reason)),
      );
    }

    if (reasons.includes(ValuationOutlierReason.AnyOutlier)) {
      data = data.filter((task) =>
        outlierReasons.some(
          (reason) => reason !== ValuationOutlierReason.AnyOutlier && task.reasons.includes(reason),
        ),
      );
    }

    if (
      !reasons.includes(ValuationOutlierReason.AnyOutlier) &&
      availableOutlierReasons.length > 0
    ) {
      data = data.filter((task) =>
        availableOutlierReasons.every((reason) => task.reasons.includes(reason)),
      );
    }

    if (availableReasons.includes(COPEReason.cope)) {
      data = data.filter((task) => task.reasons.includes(COPEReason.cope));
    }

    data.forEach((task) => {
      filteredTiv += task.rawTiv;
    });

    return data;
  };

  let readyTasksCount = 0;
  let filteredTiv = 0;

  const filteredTasks = transformedTasks.filter((task) => {
    if (task.status === TaskStatus.Ready) {
      readyTasksCount += 1;

      const hasCat = catReasons.some((reason) => task.reasons.includes(reason));
      if (hasCat) {
        taskTotals.cat.properties += 1;
        taskTotals.cat.tiv += task.rawTiv;
      }

      task.reasons.forEach((reason) => {
        if (reason === AttritionalReason.attritional) {
          taskTotals.attritional.properties += 1;
          taskTotals.attritional.tiv += task.rawTiv;
        }

        if (reason === COPEReason.cope) {
          taskTotals.cope.properties += 1;
          taskTotals.cope.tiv += task.rawTiv;
        }

        if (
          reason === ValuationOutlierReason.Overvalued ||
          reason === ValuationOutlierReason.Undervalued
        ) {
          taskTotals.valuation.properties += 1;
          taskTotals.valuation.tiv += task.rawTiv;
        }
      });
    }

    const nameMatch =
      name?.length > 0
        ? task.propertyName.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
          task.propertyCityAndState.toLocaleLowerCase().includes(name.toLocaleLowerCase())
        : true;
    const selfAssignedMatch = selfAssigned
      ? task.assignees.some((assignee) => assignee.email === account.email)
      : true;
    const assigneeMatch =
      users.length === 0
        ? true
        : users.some((user) => task.assignees.some((assignee) => assignee.email === user));

    const dismissedMatch = dismissed
      ? task.status === TaskStatus.Dismissed
      : task.status === TaskStatus.Ready;

    if (nameMatch && assigneeMatch && dismissedMatch && selfAssignedMatch) {
      filteredTiv += task.rawTiv;
      return true;
    } else {
      return false;
    }
  });

  const filtersApplied =
    name?.length > 0 || users.length !== 0 || dismissed || selfAssigned || reasons.length > 0;
  const reasonOptionsString = Object.keys(reasonOptions);

  const reasonOptionsFormatted = useMemo(() => {
    const data = Object.keys(reasonOptions).map((reason: any) => ({
      color: colorMap[reason],
      label: labelMap[reason],
      value: reason,
    }));

    if (catReasons.filter((item) => reasonOptionsString.includes(item)).length > 1) {
      data.push({
        color: 'rgb(13, 106, 100)',
        label: 'All CAT modifiers',
        value: CATReasons.anyCat,
      });
    }

    if (outlierReasons.filter((item) => reasonOptionsString.includes(item)).length > 1) {
      data.push({
        color: 'rgb(13, 106, 100)',
        label: 'All valuation outliers',
        value: ValuationOutlierReason.AnyOutlier,
      });
    }

    return data;
  }, [reasonOptions]);

  return (
    <TaskContext.Provider
      value={{
        dismissedTasks: transformedTasks.filter(
          (task) => task.status === TaskStatus.Dismissed,
        ) as Array<IFormattedtask>,
        error,
        filteredTiv: formatStreamCurrency(filteredTiv),
        filters: {
          clearFilters: () => {
            setQueryState({
              category: null,
              dismissed: null,
              hazards: null,
              name: null,
              reasons: null,
              selfAssigned: 'false',
              users: null,
            });
          },
          dismissed,
          filtersApplied,
          name,
          reasonOptions: reasonOptionsFormatted,
          reasons,
          selfAssigned,
          setFilter: (filterName, filterValue) => {
            tracker.track('Pre-Check: Filter Applied', {
              filterName,
              filterValue,
            });
            const newQueryState = { [filterName]: filterValue as string };

            // This condition is to handle selfAssigned and All Assignee filters for the admin in the inbox page.
            if (filterName === 'selfAssigned' || filterName === 'users') {
              // When users filter is applied, selfAssigned filter should be set to true if the current user is in the users
              // list else if the users list is empty or contains any value other than current user,
              // selfAssigned should be set to false.
              if (filterName === 'users') {
                newQueryState.selfAssigned =
                  filterValue.length === 1 && filterValue[0] === account.email ? 'true' : 'false';
              } else if (filterName === 'selfAssigned') {
                // When selfAssigned filter is applied, users filter should be set to the current user.
                newQueryState.users = filterValue === 'true' ? account.email : '';
                newQueryState.selfAssigned = filterValue as string;
              }
            }
            setQueryState(newQueryState);
          },
          userOptions,
          users,
        },
        loading: loading,
        readyTasksCount,
        refetch,
        selectedTaskId,
        selectedTasks,
        setSelectedTaskId,
        setSelectedTasks,
        setSortDirection,
        setSortField,
        sortDirection,
        sortField,
        streamSlug: stream?.slug,
        taskTotals,
        tasks: filterReasonsTasks(filteredTasks as IFormattedtask[], [
          ...(reasons.filter((item) => reasonOptions[item]) || []),
        ]) as Array<IFormattedtask>,
        tivPercent: `${Math.round((filteredTiv / stream?.totalInsuredValue) * 100)}%`,
        totalTiv: stream?.totalInsuredValue || 0,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => useContext(TaskContext);
